// import React from "react";
// import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./maintenanceMaster.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
// import { persistor, store } from "./redux/store";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./authConfig";

// const msalInstance = new PublicClientApplication(msalConfig);

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <BrowserRouter>
//       <MsalProvider instance={msalInstance}>
//         <App />
//         </MsalProvider>
//       </BrowserRouter>
//     </PersistGate>
//   </Provider>,
//   document.getElementById("root")
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))lo
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


//==============================================================================================================


import { React, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./maintenanceMaster.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./redux/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { b2cConfig } from "./config/authConfigB2C";
import { LOGIN_GODREJ_EMPLOYEE } from "./utils/constant";
const msalInstance = new PublicClientApplication(msalConfig);
const b2cInstance = new PublicClientApplication(b2cConfig);

const loginType = sessionStorage.getItem("loginType");
console.log("login type",loginType);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MsalProvider instance={((loginType === LOGIN_GODREJ_EMPLOYEE) || (loginType === null) )? msalInstance : b2cInstance}>
        {/* <MsalProvider instance={ b2cInstance}> */}
          <App></App>

        </MsalProvider>
        {/* <MsalProviderComponent >
          <app></app>
          
        </MsalProviderComponent> */}
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();


// const Root = () => {
//   const [useB2C, setUseB2C] = useState(false);
//   const [loginType, setLoginType] = useState(LOGIN_GODREJ_EMPLOYEE);

//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <BrowserRouter>
//           {/* <MsalProvider instance={useB2C ? b2cInstance : msalInstance}>
//             <App />
//           </MsalProvider> */}
//            {loginType === LOGIN_GODREJ_EMPLOYEE ? (
//             <MsalProvider instance={msalInstance}>
//               <App />
//             </MsalProvider>
//           ) : (
//             <MsalProvider instance={b2cInstance}>
//               <App />
//             </MsalProvider>
//           )}
//         </BrowserRouter>
//       </PersistGate>
//     </Provider>
//   );
// };

// ReactDOM.render(<Root />, document.getElementById("root"));

// reportWebVitals();
