import { useEffect, useState, useRef } from "react";
import { Breadcrumb, Button, Nav, Tab } from "react-bootstrap";
import CompressorAppDetails from "./CompressorAppDetails";
import CustomerDetails from "./CustomerDetails";
import EnergySavingROI from "./EnergySavingROI";
import FileNetworkDetails from "./FileNetworkDetails";
import UpcIfcSoln from "./UpcIfcSoln";
import Scrollbar from "smooth-scrollbar";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import TableViewA from "./Tables/TableA";
import SingleValuePopUp from "../utility/SingleValuePopUp";
import informlogo from "../../icons/Warning.svg";
import { RenderIf } from "../../functions/functions";
import Loader from "../utility/Loader";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

const NewSolution = (props) => {
  const childRef = useRef();
  const firstChildRef = useRef();
  const thirdChildRef = useRef();
  const fourthChildRef = useRef();
  const [key, setKey] = useState(1);
  const [goToNext, setGoToNext] = useState(false);
  const [IsDisabled, sett] = useState(true);
  const [validateSecond, setValidateSecond] = useState(false);
  const [validateKey, setValidateKey] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [maxComp, setMaxComp] = useState(0);
  const [maxApp, setMaxApp] = useState(0);
  const [isLoader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });
  const [show, setShow] = useState(false);
  // const [enquirydata, setEnquiryData] = useState({});
  console.log("props:" + JSON.stringify(props));
  let userId = props.user.um_id;
  let userRole = props.user.um_role;
  let userName = props.user.um_name;
  let enquirydata = props.history?.location?.data;
  console.log(enquirydata);
  const [enquirynum, setEnquirynum] = useState("");
  //let enquirynum="";
  const childFunc = useRef(null);
  const tempFunc = useRef(null);
  const tabFunc = useRef(null);
  //  useEffect(()=>
  //  {
  //   if(props.history?.location?.data)
  //   {
  //   setEnquiryData( props.history?.location?.data);
  //   }
  //  },[]);
  console.log(enquirynum);

  useEffect(() => {
    if (goToNext) {
      console.log("setkey", key);
      setKey(+key + 1);
      setGoToNext(false);
    }
  }, [goToNext]);
  useEffect(() => {
    Scrollbar.init(document.querySelector(".tab-pane"));
    Scrollbar.init(document.querySelector(".app-content"));
    Scrollbar.init(document.querySelector(".compApp"));
    Scrollbar.init(document.querySelector(".fileNet"));
    Scrollbar.init(document.querySelector(".energyRoi"));

    // data from dashboard
    // if (props?.location?.data) {
    //   console.log(
    //     "redirect Data-(data from dashboard)------------",
    //     props.location.data
    //   );
    // }
  }, []);

  const [color, setColor] = useState([]);

  const someFunction = async (keyData) => {
    console.log("keyData-------", keyData);
    if (keyData === 1) {
      let temp = await tabFunc.current();
      console.log("tempcheck1-------", temp);
      if (temp) {
        return false;
      } else {
        setColor((old) => [...old, 1]);
        return true;
      }
    } else if (keyData === 2) {
      let temp = await childRef.current.tabCheck2();
      console.log("tempcheck2-------", temp);
      if (temp) {
        setColor((old) => [...old, 2]);
        return true;
      } else {
        return false;
      }
    } else if (keyData === 3) {
      let temp = await thirdChildRef.current.tabCheck3();
      console.log("tempcheck3-------", temp);
      if (temp) {
        return false;
      } else {
        setColor((old) => [...old, 3]);
        return true;
      }
    } else if (keyData === 4) {
      let temp = await fourthChildRef.current.tabCheck4();
      console.log("tempcheck4-------", temp);
      if (temp) {
        setColor((old) => [...old, 4]);
        return true;
      } else {
        return false;
      }
    } else if (keyData === 5) {
      setColor((old) => [...old, 5]);
    } else {
      return false;
    }
  };

  console.log("color", color);

  const keyCheck = async (value) => {
    let errorKey = false;
    for (let i = 1; i < value; i++) {
      let temp = await someFunction(i);
      console.log("Error Check-----", temp);
      if (!temp) {
        errorKey = true;
        setKey(+i);
        break;
      }
    }
    if (!errorKey) {
      console.log("inside errorKey-----", value);
      setKey(+value);
    }
  };

  const next = async (e) => {
    console.log("key", key);
    console.log("nextdisable", IsDisabled);
    let temp1 = false;
    switch (key) {
      case 1:
        setLoader(true);
        if (IsDisabled) {
          e.preventDefault();
          childFunc.current();
          setColor((old) => [...old, 1]);
        } else {
          setKey(+key + 1);
          setColor((old) => [...old, 1]);
        }
        setLoader(false);
        break;
      case 2:
        setLoader(true);
        if (IsDisabled) {
          temp1 = await childRef.current.runValidate();
          console.log("temp-------", temp1);
          if (temp1) {
            setKey(+key + 1);
            setColor((old) => [...old, 2]);
            e.preventDefault();
          }
        } else {
          setKey(+key + 1);
          setColor((old) => [...old, 2]);
        }
        setLoader(false);
        break;
      case 3:
        setLoader(true);
        if (IsDisabled) {
          temp1 = await thirdChildRef.current.runValidate2();
          console.log("insideValidate-------", temp1);
          if (temp1) {
            setKey(+key + 1);
            setColor((old) => [...old, 3]);
            e.preventDefault();
          }
        } else {
          setKey(+key + 1);
          setColor((old) => [...old, 3]);
        }
        setLoader(false);
        break;
      case 4:
        setLoader(true);
        if (IsDisabled) {
          temp1 = await fourthChildRef.current.runValidate3();
          console.log("temp4-------", temp1);
          if (temp1) {
            setKey(+key + 1);
            setColor((old) => [...old, 4]);
            e.preventDefault();
          }
        } else {
          setKey(+key + 1);
          setColor((old) => [...old, 4]);
        }
        setLoader(false);
        break;
      default:
        break;
    }
  };
  const tempSave = (e) => {
    console.log(e);
    switch (key) {
      case 1:
        e.preventDefault();
        tempFunc.current();
        break;
      case 2:
        childRef.current.TempRunValidate();
        break;
      case 3:
        thirdChildRef.current.TempRunValidate2();
        break;
      case 4:
        fourthChildRef.current.TempRunValidate3();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* <div className="mobile-header">
    Create New Solution
    </div> */}

      <div className="bread-crumb d-none d-md-block d-lg-block">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/newSoln" active>
            Create New Solution
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="place-center">
        <div className="d-md-none d-lg-none ">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/newSoln" active>
              Create New Solution
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="informationLabel">
        <div className="ms-3">
          <img src={informlogo} alt="inform"></img>
          <label>WARNING</label>
          <span className="informationSpan ms-1">
            Please Save your data (if applicable) on regular intervals to avoid
            data loss.
          </span>
        </div>
      </div>
      {isLoader ? (
        <Loader />
      ) : null}
      <div className="app-content" id="NewSolution">
        <Tab.Container
          defaultActiveKey={1}
          activeKey={key}
          onSelect={(k) => {
            keyCheck(k);
            // setKey(k);
          }}
        >
          <Nav bsPrefix="soln-tabs">
            <Nav.Link
              bsPrefix="form-box "
              // onClick={() => {
              //   setKey(1)
              // }}
              className={color.includes(1) ? "complete" : ""}
              eventKey={1}
            >
              <Nav.Item bsPrefix="form-circle">1</Nav.Item>
              <label>
                Customer
                <br /> Details
              </label>
            </Nav.Link>
            <Nav.Link
              bsPrefix="form-box"
              // onClick={() => {
              //   setKey(2)
              // }}
              className={color.includes(2) ? "complete" : ""}
              eventKey={2}
            >
              <Nav.Item bsPrefix="form-circle">2</Nav.Item>
              <label>Compressor and Application Details</label>
            </Nav.Link>
            <Nav.Link
              bsPrefix="form-box"
              // onClick={() => {
              //   setKey(3)
              // }}
              className={color.includes(3) ? "complete" : ""}
              eventKey={3}
            >
              <Nav.Item bsPrefix="form-circle">3</Nav.Item>
              <label>File Upload/ Network Details</label>
            </Nav.Link>
            <Nav.Link
              bsPrefix="form-box"
              // onClick={() => {
              //   setKey(4)
              // }}
              className={color.includes(4) ? "complete" : ""}
              eventKey={4}
            >
              <Nav.Item bsPrefix="form-circle">4</Nav.Item>
              <label>
                UPC/IFC
                <br /> Solution
              </label>
            </Nav.Link>
            <Nav.Link
              bsPrefix="form-box"
              // onClick={() => {
              //   setKey(5)
              // }}
              className={color.includes(5) ? "complete" : ""}
              eventKey={5}
            >
              <Nav.Item bsPrefix="form-circle">5</Nav.Item>
              <label>
                Energy Saving
                <br /> and ROI
              </label>
            </Nav.Link>
          </Nav>

          {/* //enquirydata?.ed_enquirynumber
          //{enquirydata?.ed_enquirynumber===""?"":"Enquiry Number-" {enquirydata?.ed_enquirynumber} } */}
          <Tab.Content className="mt-2">
            <div
              class="d-flex flex-column align-items-end me-2"
              className="enq-no"
              style={{
                display: enquirydata?.ed_enquirynumber
                  ? ""
                  : enquirynum
                  ? ""
                  : "none",
              }}
            >
              <span>
                <b>
                  {enquirydata?.ed_insertedby === undefined
                    ?  `Raised By- ${userName}`
                    : ` Raised By- ${enquirydata?.username}`}
                </b>
              </span>
              &nbsp;
              <span >
                <b>
                  {enquirydata?.ed_enquirynumber === undefined
                    ? enquirynum === ""
                      ? ``
                      : ` | Enquiry Number- ${enquirynum} `
                    : ` | Enquiry Number- ${enquirydata?.ed_enquirynumber} `}
                </b>
              </span>
            </div>
            <Tab.Pane
              eventKey={1}
              className="px-3 bg-white paneHeight"
              style={{
                //height: "448px",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <CustomerDetails
                childFunc={childFunc}
                ref={firstChildRef}
                tempFunc={tempFunc}
                tabFunc={tabFunc}
                setGoToNext={setGoToNext}
                enquirydata={enquirydata}
                setEnquirynum={setEnquirynum}
                enquirynum={enquirynum}
                sett={sett}
                userId={userId}
                setLoader={setLoader}

                // setEnquiryID={(value)=>{
                //   setEnquiryData((prevState)=>
                //   (
                //     {
                //       ...prevState,
                //       ed_enquiryid: value,
                //     }
                //   )

                //   )
                // }

                //}
              />
            </Tab.Pane>

            <Tab.Pane eventKey={2} className="paneHeight compApp">
              <CompressorAppDetails
                page2key={key}
                enquiryid={enquirydata?.ed_enquiryid}
                ref={childRef}
                IsDisabled={IsDisabled}
                enquirydata={enquirydata}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={3} className="p-3 bg-white paneHeight fileNet">
              <FileNetworkDetails
                page3key={key}
                ref={thirdChildRef}
                tempFunc={tempFunc}
                setGoToNext={setGoToNext}
                enquirydata={enquirydata}
                IsDisabled={IsDisabled}
                enquiryid={enquirydata?.ed_enquiryid}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey={4}
              className="paneHeight"
              style={{
                height:
                  parseInt(maxApp) > 0
                    ? `${
                        parseInt(maxApp) > parseInt(maxComp)
                          ? `${maxApp}px`
                          : `${maxComp}px`
                      }`
                    : "450px",
              }}
            >
              <UpcIfcSoln
                page4key={key}
                ref={fourthChildRef}
                enquiryid={enquirydata?.ed_enquiryid}
                enquirydata={enquirydata}
                userId={userId}
                EnquiryNumber={
                  enquirydata?.ed_enquirynumber === undefined
                    ? enquirynum === ""
                      ? ``
                      : `Enquiry Number- ${enquirynum}`
                    : `Enquiry Number- ${enquirydata?.ed_enquirynumber}`
                }
                IsDisabled={IsDisabled}
                setMaxComp={setMaxComp}
                setMaxApp={setMaxApp}
                maxApp={maxApp}
                maxComp={maxComp}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey={5}
              className="p-3 roiTableWrapper paneHeight energyRoi"
              style={{
                // borderRadius: "8px 8px 0 0",
                // background: "#EFF3FC",
                borderRadius: "8px",
                background: "#ffffff",
              }}
            >
              <EnergySavingROI
                page5key={key}
                enquiryid={enquirydata?.ed_enquiryid}
                userId={userId}
                userRole={userRole}
                enquirydata={enquirydata}
                pendingWith={enquirydata?.ed_pendingwith}
                status={enquirydata?.ed_enqstatus}
                ed_status={enquirydata?.ed_status}
                ed_proposalstatus={enquirydata?.ed_proposalstatus}
                IsDisabled={IsDisabled}
                ed_insertedby={enquirydata?.ed_insertedby}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <div className="footer">
        <div className="">
          <Button
            size="sm"
            variant="outline-primary"
            className="me-2"
            style={{ display: key === 1 ? "none" : "  " }}
            onClick={() => setKey(+key - 1)}
          >
            Back
          </Button>
          <Button
            size="sm"
            className="me-2"
            style={{ display: key === 5 ? "none" : "  " }}
            onClick={(e) => next(e)}
          >
            Next
          </Button>
          <Button
            size="sm"
            hidden={IsDisabled === false ? true : false}
            variant="outline-primary"
            className="float-end me-2"
            style={{ display: key === 5 ? "none" : " " }}
            onClick={tempSave}
          >
            Temp Save
          </Button>
        </div>
        <br />
        {/* )} */}
      </div>

      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          //setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>
    </>
  );
};

export default compose(withRouter)(NewSolution);
