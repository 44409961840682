import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useDispatch } from "react-redux";
import { Chart, registerables } from 'chart.js';
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import encryptData from "../../utils/encryption";
import LoginPage from "./LoginPage"
import {
    setAccessToken,
    setUserDetails,
    setUserName,
} from "../../redux/redux/global/action";
function SSOAuth(props) {
    let history = useHistory();
    const [idToken, setIdToken] = useState(null);
    const [validUser, setValidUser] = useState(false);
    const [validDealer, setValidDealer] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const [errorMessage, setErrorMessage] = useState("");
    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const [empType, setEmpType] = useState("Employee");

    console.log("accounts length", accounts.length, accounts, isAuthenticated);

    let SSOAccount = accounts.filter(accounts => accounts.tenantId.includes(process.env.REACT_APP_SSO_TENANTID));
    console.log("SSOAccount", SSOAccount);


    useEffect(() => {
        if (accounts.length == 0 || SSOAccount.length == 0) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
            });
        }
    }, []);
    useEffect(() => {
        if (isAuthenticated) {
            RequestProfileData();
            getUserData();
        }

    }, [isAuthenticated]);
    useEffect(() => {
        if (isAuthenticated && validUser) {
            props.setIsAuthenticated(true);
            history.push({
                pathname: "/dashboard",
            });
        }

    }, [validUser]);
    useEffect(() => {
        if (errorMessage != "") {
            history.push({
                pathname: `/`,
                state: { errorMessage: errorMessage }
            });
        }

    }, [errorMessage]);
    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: SSOAccount[0]
        };
        instance.acquireTokenSilent(request).then((response) => {
            setIdToken(response.idToken);

        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setIdToken(response.idToken);
            });
        });
    }
    const getUserData = async () => {
        let email = SSOAccount[0].username;
        let value = {
            User: email,
            Password: "wueyiueyruiwefhrsughjfhghgjhgvjhgfrgrgfuygvjbvjxbvuhfil",
        };
        value = encryptData(JSON.stringify(value), "enc");
        axios
            .post("/authentication/login/", { body: value }, {
                headers: {},
            })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('Type', "Employee");

                    let token = response?.data?.data;
                    let accessData = response?.data?.accessibleMenu;
                    let JWT = jwt_decode(token);
                    if (accessData) {
                        JWT = {
                            ...JWT,
                            accessData: [...accessData.split(',')]
                        }
                    }
                    dispatch(setUserDetails(JWT));

                    dispatch(setAccessToken(token));
                    dispatch(setUserName(JWT.um_name));
                    setValidUser(true);
                    // props.setIsAuthenticated(true);

                }
                else {

                    setErrorMessage("Invalid user!");
                    setValidUser(false);
                }
            })
            .catch((e) => {

                if (e?.response?.data?.message) {
                    setErrorMessage(e?.response?.data?.message);
                    setValidUser(false);

                }
            })
            .finally(() => {
            });

    }
    return (
        <>
        </>
    );
}
export default SSOAuth;