import { useState, useEffect, forwardRef } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { getExecSummary } from "../../../Services/executive-summary-service";

import Scrollbar from "smooth-scrollbar";
import {
  isNumberWithDecimalPM,
  isNumberWithDecimal,
  validDecimals,
  validINRCurrency,
  validUSDCurrency,
  getFormatedAmount,
  isAlphanumericWithSpecialChars,
} from "../../../functions/validations";

import { decimalFloat,multipleOfFifty } from "../../../utils/twoDecimal";

const ExecutiveSummary = (props) => {
  //const ExecutiveSummary = forwardRef((props, ref) => {




  const tableB = props.DataTableB;
  const [ExecData, setExecData] = useState({});

  const [FlagIV, setFlagIV] = useState(false);
  const [Investment_B_Value, setInvestment_B_Value] = useState([]);
  //const [Investment_B_Value, setInvestment_B_Value] = useState([]);
  //const [alertMessage, setAlertMessage] = useState("");
  const [Inx, setInx] = useState("");


  // const handleChange = (e, index) => {};
  const [currency, setcurrency] = useState("");
  const [electricityUnitRate, setelectricityUnitRate] = useState(0);
  const [dailyEnergyConsumption, setdailyEnergyConsumption] = useState(0);
  const [dailyEnergySavingProposed, setdailyEnergySavingProposed] = useState(0);
  const [dailyEnergyCostSaving, setdailyEnergyCostSaving] = useState(0);
  const [annualCostSaving, setannualCostSaving] = useState(0);
  const [annualCO2Reduction, setannualCO2Reduction] = useState(0);
  const [workingdays, setworkingdays] = useState(0);
  let currency_code = "";
  let currency_amount = "";
  if (currency == "INR") {
    currency_code = "Rs.";
    currency_amount = "Lacs";
  } else {
    currency_code = "$";
  }
console.log("letcheck");


  useEffect(() => {
    if (props.page5key === 5) {
      // getApprovalWf();
      //getSummary();
      setSummaryDetails();
    }
  }, [props]);
  const setSummaryDetails = async () => {
    console.log("setSummaryDetails");
    setcurrency(props.enquirydata.ed_currencyvalue);
    setworkingdays(props.enquirydata.ed_workingdays);
    setelectricityUnitRate(props.enquirydata.ed_electricityunitrate);
    setdailyEnergyConsumption(Math.floor(props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption
      ));
    setdailyEnergySavingProposed(Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving) / 100));
    setdailyEnergyCostSaving((Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving ) / 100) * electricityUnitRate).toFixed(2));
    setannualCostSaving(((currency == "INR") ? (Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving ) / 100) * electricityUnitRate).toFixed(2) * workingdays / 100000 : (Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving ) / 100) * electricityUnitRate).toFixed(2) * workingdays).toFixed(2));
    console.log("annualCostSaving",annualCostSaving);
    setannualCO2Reduction(((Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving) / 100) * workingdays * 0.79) / 1000).toFixed(2));
      
    props.setannualCostSaving(((currency == "INR") ? (Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving ) / 100) * electricityUnitRate).toFixed(2) * workingdays / 100000 : (Math.floor((props.enquirydata.ed_status=='Created'?multipleOfFifty(
      props.EnergyConsumption):props.enquirydata.ed_dailyenergyconsumption?
      props.enquirydata.ed_dailyenergyconsumption:props.EnergyConsumption) * (props.proposedenergysaving?props.proposedenergysaving:props.energysaving ) / 100) * electricityUnitRate).toFixed(2) * workingdays).toFixed(2));

  }
  // const getSummary = async () => {
  //   let execsummary = await getExecSummary(props.enquirykey);
  //   if (execsummary.status === 200) {
  //     console.log("executive summary", execsummary.data);
  //     setExecData(execsummary.data);
  //   }
  // };

  return (
    <div className="roiTable">
      <div style={{ padding: "16px" }} className="d-none d-md-block d-lg-block">
        <Row className="square border rounded-top executive-head mt-3 ">
          <div className="d-flex justify-content-between">
            <span className="executive-head-text">EXECUTIVE SUMMARY</span>
            <span className="executive-head-smalltext">
              Note – 1 kWh energy saved = 0.79 kg of CO2 emission saved
            </span>
          </div>
        </Row>
        <Row className="square border border-top-0 executive-row">
          <Col md={4} className="executive-que">
            1. Daily Energy Consumption (Estimated)
          </Col>
          <Col md={2} className="square border-end executive-ans">
            {/* <span>{ExecData.Field_4} KWh/day</span> */}
            <span>{dailyEnergyConsumption} KWh/day
            
            </span>

          </Col>
          <Col md={3} className="executive-que">
            5. Total Cost of Control Systems {" "}
          </Col>
          <Col md={3} className="executive-ans">
            {currency_code} {getFormatedAmount(props.Sum, currency)}   {currency_amount}
          </Col>
        </Row>
        <Row className="square border border-top-0 executive-row">
          <Col md={4} className="executive-que" v>
            2. Daily Energy Savings (Proposed)
          </Col>
          <Col md={2} className="square border-end executive-ans">
            {/* {ExecData.Field_5} */}
            {dailyEnergySavingProposed}{" "}
            kWh/day(@
            {props.proposedenergysaving?props.proposedenergysaving:props.energysaving} %)
          </Col>
          <Col md={3} className="executive-que">
            6. Annual CO2 Reduction
          </Col>
          <Col md={3} className="executive-ans">
            {/* {ExecData.Field_13} Tons */}
            {annualCO2Reduction} Tons
          </Col>
        </Row>
        <Row className="square border border-top-0 executive-row">
          <Col md={4} className="executive-que">
            3. Daily Energy Cost Saving (@
            {currency_code}
            {electricityUnitRate}/kWh) {""}
          </Col>
          <Col md={2} className="square border-end executive-ans">
            {/* {currency_code} {getFormatedAmount(ExecData.Field_6, currency)} */}
            {currency_code}  {getFormatedAmount(dailyEnergyCostSaving, currency)}
          </Col>
          <Col md={3} className="executive-que">
            7. Constant Pressure within
          </Col>
          <Col md={3} className="executive-ans">
            ± 1 psig (± 0.07 bar)
          </Col>
        </Row>
        <Row className="square border border-top-0 rounded-bottom executive-row">
          <Col md={4} className="executive-que">
            {/* 4. Annual Cost Savings ({ExecData.Field_7} days){" "} */}
            4. Annual Cost Savings ({workingdays} days){" "}

          </Col>
          <Col md={2} className="square border-end executive-ans">
            {currency_code} {getFormatedAmount(annualCostSaving, currency)} {currency_amount}
          </Col>
          <Col md={3}></Col>
          <Col md={3}></Col>
        </Row>
      </div>
      {/* <div className="d-none d-md-block d-lg-block table-container">
        <Form name="AirTreatmentForm">
          <div className="tableWrapperCompressorModal">
            <div className="table-head tableHeadCompressorModal">
              <div className="table-body table-body-custom tableBodyCompressorModal">
                {tableB.length > 0 &&
                  tableB.map((item, index) => {
                    return (
                      <div>
                        <div
                          className="bluetableheading"
                          style={{ borderRadius: "6px", width: "1245px" }}
                        >
                          <div
                            className="bluetableheading-row"
                            style={{
                              color: "#264AE4",
                            }}
                          >
                            <span>EXECUTIVE SUMMARY</span>
                            <span
                              style={{
                                fontWeight: 200,
                                color: "gray",
                                fontSize: "smaller",
                              }}
                            >
                              Note – 1 kWh energy saved = 0.79 kg of CO2
                              emission saved
                            </span>
                          </div>
                          <table class="table table-bordered">
                            <tbody>
                              <tr style={{ justifyContent: "right" }}>
                                <td class="col-sm-6 col-md-5 col-lg-6">
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      1. Daily Energy Consumption (Estimated)
                                      {""}{" "}
                                    </span>

                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {ExecData.Field_4} {""}KWh/day
                                    </span>
                                  </div>
                                </td>

                                <td class="col-sm-6 col-md-5 col-lg-6">
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      5. Total Cost of Control Systems {""}
                                      {""}
                                    </span>

                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {currency_code} {ExecData.Field_9}{" "}
                                      {currency_amount}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      2. Daily Energy Savings (Proposed){""}
                                    </span>
                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {ExecData.Field_5} kWh/day(@
                                      {Math.floor(
                                        ExecData.Field_12 * 100
                                      )}{" "}
                                      %)
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      6. Annual CO2 Reduction{""}
                                    </span>
                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {ExecData.Field_13} Tons
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      3. Daily Energy Cost Saving (@
                                      {currency_code}
                                      {ExecData.Field_11}/kWh) {""}
                                    </span>
                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {currency_code} {ExecData.Field_6}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                        width: "200%",
                                      }}
                                    >
                                      7. Constant Pressure within {""}
                                    </span>
                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      ± 1 psig (± 0.07 bar) Tons
                                    </span>

                                    <span></span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="blueBottomRoi">
                                    <span
                                      style={{
                                        justifyContent: "right",
                                        color: "#264AE4",
                                        fontWeight: 200,
                                      }}
                                    >
                                      4. Annual Cost Savings (
                                      {ExecData.Field_7} days) {""}
                                    </span>
                                    <span
                                      style={{
                                        color: "#264AE4",
                                      }}
                                    >
                                      {currency_code} {ExecData.Field_8}{" "}
                                      {currency_amount}
                                    </span>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="roiBlueTabLeft">ROI in Years</div>
                        <div className="roiBlueTabRight">ROI in Months </div> 
                      </div>
                    );
                  })}
                {<EnergySavingROI dataTable1={tableA} />}
              </div>
            </div>
          </div>
        </Form>
      </div> */}
      <div className="d-md-none d-lg-none table-container">
        <Form name="AirTreatmentForm">
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="table-body tableBodyCompressorModal">
              {/* {tableB.length > 0 &&
                tableB.map((item, index) => { */}
                  {/* return ( */}
                    <div>
                      <div
                        className="bluetableheading"
                        style={{ borderRadius: "6px", width: "100%" }}
                      >
                        <div
                          className="bluetableheading-row"
                          style={{
                            color: "#264AE4",
                          }}
                        >
                          <span>EXECUTIVE SUMMARY</span>
                        </div>
                        <table class="table table-bordered tableStyle">
                          <tbody>
                            <span className="p-2"
                              style={{
                                fontWeight: 200,
                                color: "gray",
                                fontSize: "smaller",
                                display: "block",
                                border: "1px solid #D0D7F4",
                                borderWidth: "0 1px",
                              }}
                            >
                              Note – 1 kWh energy saved = 0.79 kg of CO2
                              emission saved
                            </span>
                            <tr style={{ justifyContent: "right" }}>
                              <td class="col-sm-6 col-md-5 col-lg-6">
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    1. Daily Energy Consumption (Estimated)
                                    {""}{" "}
                                  </span>

                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {/* {ExecData.Field_4} {""}KWh/day */}
                                    {dailyEnergyConsumption} {""}KWh/day
                                   
                                  </span>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    2. Daily Energy Savings (Proposed){""}
                                  </span>
                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {/* {ExecData.Field_5} kWh/day(@
                                    {Math.floor(ExecData.Field_12 * 100)} %)
                                     */}

                                    {dailyEnergySavingProposed} kWh/day(@
                                      {props.proposedenergysaving?props.proposedenergysaving:props.energysaving} %)

                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    3. Daily Energy Cost Saving (@{" "}
                                    {currency_code} {electricityUnitRate}
                                    /kWh){""}
                                  </span>
                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {currency_code} {dailyEnergyCostSaving}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    4. Annual Cost Savings (
                                    {workingdays} days){""}
                                  </span>
                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {currency_code} {annualCostSaving}{" "}
                                    {currency_amount}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td class="col-sm-6 col-md-5 col-lg-6">
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    5. Total Cost of Control Systems{""}
                                    {""}
                                  </span>

                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {currency_code} {getFormatedAmount(props.Sum, currency)}{" "}
                                    {currency_amount}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                    }}
                                  >
                                    6. Annual CO2 Reduction{""}
                                  </span>
                                  <span
                                    style={{
                                      color: "#264AE4",
                                    }}
                                  >
                                    {annualCO2Reduction} Tons
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="blueBottomRoi">
                                  <span
                                    style={{
                                      justifyContent: "right",
                                      color: "#264AE4",
                                      fontWeight: 200,
                                      width: "170%",
                                    }}
                                  >
                                    7. Constant Pressure within {""}
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span
                                    style={{
                                      color: "#264AE4",
                                      width: "160%",
                                    }}
                                  >
                                    ± 1 psig (± 0.07 bar)
                                  </span>
                                  <span></span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="roiBlueTabLeft">ROI in Years</div>
                        <div className="roiBlueTabRight">ROI in Months </div> */}
                    </div>
                             </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ExecutiveSummary;
