import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import { IoImageOutline } from "react-icons/io5";
import { BiCopy, BiNetworkChart } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
// import { BsDownload } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbChartCircles } from "react-icons/tb";
import ApplicationDets from "./ApplicationDets";
import axios from "../../utils/axios";
import Loader from "../utility/Loader";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { RenderIf } from "../../functions/functions";
import { decimalFloat,multipleOfFifty } from "../../utils/twoDecimal";
import NetworkDets from "./NetworkDets";
import CompressorDetails from "./CompressorDetails";
import { MarkerType } from "react-flow-renderer";
import CompressorNode from "./CompressorNode";
import ApplicationNode from "./ApplicationNode";
import ReactFlow, {
  useEdgesState,
  useNodesState,
  Controls,
} from "react-flow-renderer";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { type } from "@testing-library/user-event/dist/type";
import { getAppDetail,updateEnquiryTab2 } from "../../Services/enquiry-customer-service";
import {
  GetNetwork,
  SaveCoordinate,
} from "../../Services/new-solution-service";
import { getLinkedNetworkDetails } from "../../Services/network-service";
import WarningModal from "../Modals/warningModal";
import SuccessAlertModal from "../Modals/successAlertModal";
import {
  getenergyConsumptionSummation,
} from "../../Services/workflow-service";
// import Scrollbar from "smooth-scrollbar";
//BsDownload BsUpload

const CompressorAppDetails = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(1);
  const [display, setDisplay] = useState("d-none");
  const [applicationNodeData, setApplicationNodeData] = useState([]);
  const [networkNodeData, setNetworkNodeData] = useState([]);
  const [active, setActive] = useState(0);
  const [mobileModal, setMobileModal] = useState(false);
  const [mobileModalValue, setMobileModalValue] = useState(0);
  const [deleteNumber, setDeleteNumber] = useState(0);
  const [deleteKey, setDeleteKey] = useState(0);
  const [module, setModule] = useState("");
  const [energyConsumption, setEnergyConsumption] = useState(0);
  const [averageIcfm, setAverageIcfm] = useState(0);
  const [node, setNode, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [count, setCount] = useState(1);
  const [rfInstance, setRfInstance] = useState(null);
  const [show1, setShow1] = useState(false);
  const [nodePosition, setNodePosition] = useState([]);
  const [showMessage, setShowMessage] = useState("");
  const [show, setShow] = useState(false);
  const [msg, setmsg] = useState("");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [enquirydata, setenquirydata] = useState({
    ed_enquiryid: 0,
    ed_dailyenergyconsumption: "", 
    ed_avgicfm: ""
  });

  const nodeTypes = useMemo(
    () => ({ application: ApplicationNode, compressor: CompressorNode }),
    []
  );
  let formName = "Delete ";
  const flowKey = "example-flow";
  const [id, setId] = useState({
    compId: 0,
    appId: 0,
  });
  const [yaxis, setYaxis] = useState({
    compYaxis: 10,
    appYaxis: 0,
  });
  const [xaxis, setXaxis] = useState({
    compXaxis: 10,
    appXaxis: 0,
  });
  console.log(module);
  console.log("Rendered-Tab-2");
  let enquiryID = props.enquiryid
    ? props.enquiryid
    : localStorage.getItem("enqkey");

    useEffect(() => {
      calculateEnergy();
      console.log("blank useeffect");
      console.log("multipleOfFifty",multipleOfFifty(2975));
      console.log("multipleOfFifty",multipleOfFifty(243.1));
      console.log("multipleOfFifty",multipleOfFifty(2669));
      console.log("multipleOfFifty",multipleOfFifty(3550));
    },[]);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  useImperativeHandle(ref, () => ({
    runValidate: async () => {
      console.log("valid 2");
      await SaveNodes();
      await SaveEnergyIcfm();
     // alert("1");
      let result = await getNetworkDetails();
      return result;
    },
    TempRunValidate: async () => {
      await SaveNodes();
      await SaveEnergyIcfm();
    //  alert("2");
      setmsg("Enquiry Updated Successfully!");
      setShowSuccess(true);
    },
    tabCheck2: async () => {
      //alert("3");

      let result = await getNetworkDetails();
      return result;
    },
  }));

  const getNetworkDetails = async () => {
   // debugger;
    try {
      setLoading(true);
      const applicationdata = await getAppDetail(enquiryID);
      const compdata = await axios.get(
        `/maintenance/compDetail/header/${enquiryID}`
      );
      const result = await getLinkedNetworkDetails(enquiryID);

      if (applicationdata.data == "") {
        setShowMessage("At least one application to be maintained.");
        setShow(true);
        setLoading(false);
        return false;
      } else if (compdata.data.data && compdata.data.data.length == 0) {
        setShowMessage("At least one compressor to be maintained.");
        setShow(true);
        setLoading(false);
        return false;
      } else if (result?.notLinked?.length > 0) {
        setShowMessage(
          "All Compressor House and Applications are not connected. For following components, connections are missing." +
          result.notLinked
        );
        setShow(true);
        setLoading(false);
        return false;
      } else if (result?.tableRows?.length == 0) {
        setShowMessage("0 network found." + result.notLinked);
        setShow(true);
        setLoading(false);
        return false;
      }
      setLoading(false);

      return true;
    }
    catch (e) {
      setLoading(false);
    }
  }

  let returnUnique = (itemsCollection, uniqueKey) => {
    const itemsMap = new Map();
    itemsCollection.forEach((item) => {
      if (itemsMap.size === 0) {
        itemsMap.set(item[uniqueKey], item);
      } else if (!itemsMap.has(item.id)) {
        itemsMap.set(item[uniqueKey], item);
      }
    });
    return [...new Set(itemsMap.values())];
  };

  const addNode = async (compHouse) => {
    setLoading(true);
    //SaveNodes();
    let temp = [];
    node.forEach((value) => {
      if (value?.id) {
        let splitValue = value.id.split("-");
        if (splitValue[0] !== "CH") {
          temp.push(value);
        }
      }
    });
    compHouse.forEach((value, index) => {
      console.log("comphouseforeach", value);
      if (value?.ch_comp_house_id) {
        //calculateEnergy();
        temp.push({
          id: value?.ch_comp_house_id
            ? "CH-" + value.ch_comp_house_id
            : "chdm-" + (index + 1),
          data: {
            label: (
              <div className="centertext" style={{ display: "grid" }}>
                <span>{value?.ch_comp_house_name}</span>
              </div>
            ),
          },
          type: "compressor",

          position: {
            x: value?.ch_x_axis ? parseInt(value.ch_x_axis) + 0 : 180,
            y: value?.ch_y_axis
              ? parseInt(value.ch_y_axis) + 0
              : (index + 1) * 100,
          },
          style: {
            borderRadius: "8px",
          },
          sourcePosition: "right",
          targetPosition: "left",
        });
      }
    });
    console.log("temp123", temp);
    let uniqueNode = returnUnique(temp, "id");
    setNode([...uniqueNode]);
    setLoading(false);
  };

  const calculateEnergy = async() => {
    console.log("calculateEnergy");
    let tempEnergy = 0;
    let tempIcfm = 0;
    setEnergyConsumption(0);
    setAverageIcfm(0);
    let consumedRes = await getenergyConsumptionSummation(enquiryID);
    console.log("consumedRes", consumedRes);
    if (consumedRes.status === 200 && consumedRes.data[0].ch_energy_consumption_per_day!=null) {
      let energyConsumed = consumedRes.data[0].ch_energy_consumption_per_day;
      let icfm = consumedRes.data[0].ch_avg_icfm;
      setEnergyConsumption(energyConsumed);
      setAverageIcfm(icfm);
      setenquirydata((prevState) => ({
        ...prevState,
        ed_enquiryid:enquiryID,
        ed_dailyenergyconsumption: multipleOfFifty(energyConsumed),
        ed_avgicfm: icfm
      }));
    }else{
      setEnergyConsumption(0);
      setAverageIcfm(0);
    }
    
    // axios
    //   .get(`/aem/api/maintenance/compDetail/header/${enquiryID}`)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       let temp = response.data.data;
    //       if (temp.length) {
    //         temp.forEach((value, index) => {
    //           if (value?.ch_comp_house_id) {
    //             tempEnergy = tempEnergy + +value?.ch_energy_consumption_per_day;
    //             tempIcfm = tempIcfm + +value?.ch_avg_icfm;
    //           }
    //         });
    //         setEnergyConsumption(tempEnergy);
    //         setAverageIcfm(tempIcfm);
    //       }
    //     }
    //   });
  };

  const getAplDets = async () => {
    //    let data = await getAppDetail(props?.enquiryid);
    let data = await getAppDetail(enquiryID);
    if (data?.data.length > 0) {
      setApplicationNodeData(data?.data);
    }
    return data;
  };
  const getNetDets = async () => {
    //  let data = await GetNetwork(props?.enquiryid);
    let data = await GetNetwork(enquiryID);
    if (data?.data.length > 0) {
      // let networkData = data?.data.map((item) => {
      //   let temp = {
      //     nc_conn_id: item.nc_conn_id,
      //     nc_enquiry_id: item.nc_enquiry_id,
      //     nc_source_id: item.nc_source_id,
      //     nc_destination_id: item.nc_destination_id,
      //     nc_source_name: item.nc_source,
      //     nc_source_type: item.nc_source_type,
      //     nc_destination_name: item.nc_destination,
      //     nc_destination_type: item.nc_destination_type,
      //     nc_valve: item.nc_valve,
      //     nc_isolation: item.nc_isolation,
      //   };
      //   return temp;
      // });
      setNetworkNodeData([...data?.data]);
    }
    return data;
  };
  const addApplicationNode = async (appHouse) => {
    console.log("appHouse", appHouse);
    let temp = [];
    node.forEach((value) => {
      if (value?.id) {
        let splitValue = value.id.split("-");
        if (splitValue[0] !== "APL") {
          temp.push(value);
        }
      }
    });
    appHouse.forEach((value, index) => {
      if (value?.ad_appid) {
        temp.push({
          id: value?.ad_appid
            ? "APL-" + value?.ad_appid
            : "apdm-" + (index + 1),
          data: {
            label: (
              <div className="centertext" style={{ display: "grid", color: (value?.ad_n2application ? "#E5C204" : "#EF802F")}}>
                <span>{value?.ad_applicationname}</span>
              </div>
            ),
          },
          type: "application",
          position: {
            x: value?.ad_x_axis ? parseInt(value.ad_x_axis) + 0 : 420,
            y: value?.ad_y_axis
              ? parseInt(value.ad_y_axis) + 0
              : (index + 1) * 100,
          },
          style: {
            borderRadius: "50%",
            border:"2px solid " + (value?.ad_n2application ? "#E5C204" : "#EF802F"),
            background: (value?.ad_n2application ? "#FDF599" : "#FFE3C3") + " 0% 0% no-repeat padding-box",
          },
          targetPosition: "left",
          sourcePosition: "right",
        });
      }
    });
    console.log("temp", temp);
    let uniqueNode = returnUnique(temp, "id");
    setNode([...uniqueNode]);
  };


  const addEdge = (network) => {
    //SaveNodes();
    console.log("network", network);
    let temp = [];
    network.forEach((value, index) => {
      temp.push({
        id: "e-" + (index + 1),
        source:
          (value?.nc_source.includes("CH")
            ? value?.nc_source.slice(0, 2)
            : value?.nc_source.slice(0, 3)) +
          "-" +
          value?.nc_source_id,
        target:
          (value?.nc_destination.includes("CH")
            ? value?.nc_destination.slice(0, 2)
            : value?.nc_destination.slice(0, 3)) +
          "-" +
          value?.nc_destination_id,
        animated: true,
        style: { stroke: "black" },
        label: "ab",
        type: "smoothstep",
        labelStyle: {
          fill: value.nc_isolation ? "#FF0000" : "#9BC057",
          fontWeight: 1,
          display: value.nc_valve ? "" : "none",
        },
        labelBgBorderRadius: 50,
        labelBgStyle: {
          fill: value.nc_isolation ? "#FF0000" : "#9BC057",
          fillOpacity: 1,
          border: "1px solid black",
          display: value.nc_valve ? "" : "none",
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      });
    });
    let uniqueNode = returnUnique(temp, "id");
    setEdges([...uniqueNode]);
    console.log("edges", edges);
  };

  // const addAppNode = () => {
  //   let Xaxis = xaxis.appXaxis,
  //     Yaxis = yaxis.appYaxis,
  //     Count = count + 1,
  //     Id = id.appId + 1;
  //   setId({ ...id, appId: Id });
  //   if (Count === 5) {
  //     Xaxis = xaxis.appXaxis + 120;
  //     setXaxis({ ...xaxis, appXaxis: Xaxis });
  //     Yaxis = 10;
  //     setYaxis({ ...yaxis, appYaxis: Yaxis });
  //     setCount(1);
  //   } else {
  //     Yaxis = yaxis.appYaxis + 100;
  //     setYaxis({ ...yaxis, appYaxis: Yaxis });
  //     setCount(Count);
  //   }
  //   setNode((prev) => [
  //     ...prev,
  //     {
  //       id: "APP" + Id.toString(),
  //       type: "textUpdater",
  //       data: { label: "Application " + Id.toString() },
  //       position: { x: Xaxis, y: Yaxis },
  //     },
  //   ]);
  // };

  const SaveEnergyIcfm = async () => {
    console.log("here save eng icfm");
    let resultEDData = updateEnquiryTab2(enquirydata);
    console.log("here save eng icfm",resultEDData);
  }

  const SaveNodes = async () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      console.log("flow", flow);
      // localStorage.setItem(flowKey, JSON.stringify(flow));
      console.log("flowKey", flow.nodes);
      let temp = [];
      flow.nodes.forEach((item) => {
        temp.push({
          id: item.id,
          x_axis: item.position.x,
          y_axis: item.position.y,
        });
      });
      setNodePosition((prev) => {
        return returnUnique(temp.concat(prev), "id");
      });
      console.log("restore", temp);

      //SaveCoordinate(temp, props?.enquiryid);
      if (temp.length > 0) SaveCoordinate(temp, enquiryID);
    }
  };

  const handleDelete = async () => {
    await setDeleteKey(Math.random());
    setShow1(false);
  };
  const handleClose = () => {
    setShow1(false);
  };

  useEffect(async () => {
    //debugger;
    //setLoader(true);
    let appDetails = await getAplDets();
    let netDetails = await getNetDets();
    setKey(3);
    setDisplay("d-block");
    // if(appDetails && netDetails)
  
    //setLoader(false);
  }, [props]);
  useEffect(() => {
    if (applicationNodeData.length > 0) {
      addApplicationNode(applicationNodeData);
    }
  }, [applicationNodeData]);
  useEffect(() => {
    console.log("netnet-------", networkNodeData);
    if (networkNodeData?.length > 0) {
      console.log("netnet-----22--", networkNodeData);
      addEdge(networkNodeData);
    }
  }, [networkNodeData]);
  useEffect(() => {
    if (rfInstance && node.length > 0) {
      setTimeout(() => {
        rfInstance.fitView();
        console.log("rfInstance", rfInstance);
      }, 10);
    }
  }, [node.length]);
  return (
    <>
      <WarningModal
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <ConfirmAlertModal
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
        module={module}
      />
      <SuccessAlertModal
        msg={msg}
        show={ShowSuccess}
        handleClose={() => {
          setShowSuccess(false);
        }}
      />
        {/* {loading ? (
        <Loader />
      ) : null} */}
        <div id="CompressorAppDetails">
          <Tab.Container
            activeKey={key}
            defaultActiveKey={3}
            onSelect={(k) => {
              setKey(k);
              setDisplay("d-block");
            }}
          >
            <Nav
              bsPrefix="test-tabs"
              as={Row}
              className="m-0 g-0"
              variant="tabs"
              style={{
                height: "32px",
                // boxShadow: "2px 3px 4px #00000040",
              }}
            >
              {/* <Nav.Link
              as={Col}
              md={1}
              eventKey={1}
              disabled
              bsPrefix="test-1"
              style={{
                borderRadius: "8px 8px 0 0",
                background:
                  "transparent linear-gradient(180deg, #4B4B4B 0%, #262626 100%) 0% 0% no-repeat padding-box",
                display: "grid",
                placeItems: "center",
                color: "#FFFFFF",
                boxShadow: "2px 3px 4px #2C2C2C99",
              }}
            >
              <Nav.Item>
                <IoImageOutline size={20} />
              </Nav.Item>
            </Nav.Link> */}
              <Col
                md={12}
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  borderRadius: "8px 8px 0 0",
                  background:
                    "0% 0% no-repeat padding-box padding-box rgb(104, 104, 104)",
                  boxShadow: "2px 3px 4px #00000040",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {/* <Nav.Link eventKey={2} bsPrefix="test" disabled>
                  <Nav.Item>Draw</Nav.Item>
                </Nav.Link> */}
                  <Nav.Link
                    className="d-none d-md-block d-lg-block text-center"
                    eventKey={3}
                    bsPrefix="test"
                  >
                    <Nav.Item>
                      <BiCopy size={20} />
                    </Nav.Item>
                  </Nav.Link>
                  <Nav.Link
                    className="d-none d-md-block d-lg-block text-center"
                    eventKey={4}
                    bsPrefix="test"
                  >
                    <Nav.Item>
                      <TbChartCircles size={20} />
                    </Nav.Item>
                  </Nav.Link>
                  <Nav.Link
                    className="d-none d-md-block d-lg-block text-center"
                    eventKey={5}
                    bsPrefix="test"
                  >
                    <Nav.Item>
                      <BiNetworkChart size={20} />
                    </Nav.Item>
                  </Nav.Link>
                  <div className="d-md-none d-lg-none compressorHeaderSelectWrapper">
                    <Form>
                      <select
                        type="text"
                        name="ucm_parameter"
                        // value={unitObj.ucm_parameter}
                        onChange={(e) => {
                          if (e.target.value) {
                            setMobileModal(true);
                            setKey(+e.target.value);
                          }
                        }}
                        maxLength="100"
                        //readOnly
                        className="placeHolderInput inputText compressorHeaderSelect custom-select"
                      >
                        <option value="">Select</option>
                        <option value="3">Add Compressor House</option>
                        <option value="4">Add Application Details</option>
                        <option value="5">Add Network Connection</option>
                      </select>
                    </Form>
                  </div>
                </div>
                <div>
                  <Nav.Item
                    hidden={props.IsDisabled == false ? true : false}
                    bsPrefix="test"
                    onClick={() => {
                      if (deleteNumber > 0) {
                        setShow1(true);
                      }
                    }}
                  >
                    <RiDeleteBin5Line size={20} />
                  </Nav.Item>
                </div>
                {/* <div>
                <Nav.Item bsPrefix="test">
                  <BsDownload size={20} />
                </Nav.Item>
              </div> */}
              </Col>
            </Nav>
          </Tab.Container>
          <Row
            style={{
              height: "100%",
              background: "#686868 0% 0% no-repeat padding-box",
              border: "1px solid #707070",
            }}
            className="gx-3 mx-0 px-2 py-3"
          >
            {/* <Col>
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            ></div>
          </Col> */}
            <Col>
              {isLoader ? (
                <Loader />
              ) : null}
              <div id="flowBackground" className="react-flow-container">
             
                  <div className="topLeftGreen">
                    <div className="leftContainer">
                      <div style={{ fontWeight: 500 }}>
                        Energy Consumption Per Day 
                      </div>
                      {/* <div>
                        <strong>{`${decimalFloat(
                          energyConsumption
                        )} kWh/day`}</strong>
                      </div> */}
                      <div>
                        <strong>{props.enquirydata?
                          (props.enquirydata.ed_status=='Created'?`${multipleOfFifty(
                          energyConsumption
                        )} kWh/day`:props.enquirydata.ed_dailyenergyconsumption?`${props.enquirydata.ed_dailyenergyconsumption}kWh/day`:`${decimalFloat(
                          energyConsumption
                        )} kWh/day`):multipleOfFifty(
                          energyConsumption
                        )}</strong>
                      </div>
                    </div>
                    <div className="rightContainer">
                      <div style={{ fontWeight: 500 }}>Avg iCFM</div>
                      <div>
                        <strong>{decimalFloat(averageIcfm)}</strong>
                      </div>
                    </div>
                  </div>
             
                <div className="bottomright">
                  <div>
                    <span
                      className="dot-circle"
                      style={{ backgroundColor: "#FF0000" }}
                    ></span>
                    <span className="dot-text">Isolated</span>
                  </div>
                  <div>
                    <span
                      className="dot-circle"
                      style={{ backgroundColor: "#9BC057" }}
                    ></span>
                    <span className="dot-text">Non Isolated</span>
                  </div>
                </div>
                <ReactFlow
                  nodes={node}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onInit={setRfInstance}
                  nodeTypes={nodeTypes}
                >
                  <Controls />
                </ReactFlow>
              </div>
            </Col>
            <Col sm={5} className={display + " d-none d-md-block d-lg-block"}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexFlow: "column",
                }}
              >
                <RenderIf isTrue={key == "3" || key == "4"}>
                  <Tab.Container
                    defaultActiveKey={1}
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                  >
                    <Nav
                      as={Row}
                      variant="tabs"
                      bsPrefix="Comp-App-Tabs"
                      className="m-0 g-0"
                    >
                      <Nav.Link as={Col} md={6} bsPrefix="app-tab" eventKey={3}>
                        <Nav.Item>Compressor House</Nav.Item>
                      </Nav.Link>
                      <Nav.Link as={Col} md={6} bsPrefix="app-tab" eventKey={4}>
                        <Nav.Item>Applications</Nav.Item>
                      </Nav.Link>
                    </Nav>
                    <Tab.Content
                      id="tab-content-1"
                      style={{
                        overflowY: "scroll",
                        height: "380px",
                        borderRadius: "0 0 4px 4px",
                        boxShadow: "1px 2px 6px #2E2E2E66",
                        padding: "8px",
                        display: "flex",
                        flexFlow: "column",
                        background:
                          "transparent linear-gradient(180deg, #A8ABB9 0%, #9094A0 100%) 0% 0% no-repeat padding-box",
                      }}
                    >
                      <RenderIf isTrue={key == "3"}>
                        <Tab.Pane eventKey={3} style={{ height: "100%" }}>
                          <CompressorDetails
                            page2key={props.page2key}
                            addNode={addNode}
                            calculateEnergy={calculateEnergy}
                            setDeleteNumber={setDeleteNumber}
                            deleteKey={key == "3" ? deleteKey : ""}
                            // enquiryid={props?.enquiryid}
                            enquiryid={enquiryID}
                            ref={ref}
                            setModule={setModule}
                            IsDisabled={props.IsDisabled}
                          />
                        </Tab.Pane>
                      </RenderIf>
                      <RenderIf isTrue={key == "4"}>
                        <Tab.Pane
                          eventKey={4}
                          style={{
                            height: "100%",
                          }}
                        >
                          <ApplicationDets
                            addApplicationNode={addApplicationNode}
                            edges={edges}
                            //enquiryid={props?.enquiryid}
                            enquiryid={enquiryID}
                            deleteKey={key == "4" ? deleteKey : ""}
                            setDeleteNumber={setDeleteNumber}
                            ref={ref}
                            setModule={setModule}
                            IsDisabled={props.IsDisabled}
                          />
                        </Tab.Pane>
                      </RenderIf>
                    </Tab.Content>
                  </Tab.Container>
                </RenderIf>
                <RenderIf isTrue={key == "5"}>
                  <Nav
                    as={Row}
                    variant="tabs"
                    bsPrefix="Comp-App-Tabs"
                    className="m-0 g-0"
                  >
                    <Nav.Link as={Col} bsPrefix="app-tab active" eventKey={5}>
                      <Nav.Item> Network Connections</Nav.Item>
                    </Nav.Link>
                  </Nav>
                  <NetworkDets
                    active={active}
                    setActive={setActive}
                    setModule={setModule}
                    setDeleteNumber={setDeleteNumber}
                    deleteKey={key == "5" ? deleteKey : ""}
                    enquiryid={props.enquiryid}
                    addEdge={addEdge}
                    IsDisabled={props.IsDisabled}
                  //  ref={ref}
                  />
                </RenderIf>
              </div>
            </Col>
          </Row>
        </div>
     

      <Modal
        className="mobileCompressorModal"
        backdrop="static"
        show={mobileModal}
        onHide={() => setMobileModal(false)}
        animation={false}
        style={{ zIndex: 99 }}
      >
        <div className="modalCloseButton" onClick={() => setMobileModal(false)}>
          <span>
            <AiOutlineClose size={20} />
          </span>
        </div>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <RenderIf isTrue={key == "3" || key == "4"}>
            <Tab.Container
              defaultActiveKey={1}
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Nav
                as={Row}
                variant="tabs"
                bsPrefix="Comp-App-Tabs"
                className="m-0 g-0"
              >
                <Nav.Link
                  as={Col}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  bsPrefix="app-tab"
                  eventKey={3}
                >
                  <Nav.Item>Compressor House</Nav.Item>
                </Nav.Link>
                <Nav.Link
                  as={Col}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  bsPrefix="app-tab"
                  eventKey={4}
                >
                  <Nav.Item>Applications</Nav.Item>
                </Nav.Link>
              </Nav>
              <Tab.Content
                id="tab-content-1"
                style={{
                  overflowY: "scroll",
                  height: "380px",
                  borderRadius: "0 0 4px 4px",
                  boxShadow: "1px 2px 6px #2E2E2E66",
                  padding: "8px",
                  display: "flex",
                  flexFlow: "column",
                  background:
                    "transparent linear-gradient(180deg, #A8ABB9 0%, #9094A0 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <RenderIf isTrue={key == "3"}>
                  <Tab.Pane eventKey={3} style={{ height: "100%" }}>
                    <CompressorDetails
                      addNode={addNode}
                      calculateEnergy={calculateEnergy}
                      setDeleteNumber={setDeleteNumber}
                      deleteKey={key == "3" ? deleteKey : ""}
                      // enquiryid={props?.enquiryid}
                      enquiryid={enquiryID}
                      setModule={setModule}
                      ref={ref}
                      IsDisabled={props.IsDisabled}
                    />
                  </Tab.Pane>
                </RenderIf>
                <RenderIf isTrue={key == "4"}>
                  <Tab.Pane
                    eventKey={4}
                    style={{
                      height: "100%",
                    }}
                  >
                    <ApplicationDets
                      addApplicationNode={addApplicationNode}
                      // enquiryid={props?.enquiryid}
                      enquiryid={enquiryID}
                      deleteKey={key == "4" ? deleteKey : ""}
                      setDeleteNumber={setDeleteNumber}
                      setModule={setModule}
                      ref={ref}
                      IsDisabled={props.IsDisabled}
                    />
                  </Tab.Pane>
                </RenderIf>
              </Tab.Content>
            </Tab.Container>
          </RenderIf>
          <RenderIf isTrue={key == "5"}>
            <Nav
              as={Row}
              variant="tabs"
              bsPrefix="Comp-App-Tabs"
              className="m-0 g-0"
            >
              <Nav.Link as={Col} bsPrefix="app-tab active" eventKey={5}>
                <Nav.Item> Network Connections</Nav.Item>
              </Nav.Link>
            </Nav>
            <NetworkDets
              active={active}
              ref={ref}
              setActive={setActive}
              deleteKey={key == "5" ? deleteKey : ""}
              setDeleteNumber={setDeleteNumber}
              enquiryid={props.enquiryid}
              setModule={setModule}
              addEdge={addEdge}
              IsDisabled={props.IsDisabled}
            />
          </RenderIf>
        </div>
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
});

export default CompressorAppDetails;
