import { Button, Form, Row, Col } from "react-bootstrap";
import { BiSelectMultiple } from "react-icons/bi";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Duplicate from "../../styles/images/Duplicate.svg";

import { BsPlusLg } from "react-icons/bs";
import SingleValuePopUp from "../utility/SingleValuePopUp";
import { RenderIf } from "../../functions/functions";
import Loader from "../utility/Loader";

import {
  getFromUnit,
  getUnitConversionRate,
} from "../../Services/unit-conversion-service";
import axios from "../../utils/axios";
import {
  getAllCompressorHouse,
  getMaxCompHouseHeaderId,
} from "../../Services/compressor-house-service";
import {
  getAllAirCompMakeYes,
  getAllAirCompTypeYes,
  getAllAirCompLubYes,
} from "../../Services/compressor-parameter-service";
import { decimalFloat } from "../../utils/twoDecimal";
import {
  alphaNumeric50,
  isSelectEmpty,
  number24,
  percent100,
  validFourDecimals,
  validTenDecimals,
  validTwoDecimals,
  isAlphanumericWithDash,
} from "../../utils/customValidation";
import UnitConfirmModal from "../Modals/UnitConfirmModal";
import { get } from "jquery";
import { useState, useEffect, useRef } from "react";
// Inside your React Component

//var Defaultmake;
const ADC = ({
  id,
  compLine,
  setCompLine,
  compheader,
  ch_comp_house_name,
  setcompheader,
  active,
  setListDot,
  compHouse,
 setCompHouse,
  enquiryID,
  airTreatment,
  setAirTreatment,
  Name,
  ...props
}) => {
  const [showAppForm, setshowAppForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [editAction, setEditAction] = useState(false);
  const [Defaultmake, setDefaultmake] = useState("");
  const [ShowUnitHeader, setShowUnitHeader] = useState(false);
  const [EventParams, setEventParams] = useState("");
  const [EventParams1, setEventParams1] = useState("");
  const [DefaultType, setDefaultType] = useState("");
  const [DefaultLub, setDefaultLub] = useState("");
  const [scrollFlg, setScrollFlag] = useState(false);
  const [showmoreless, setshowmoreless] = useState([]);
  const textInput = useRef(null);
  let ch_csu;
  let ch_cfu;
  let ch_pu;

  const getcompYes = async () => {
    let abc = new Array();
    let result = await getAllAirCompMakeYes();

    //typeyes
    let resultTypeYes = await getAllAirCompTypeYes();

    //let resultlubyes = await getAllAirCompTypeYes
    let resultLubYes = await getAllAirCompLubYes();

    abc.push(result, resultTypeYes, resultLubYes);
    return abc;
  };

  console.log("Active details",active);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
    editType: "",
    actualValue: "",
  });
  let active_ch_controlsettings_unit =
    compHouse[active]?.ch_controlsettings_unit || "";
  let active_ch_capacity_unit = compHouse[active]?.ch_capacity_unit || "";
  let active_ch_power_unit = compHouse[active]?.ch_power_unit || "";
  let active_ch_energy_consumption_per_day =
    compHouse[active]?.ch_energy_consumption_per_day || "";
  let active_ch_avg_icfm = compHouse[active]?.ch_avg_icfm || "";
  let active_ch_comp_code = compHouse[active]?.ch_comp_code || "";
  let active_ch_comp_house_id = compHouse[active]?.ch_comp_house_id || "";
  let active_ch_sequence_number = compHouse[active]?.ch_sequence_number || "";
  let active_enquiry_id = compHouse[active]?.ch_enquiry_id || "";
  const activeHeader = {
    active_enquiry_id,
    active_ch_controlsettings_unit,
    active_ch_capacity_unit,
    active_ch_power_unit,
    active_ch_energy_consumption_per_day,
    active_ch_avg_icfm,
    active_ch_comp_code,
    active_ch_comp_house_id,
    active_ch_sequence_number,
  };

  const [Pressure, setPressure] = useState([]);
  const [PresDefaultRate, setPresDefaultRate] = useState();
  const [CapDefaultRate, setCapDefaultRate] = useState();
  const [PowerDefaultRate, setPowerDefaultRate] = useState();
  const [Capacity, setCapacity] = useState([]);
  const [Power, setPower] = useState([]);
  const [maxChid, setmaxChid] = useState(0);
  const [validateMessage, setValidateMessage] = useState("");
  const [addLine, setAddLine] = useState([]);
  const [errorLine, setErrorLine] = useState({
    cl_make: null,
    cl_model: "",
    cl_compressor_type: null,
    cl_lub_non_lub: null,
    cl_pressure: "",
    cl_capacity_flow: "",
    cl_power: "",
    cl_efficiency: "",
    cl_voltage: "",
    cl_starter_type: "",
    cl_comp_vol_efficiency: "",
    cl_qty: null,
    cl_running_hrs_per_day: "",
    cl_unload: "",
    cl_load_factor: "",
    cl_energy_consumption_per_day: null,
    cl_avg_icfm: null,
  });
  const [editLine, setEditLine] = useState([]);
  const showPopUp = (name, type, key, value, editType, actualValue) => {
    console.log("name", name);
    setPopUp({ name, type, key, value, editType, actualValue });
    setShowForm(true);
    //props.setshowCompForm(false);///////////// heres the 1026 hint
  };
  const [loading, setLoading] = useState(false);
  console.log("adcprops", props);
  console.log("compHouse[active]?.ch_energy_consumption_per_day",compHouse[active]?.ch_energy_consumption_per_day)

  const fetchCompMaster = async () => {
  console.log("adcprops");
    setLoading(true);
    //const resFromUnitPressure = await getFromUnit("Pressure");
    //setPressure(resFromUnitPressure);
    setPressure(props.Pressure);
    setPresDefaultRate(compHouse[active]?.ch_controlsettings_unit_rate);
    setCapDefaultRate(compHouse[active]?.ch_capacity_unit_rate);
    setPowerDefaultRate(compHouse[active]?.ch_power_unit_rate);
    // const resFromUnitCapacity = await getFromUnit("Capacity");
    // setCapacity(resFromUnitCapacity);
    setCapacity(props.Capacity);
    // const defcapacityrate = await getUnitConversionRate(
    //   resFromUnitCapacity,
    //   "Capacity"
    // );
    // setCapDefaultRate(defcapacityrate);

    // const resFromUnitPower = await getFromUnit("Power");
    // setPower(resFromUnitPower);
    setPower(props.Power);

    // const defPowerrate = await getUnitConversionRate(resFromUnitPower, "Power");
    // setPowerDefaultRate(defPowerrate);
    // const resMaxChID = await getMaxCompHouseHeaderId();
    // setmaxChid(resMaxChID);
    // setAction("Add");
    // if (
    //   activeHeader.active_ch_controlsettings_unit === "" ||
    //   activeHeader.active_ch_capacity_unit === "" ||
    //   activeHeader.active_ch_power_unit === ""
    // ) {
    // } else {
    //   const resCompLineData = await getAllCompressorHouse(activeHeader);
    //   setCompLine(resCompLineData);
    // }
    setLoading(false);
  };

  useEffect(async () => {
    // if (action !== "Add" ) {
      if (action !== "Add" && action !== "Edit" ) {
      //let Defaultdata = await getcompYes();
      let Defaultdata = props.CompressorParameters;
      console.log(Defaultdata);
      setDefaultmake(Defaultdata[0]);
      setDefaultType(Defaultdata[1]);
      setDefaultLub(Defaultdata[2]);
      setAction("FirstAdd");
      if (compLine.length === 0) {
        setAddLine([
          {
            cl_enquiry_id: enquiryID,
            cl_srno: "",
            cl_compressor_id: "",
            cl_make: Defaultdata[0]?.toString(),
            cl_model: "",
            cl_compressor_type: Defaultdata[1]?.toString(),
            cl_lub_non_lub: Defaultdata[2]?.toString(),
            cl_pressure: "",
            cl_capacity_flow: "",
            cl_power: "",
            cl_efficiency: "",
            cl_voltage: "",
            cl_starter_type: "",
            cl_comp_vol_efficiency: "",
            cl_qty: 1,
            cl_running_hrs_per_day: "",
            cl_unload: "",
            cl_load_factor: "",
            cl_energy_consumption_per_day: "",
            cl_avg_icfm: "",
          },
        ]);
      } else {
        setAddLine([]);
      }
    }
    //totalEnergyConsumption(compLine);
  }, [compLine]);

  useEffect(() => {
    // alert("fetchCompMaster");
    // let node = document.querySelector("#AddCompressor-table-" + id);
    // //console.log(node);
    // node && Scrollbar.init(node);
    fetchCompMaster();
  }, [active]);

  useEffect(() => {
    if (!scrollFlg) {
      return;
    }
    textInput.current.scrollIntoView({
      behavior: "smooth",
    });
  }, [scrollFlg]);

  const handleHeaderChange = async (e) => {
    console.log(compheader);
    console.log(addLine.length, editLine.length, compLine.length);
    console.log(addLine, editLine, compLine);

    if (compLine.length > 0) {
      const { name, value } = e.target;
      setEventParams(value);
      setEventParams1(name);
      setShowUnitHeader(true);
    } else {
      const { name, value } = e.target;
      if (name === "ch_controlsettings_unit") {
        let result = await getUnitConversionRate(value, "Pressure");
        console.log(result);
        ch_csu = value;
        await setPresDefaultRate(result);
        setcompheader((prevState) => ({
          // setCompHouse((prevState) => ({
          ...prevState,
          ch_controlsettings_unit_rate: result,
          ch_controlsettings_unit: value,
        }));
      }

      if (name === "ch_capacity_unit") {
        let result = await getUnitConversionRate(value, "Capacity");
        console.log(result);
        ch_cfu = value;
        await setCapDefaultRate(result);
        setcompheader((prevState) => ({
          // setCompHouse((prevState) => ({
          ...prevState,
          ch_capacity_unit_rate: result,
          ch_capacity_unit: value,
        }));
      }

      if (name === "ch_power_unit") {
        let result = await getUnitConversionRate(value, "Power");
        console.log(result);
        ch_pu = value;
        await setPowerDefaultRate(result);
        setcompheader((prevState) => ({
          // setCompHouse((prevState) => ({
          ...prevState,
          ch_power_unit_rate: result,
          ch_power_unit: value,
        }));
      }
    }
    console.log("srf1 compline",compLine);

    
  };

  const handleChange = (e, index, type) => {
    if (e.target.name === "cl_starter_type") {
      let error = isSelectEmpty(e.target.value, "Select a Value");
      setErrorLine((prevState) => ({
        ...prevState,
        cl_starter_type: error,
      }));
    }
    
    
    
    const { name, value } = e.target;
    // let energyCPD = document.getElementById(
    //   "cl_energy_consumption_per_day" + index
    // ).value;
    //let icfm = document.getElementById("cl_avg_icfm" + index).value;

    // if (e.target.name === "cl_energy_consumption_per_day") {
    //   energyCPD = e.target.value;
    // }
    //console.log("defratecpd", energyCPD);
    if (e.target.name === "cl_running_hrs_per_day") {
      console.log("cl_running_hrs_per_day here");
      
      if (e.target.value === 0) {
        setAddLine([
          {
            cl_load_factor: 0,
          },
        ]);
      }
      
      console.log(compheader.ch_energy_consumption_per_day);

    }
   
    if (type === "add") {
      let temp3 = [...addLine];
      temp3[0][name] = value;
      
      setAddLine([...temp3]);
    } else {
      let tmp = [...compLine];
      tmp[index][name] = value;
    
      setCompLine(tmp);
      if (type === "edit") {
        let temp2 = [...editLine];
        temp2[0][name] = value;
       
        setEditLine([...temp2]);
      }
    }
    console.log("srf1 comp",index,compLine);
    console.log("srf1 edit",editLine);
    console.log("srf1 add",addLine);
    console.log("srf1 action",action);
    console.log("srf1 PowerDefaultRate",PowerDefaultRate);
    if(action=='Add' || action=='FirstAdd'){
      console.log("srf1 running",addLine[0].cl_running_hrs_per_day);
      let sum=compLine.reduce((sum,energycons)=>
      decimalFloat(sum)+
      parseFloat(
        Number(decimalFloat(
          ((energycons.cl_power *
            PowerDefaultRate) /
            (energycons.cl_efficiency / 100)) *
            energycons.cl_running_hrs_per_day *
            (energycons.cl_load_factor / 100) +
            ((energycons.cl_power *
              PowerDefaultRate) /
              (energycons.cl_efficiency / 100)) *
              (energycons.cl_unload / 100) *
              energycons.cl_running_hrs_per_day *
              (1 -
                energycons.cl_load_factor / 100) *
              energycons.cl_qty
         ))
        ),0
      );
      let avg=compLine.reduce((avg,energycons)=>
      decimalFloat(avg)+decimalFloat(
        Number(
          energycons.cl_capacity_flow *
            CapDefaultRate *
            (energycons.cl_comp_vol_efficiency /
              100) *
            energycons.cl_qty *
            (energycons.cl_load_factor / 100)
        )
      ),0
      );
      console.log("srf1 sum",sum);
      let sum2=Number(decimalFloat(
        ((addLine[0].cl_power *
          PowerDefaultRate) /
          (addLine[0].cl_efficiency / 100)) *
          addLine[0].cl_running_hrs_per_day *
          (addLine[0].cl_load_factor / 100) +
          ((addLine[0].cl_power *
            PowerDefaultRate) /
            (addLine[0].cl_efficiency / 100)) *
            (addLine[0].cl_unload / 100) *
            addLine[0].cl_running_hrs_per_day *
            (1 -
              addLine[0].cl_load_factor / 100) *
            addLine[0].cl_qty
       ))

      let avg2=decimalFloat(
        Number(
          addLine[0].cl_capacity_flow *
            CapDefaultRate *
            (addLine[0].cl_comp_vol_efficiency /
              100) *
            addLine[0].cl_qty *
            (addLine[0].cl_load_factor / 100)
        )
      )
      console.log("srf1 sum2",sum2,"-",avg2,avg);
      setcompheader((prevState) => ({
        ...prevState,
        ch_energy_consumption_per_day: 
        decimalFloat(sum)
        +(sum2?sum2:0),
        ch_avg_icfm: decimalFloat(avg)+
        (avg2?avg2:0)
        
      }));
      console.log("srf1 sum",compheader.ch_energy_consumption_per_day);
    }
    else if(action=="Edit"){
      let compExclude=compLine.filter(data=>data.cl_line_id!=editLine[0].cl_line_id);
      console.log("srf1 compExclude",compExclude);
      let sum=compExclude.reduce((sum,energycons)=>
      parseFloat(sum)+
      parseFloat(
        Number(decimalFloat(
          ((energycons.cl_power *
            PowerDefaultRate) /
            (energycons.cl_efficiency / 100)) *
            energycons.cl_running_hrs_per_day *
            (energycons.cl_load_factor / 100) +
            ((energycons.cl_power *
              PowerDefaultRate) /
              (energycons.cl_efficiency / 100)) *
              (energycons.cl_unload / 100) *
              energycons.cl_running_hrs_per_day *
              (1 -
                energycons.cl_load_factor / 100) *
              energycons.cl_qty
         ))
        ),0
      );
      let avg=compExclude.reduce((avg,energycons)=>
      parseFloat(avg)+parseFloat(
        Number(
          energycons.cl_capacity_flow *
            CapDefaultRate *
            (energycons.cl_comp_vol_efficiency /
              100) *
            energycons.cl_qty *
            (energycons.cl_load_factor / 100)
        )
        ),0
      );
      console.log("srf1 sum",sum);
      let sum2=Number(decimalFloat(
        ((editLine[0].cl_power *
          PowerDefaultRate) /
          (editLine[0].cl_efficiency / 100)) *
          editLine[0].cl_running_hrs_per_day *
          (editLine[0].cl_load_factor / 100) +
          ((editLine[0].cl_power *
            PowerDefaultRate) /
            (editLine[0].cl_efficiency / 100)) *
            (editLine[0].cl_unload / 100) *
            editLine[0].cl_running_hrs_per_day *
            (1 -
              editLine[0].cl_load_factor / 100) *
            editLine[0].cl_qty
       ))

      let avg2=decimalFloat(
        Number(
          editLine[0].cl_capacity_flow *
            CapDefaultRate *
            (editLine[0].cl_comp_vol_efficiency /
              100) *
            editLine[0].cl_qty *
            (editLine[0].cl_load_factor / 100)
        )
      )
      setcompheader((prevState) => ({
        ...prevState,
        ch_energy_consumption_per_day: 
        decimalFloat(sum)+
        +(sum2?sum2:0),
        ch_avg_icfm: decimalFloat(avg)+
        (avg2?avg2:0)
      }));
      console.log("comphouse",compHouse[active]);
      
    }
  };
  const setPopUpData = (data) => {
    let id = popUp.value;
    if (data.name === "Air Compressor Make") {
      if (popUp.editType === "add") {
        let temp3 = [...addLine];
        temp3[0].cl_make = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_make: null,
        }));
        setAddLine([...temp3]);
      } else {
        const temp = [...compLine];
        temp[id].cl_make = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_make: error,
        }));
        setCompLine(temp);
        if (popUp.editType === "edit") {
          let temp2 = [...editLine];
          temp2[0].cl_make = data.result.comp_parametervalue;
          let error = isSelectEmpty(
            data.result.comp_parametervalue,
            "Select a Value"
          );
          setErrorLine((prevState) => ({
            ...prevState,
            cl_make: error,
          }));
          setEditLine([...temp2]);
        }
      }
      //setcompParameter(data.result.comp_parametervalue);
    }
    if (data.name === "Air Compressor Type") {
      if (popUp.editType === "add") {
        let temp3 = [...addLine];
        temp3[0].cl_compressor_type = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_compressor_type: error,
        }));
        setAddLine([...temp3]);
      } else {
        const temp = [...compLine];
        temp[id].cl_compressor_type = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_compressor_type: error,
        }));
        setCompLine(temp);
        if (popUp.editType === "edit") {
          let temp2 = [...editLine];
          temp2[0].cl_compressor_type = data.result.comp_parametervalue;
          let error = isSelectEmpty(
            data.result.comp_parametervalue,
            "Select a Value"
          );
          setErrorLine((prevState) => ({
            ...prevState,
            cl_compressor_type: error,
          }));
          setEditLine([...temp2]);
        }
      }
    }
    if (data.name === "Lub/Non Lub") {
      if (popUp.editType === "add") {
        let temp3 = [...addLine];
        temp3[0].cl_lub_non_lub = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_lub_non_lub: error,
        }));
        setAddLine([...temp3]);
      } else {
        const temp = [...compLine];
        temp[id].cl_lub_non_lub = data.result.comp_parametervalue;
        let error = isSelectEmpty(
          data.result.comp_parametervalue,
          "Select a Value"
        );
        setErrorLine((prevState) => ({
          ...prevState,
          cl_lub_non_lub: error,
        }));
        setCompLine(temp);
        if (popUp.editType === "edit") {
          let temp2 = [...editLine];
          temp2[0].cl_lub_non_lub = data.result.comp_parametervalue;
          let error = isSelectEmpty(
            data.result.comp_parametervalue,
            "Select a Value"
          );
          setErrorLine((prevState) => ({
            ...prevState,
            cl_lub_non_lub: error,
          }));
          setEditLine([...temp2]);
        }
      }
      //setcompParameter(data.result.comp_parametervalue);
    }
  };
  const onCancel = (e, index) => {
    //alert(index);
    var gfg_down = document.getElementById("row_" + index);
    if (index === 0) {
      alert("Atleast one compressor should be added.");
    } else {
      gfg_down.remove();
      index = index - 1;
      //alert(index);
    }
  };

  const totalEnergyConsumption = (compLine) => {
    //alert(index);
    console.log("compLine",compLine);
    for(let i = 0; i <= compLine.length; i++){
      //var energy =document.getElementById("cl_energy_consumption_per_day"+element).value;
      var energy =compLine[i].cl_energy_consumption_per_day;
      console.log("energy",energy);
    }
    
    // if (index === 0) {
    //   alert("Atleast one compressor should be added.");
    // } else {
    //   gfg_down.remove();
    //   index = index - 1;
    //   //alert(index);
    // }
  };
  //Defaultmake = data from backend where Default is true

  const handleAddClick = (id) => {
    //setActive(id);
    setAction("Add");
    setEditLine([]);
    setEditAction(true);
    setAddLine([
      {
        cl_enquiry_id: enquiryID,
        cl_compressor_id: compLine[0]?.cl_compressor_id || "",
        cl_srno: "",
        cl_make: Defaultmake.toString(),
        cl_model: "",
        cl_compressor_type: DefaultType.toString(),
        cl_lub_non_lub: DefaultLub.toString(),
        cl_pressure: "",
        cl_capacity_flow: "",
        cl_power: "",
        cl_efficiency: "",
        cl_voltage: "",
        cl_starter_type: "",
        cl_comp_vol_efficiency: "",
        cl_qty: 1,
        cl_running_hrs_per_day: "",
        cl_unload: "",
        cl_load_factor: "",
        cl_energy_consumption_per_day: "",
        cl_avg_icfm: "",
      },
    ]);
    setScrollFlag(true);
  };

  const handleAddClick2 = (row, index) => {
    //setActive(id);
    let temp = [...compLine];
    console.log(row);
    temp[index].edit = false;
    setAction("Add");
    setEditLine([]);
    setEditAction(true);
    
    setAddLine([
      {
        cl_enquiry_id: enquiryID,
        cl_srno: compLine.length + 1,
        cl_compressor_id: row.cl_compressor_id,
        cl_make: row.cl_make,
        cl_model: row.cl_model,
        cl_compressor_type: row.cl_compressor_type,
        cl_lub_non_lub: row.cl_lub_non_lub,
        cl_pressure: row.cl_pressure,
        cl_capacity_flow: row.cl_capacity_flow,
        cl_power: row.cl_power,
        cl_efficiency: row.cl_efficiency,
        cl_voltage: row.cl_voltage,
        cl_starter_type: row.cl_starter_type,
        cl_comp_vol_efficiency: row.cl_comp_vol_efficiency,
        cl_qty: row.cl_qty,
        cl_running_hrs_per_day: row.cl_running_hrs_per_day,
        cl_unload: row.cl_unload,
        cl_load_factor: row.cl_load_factor,
        cl_energy_consumption_per_day: row.cl_energy_consumption_per_day,
        cl_avg_icfm: row.cl_avg_icfm
      },
    ]);
    setcompheader((prevState) => ({
      ...prevState,
      ch_energy_consumption_per_day: decimalFloat(Number(compHouse[active]?.ch_energy_consumption_per_day))+decimalFloat(Number(row.cl_energy_consumption_per_day)),
      ch_avg_icfm: decimalFloat(Number(compHouse[active]?.ch_avg_icfm))+decimalFloat(Number(row.cl_avg_icfm)),
    }));
    console.log("ch_energy_consumption_per_day",Number(compHouse[active]?.ch_energy_consumption_per_day)+decimalFloat(Number(row.cl_energy_consumption_per_day)));
    console.log("ch_energy_consumption_per_day row", row.cl_energy_consumption_per_day);
    // compLine.push({
    //   cl_enquiry_id: enquiryID,
    //   cl_srno: index + 1,
    //   cl_compressor_id: row.cl_compressor_id,
    //   cl_make: row.cl_make,
    //   cl_model: row.cl_model,
    //   cl_compressor_type: row.cl_compressor_type,
    //   cl_lub_non_lub: row.cl_lub_non_lub,
    //   cl_pressure: row.cl_pressure,
    //   cl_capacity_flow: row.cl_capacity_flow,
    //   cl_power: row.cl_power,
    //   cl_efficiency: row.cl_efficiency,
    //   cl_voltage: row.cl_voltage,
    //   cl_starter_type: row.cl_starter_type,
    //   cl_comp_vol_efficiency: row.cl_comp_vol_efficiency,
    //   cl_qty: row.cl_qty,
    //   cl_running_hrs_per_day: row.cl_running_hrs_per_day,
    //   cl_unload: row.cl_unload,
    //   cl_load_factor: row.cl_load_factor,
    //   cl_energy_consumption_per_day: row.cl_energy_consumption_per_day,
    //   cl_avg_icfm: row.cl_avg_icfm
    // });
    // console.log("compline",compLine);
    // totalEnergyConsumption(compLine);
    
    setErrorLine({
      cl_make: null,
      cl_model: null,
      cl_compressor_type: null,
      cl_lub_non_lub: null,
      cl_pressure: null,
      cl_capacity_flow: null,
      cl_power: null,
      cl_efficiency: null,
      cl_voltage: null,
      cl_starter_type: null,
      cl_comp_vol_efficiency: null,
      cl_qty: null,
      cl_running_hrs_per_day: null,
      cl_unload: null,
      cl_load_factor: null,
      cl_energy_consumption_per_day: null,
      cl_avg_icfm: null,
    });
    
    setScrollFlag(true);
  };

  const handleEditRow = (row, index) => {

    let temp = [...compLine];
    console.log(row);
    temp[index].edit = true;
    setCompLine([...temp]);
    setAction("Edit")
    setEditAction(true);
    setErrorLine({
      cl_make: null,
      cl_model: null,
      cl_compressor_type: null,
      cl_lub_non_lub: null,
      cl_pressure: null,
      cl_capacity_flow: null,
      cl_power: null,
      cl_efficiency: null,
      cl_voltage: null,
      cl_starter_type: null,
      cl_comp_vol_efficiency: null,
      cl_qty: null,
      cl_running_hrs_per_day: null,
      cl_unload: null,
      cl_load_factor: null,
      cl_energy_consumption_per_day: null,
      cl_avg_icfm: null,
    });
    
    setEditLine([
      {
        cl_enquiry_id: row.cl_enquiry_id,
        cl_srno: index + 1,
        cl_compressor_id: row.cl_compressor_id,
        cl_make: row.cl_make,
        cl_model: row.cl_model,
        cl_compressor_type: row.cl_compressor_type,
        cl_lub_non_lub: row.cl_lub_non_lub,
        cl_pressure: row.cl_pressure,
        cl_capacity_flow: row.cl_capacity_flow,
        cl_power: row.cl_power,
        cl_efficiency: row.cl_efficiency,
        cl_voltage: row.cl_voltage,
        cl_starter_type: row.cl_starter_type,
        cl_comp_vol_efficiency: row.cl_comp_vol_efficiency,
        cl_qty: row.cl_qty,
        cl_running_hrs_per_day: row.cl_running_hrs_per_day,
        cl_unload: row.cl_unload,
        cl_load_factor: row.cl_load_factor,
        cl_energy_consumption_per_day: row.cl_energy_consumption_per_day,
        cl_avg_icfm: decimalFloat(row.cl_avg_icfm),
        cl_line_id: row.cl_line_id,
      },
    ]);
    
  };
  const decimalCount = (num) => {
    // Convert to String
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  };

  if (
    compHouse[active]?.ch_controlsettings_unit === "" ||
    compHouse[active]?.ch_capacity_unit === "" ||
    compHouse[active]?.ch_power_unit === ""
  ) {
    ch_csu = compheader.ch_controlsettings_unit;
    ch_cfu = compheader.ch_capacity_unit;
    ch_pu = compheader.ch_power_unit;
    // setPresDefaultRate(compheader.ch_controlsettings_unit_rate);
    // setPowerDefaultRate(compheader.ch_controlsettings_unit_rate);
    // setCapDefaultRate(compheader.ch_capacity_unit_rate);
  } else {
    ch_csu = compHouse[active]?.ch_controlsettings_unit || "";
    ch_cfu = compHouse[active]?.ch_capacity_unit || "";
    ch_pu = compHouse[active]?.ch_power_unit || "";
    // setPresDefaultRate(compHouse[active]?.ch_controlsettings_unit_rate );
    // setPowerDefaultRate(compHouse[active]?.ch_controlsettings_unit_rate);
    // setCapDefaultRate(compHouse[active]?.ch_capacity_unit_rate);
  }
  const bodyValidate = () => {
    let error = false;
    Object.entries(errorLine).forEach((value) => {
      if (value[1] !== null && value[0] != "cl_load_factor") {
        console.log("bodyValidate", value);
        let key = value[0];
        setErrorLine((prevState) => ({
          ...prevState,
          [value[0]]: "Enter a value",
        }));
        error = true;
        key = "";
      }
    });
    return error;
  };
  const addCompressor = (value) => {
    console.log("compLine");
    console.log(compLine);
    //console.log(compLine[0].cl_line_id);

    if (Name == "undefined" || Name == "") {
      setValidateMessage("*Enter Compressor House Name");
      //      setValidateMessage("*Enter Compressor House Name");
    } else if (
      PowerDefaultRate == "" ||
      PowerDefaultRate == null ||
      PowerDefaultRate == "undefined" ||
      CapDefaultRate == "" ||
      CapDefaultRate == null ||
      CapDefaultRate == "undefined" ||
      PresDefaultRate == "" ||
      PresDefaultRate == "undefined" ||
      PresDefaultRate == null
    ) {
      setValidateMessage("*Select All Headers");
    } else {
      if (!bodyValidate()) {
        //if (ch_csu && ch_cfu && ch_pu) {
        for (let i = 0; i < compLine.length; i++) {
          compLine[i].cl_energy_consumption_per_day =
            ((compLine[i].cl_power * PowerDefaultRate) /
              (compLine[i].cl_efficiency / 100)) *
              compLine[i].cl_running_hrs_per_day *
              (compLine[i].cl_load_factor / 100) +
            ((compLine[i].cl_power * PowerDefaultRate) /
              (compLine[i].cl_efficiency / 100)) *
              (compLine[i].cl_unload / 100) *
              compLine[i].cl_running_hrs_per_day *
              (1 - compLine[i].cl_load_factor / 100) *
              compLine[i].cl_qty;

          compLine[i].cl_avg_icfm =decimalFloat(
            compLine[i].cl_capacity_flow *
            CapDefaultRate *
            (compLine[i].cl_comp_vol_efficiency / 100) *
            compLine[i].cl_qty *
            (compLine[i].cl_load_factor / 100));
        }
        console.log("compheader", compheader);
        // DefaultPressure={compheader.ch_controlsettings_unit}
        // DefaultPower={compheader.ch_power_unit_rate}
        // DefaultCapacity={compheader.ch_capacity_unit}

        compLine.push({
          cl_enquiry_id: +addLine[0]?.cl_enquiry_id,
          cl_compressor_id: +addLine[0]?.cl_compressor_id,
          cl_make: addLine[0].cl_make,
          cl_model: addLine[0]?.cl_model,
          cl_compressor_type: addLine[0]?.cl_compressor_type,
          cl_lub_non_lub: addLine[0]?.cl_lub_non_lub,
          cl_pressure: addLine[0]?.cl_pressure,
          cl_capacity_flow: +addLine[0]?.cl_capacity_flow,
          cl_power: +addLine[0]?.cl_power,
          cl_efficiency: +addLine[0]?.cl_efficiency,
          cl_voltage: addLine[0]?.cl_voltage,
          cl_starter_type: addLine[0]?.cl_starter_type,
          cl_comp_vol_efficiency: +addLine[0]?.cl_comp_vol_efficiency,
          cl_qty: +addLine[0]?.cl_qty,
          cl_running_hrs_per_day: +addLine[0]?.cl_running_hrs_per_day,
          cl_unload: +addLine[0]?.cl_unload,
          cl_load_factor: +addLine[0]?.cl_load_factor,
          cl_energy_consumption_per_day:
            ((+addLine[0].cl_power * PowerDefaultRate) /
              (+addLine[0].cl_efficiency / 100)) *
              +addLine[0].cl_running_hrs_per_day *
              (+addLine[0].cl_load_factor / 100) +
            ((+addLine[0].cl_power * PowerDefaultRate) /
              (+addLine[0].cl_efficiency / 100)) *
              (+addLine[0].cl_unload / 100) *
              +addLine[0].cl_running_hrs_per_day *
              (1 - +addLine[0].cl_load_factor / 100) *
              +addLine[0].cl_qty,
          cl_avg_icfm:decimalFloat(
            +addLine[0].cl_capacity_flow *
            CapDefaultRate *
            (+addLine[0].cl_comp_vol_efficiency / 100) *
            +addLine[0].cl_qty *
            (+addLine[0].cl_load_factor / 100)),
          cl_line_id: 0,
        });
        var addsingleLine = false;
        var addCompLineData = [];
        console.log("compheadercapacity", ch_cfu, props.DefaultCapacity);
        console.log("compheadercontrol", ch_csu, props.DefaultPressure);
        console.log("compheaderPower", ch_pu, props.DefaultPower);

        if (
          ch_cfu === props.DefaultCapacity &&
          ch_csu === props.DefaultPressure &&
          ch_pu === props.DefaultPower
        ) {
          addsingleLine = true;
          addCompLineData.push(compLine[compLine.length - 1]);
        } else {
          addsingleLine = false;
        }
        let bodyData = {
          compHeader: {
            ch_enquiry_id: +addLine[0]?.cl_enquiry_id || "",
            ch_controlsettings_unit:
              compheader.ch_controlsettings_unit || ch_csu,
            ch_capacity_unit: compheader.ch_capacity_unit || ch_cfu,
            ch_power_unit: compheader.ch_power_unit || ch_pu,
            ch_controlsettings_unit_rate: PresDefaultRate,
            ch_capacity_unit_rate: CapDefaultRate,
            ch_power_unit_rate: PowerDefaultRate,
            ch_comp_house_name: Name,
          },
          compLine: addsingleLine ? addCompLineData : compLine,
        };

        console.log("bodyData", bodyData);
        setLoading(true);
        setValidateMessage("");
        axios
          .post("/maintenance/compDetail", bodyData)
          .then((response) => {
            console.log(response);
            if (
              response.data.message.includes(
                "fails to match the required pattern"
              ) ||
              response.data.message.includes("must be")
            ) {
              let s = response.data.message;
              s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
              console.log("In Weird Pattern", s);

              props.setShowMessageWarning(
                "Special Characters not allowed in " + s
              );
            } else {
              if (response.status === 200) {
                props.fetchCompHouseHeader();
                props.setShowMessage(
                  "Great! You have Added Compressor Successfully"
                );
                setListDot(true);
                props.closeModal();
                props.calculateEnergy();
              }
             
            }
            props.setActive(active);
          })
          .catch((e) => {
            console.log("after post- error----------", e);
            //setValidateMessage(e);
            props.setShowMessageWarning(
              "Problem in adding compressor. Please try again!"
            );
            props.fetchCompHouseHeader();
          })
          .finally(() => {
            setLoading(false);
          });
        // } else {
        //   setValidateMessage("*Enter All Values");
        // }
      }
      // else {
      //   setValidateMessage("*Select All Headers");
      // }
    }
  };
  //mayuredit
  const editCompressor = (value) => {
    if (Name == undefined || Name == "") {
      alert("Enter Compressor House Name");
      setValidateMessage("*Enter Compressor House Name");
    } else {
      if (!bodyValidate()) {
        for (let i = 0; i < compLine.length; i++) {
          compLine[i].cl_energy_consumption_per_day =
            ((compLine[i].cl_power * PowerDefaultRate) /
              (compLine[i].cl_efficiency / 100)) *
              compLine[i].cl_running_hrs_per_day *
              (compLine[i].cl_load_factor / 100) +
            ((compLine[i].cl_power * PowerDefaultRate) /
              (compLine[i].cl_efficiency / 100)) *
              (compLine[i].cl_unload / 100) *
              compLine[i].cl_running_hrs_per_day *
              (1 - compLine[i].cl_load_factor / 100) *
              compLine[i].cl_qty;

          compLine[i].cl_avg_icfm =decimalFloat(
            compLine[i].cl_capacity_flow *
            CapDefaultRate *
            (compLine[i].cl_comp_vol_efficiency / 100) *
            compLine[i].cl_qty *
            (compLine[i].cl_load_factor / 100));


            let compExclude=compLine.filter(data=>data.cl_line_id!=compLine[i].cl_line_id);
            console.log("srf1 compExclude",compExclude);
            // let sum=compExclude.reduce((sum,energycons)=>
            // parseFloat(sum)+parseFloat(energycons.cl_energy_consumption_per_day),0
            // );
            // let avg=compExclude.reduce((avg,energycons)=>
            // parseFloat(avg)+parseFloat(energycons.cl_avg_icfm),0
            // );
            let sum=compLine.reduce((sum,energycons)=>
      parseFloat(sum)+parseFloat(
        Number(decimalFloat(
          ((energycons.cl_power *
            (PowerDefaultRate)) /
            (energycons.cl_efficiency / 100)) *
            energycons.cl_running_hrs_per_day *
            (energycons.cl_load_factor / 100) +
            ((energycons.cl_power *
              (PowerDefaultRate)) /
              (energycons.cl_efficiency / 100)) *
              (energycons.cl_unload / 100) *
              energycons.cl_running_hrs_per_day *
              (1 -
                energycons.cl_load_factor / 100) *
              energycons.cl_qty
         ))
        ),0
      );
      let avg=compLine.reduce((avg,energycons)=>
      parseFloat(avg)+parseFloat(
        // energycons.cl_avg_icfm
        decimalFloat(
          Number(
            energycons.cl_capacity_flow *
            (CapDefaultRate) *
              (energycons.cl_comp_vol_efficiency /
                100) *
              energycons.cl_qty *
              (energycons.cl_load_factor / 100)
          )
        )
        ),0
      );

            setcompheader((prevState) => ({
              ...prevState,
              ch_energy_consumption_per_day: 
              decimalFloat(sum)
              +Number(decimalFloat(
                ((compLine[i].cl_power *
                  PowerDefaultRate) /
                  (compLine[i].cl_efficiency / 100)) *
                  compLine[i].cl_running_hrs_per_day *
                  (compLine[i].cl_load_factor / 100) +
                  ((compLine[i].cl_power *
                    PowerDefaultRate) /
                    (compLine[i].cl_efficiency / 100)) *
                    (compLine[i].cl_unload / 100) *
                    compLine[i].cl_running_hrs_per_day *
                    (1 -
                      compLine[i].cl_load_factor / 100) *
                    compLine[i].cl_qty
               )),
              ch_avg_icfm: decimalFloat(avg)+
              decimalFloat(
                Number(
                  compLine[i].cl_capacity_flow *
                    CapDefaultRate *
                    (compLine[i].cl_comp_vol_efficiency /
                      100) *
                    compLine[i].cl_qty *
                    (compLine[i].cl_load_factor / 100)
                )
              )
            }));
        }
        
        let bodyData = {
          compHeader: {
            ch_enquiry_id: +compHouse[active]?.ch_enquiry_id || "",
            ch_comp_house_id: +compHouse[active]?.ch_comp_house_id || "",
            ch_controlsettings_unit:
              compheader.ch_controlsettings_unit || ch_csu,
            ch_capacity_unit: compheader.ch_capacity_unit || ch_cfu,
            ch_power_unit: compheader.ch_power_unit || ch_pu,
            ch_capacity_unit_rate: CapDefaultRate,
            ch_power_unit_rate: PowerDefaultRate,
            ch_controlsettings_unit_rate: PresDefaultRate,
            ch_comp_house_name: Name,
          },
          compLine,
        };

        // setValidateMessage("");
        setLoading(true);
        axios
          .post("/maintenance/compDetail", bodyData)
          .then((response) => {
            if (
              response.data.message.includes(
                "fails to match the required pattern"
              ) ||
              response.data.message.includes("must be")
            ) {
              let s = response.data.message;
              s = s.substring(s.indexOf("_") + 1, s.indexOf(":"));
              console.log("In Weird Pattern", s);

              props.setShowMessageWarning(
                "Special Characters not allowed in " + s
              );
            } else {
              if (response.status === 200) {
                props.fetchCompHouseHeader();
                props.setShowMessage(
                  "Great! You have Updated Compressor Successfully"
                );
                props.calculateEnergy();

                props.closeModal();
              }
             
            }
          })
          .catch((e) => {
            console.log("after post- error----------", e);
            setValidateMessage(e);
            props.setShowMessageWarning(
              "Problem in updating compressor details. Please try again!"
            );
          })
          .finally(() => {
            setLoading(true);
          });
      } else {
        setValidateMessage("*Enter All Values");
      }
    }
  };

  const handleyess = async () => {
    console.log(EventParams, EventParams1);
    let result;
    console.log(EventParams, EventParams1);
    if (EventParams1 === "ch_controlsettings_unit") {
      result = await getUnitConversionRate(EventParams, "Pressure");
      console.log(result);
      await setPresDefaultRate(result);
      ch_csu = EventParams;
      compheader.ch_controlsettings_unit_rate = result;
    }

    if (EventParams1 === "ch_capacity_unit") {
      result = await getUnitConversionRate(EventParams, "Capacity");
      console.log(result);
      await setCapDefaultRate(result);
      ch_cfu = EventParams;
      compheader.ch_capacity_unit_rate = result;
    }

    if (EventParams1 === "ch_power_unit") {
      result = await getUnitConversionRate(EventParams, "Power");
      console.log(result);
      console.log(EventParams);
      await setPowerDefaultRate(result);
      ch_pu = EventParams;
      compheader.ch_power_unit_rate = result;
    }

    if(action=='Add' || action=='FirstAdd'){
      console.log("srf1 running",addLine[0].cl_running_hrs_per_day);
      // let sum=compLine.reduce((sum,energycons)=>
      // parseFloat(sum)+parseFloat(energycons.cl_energy_consumption_per_day),0
      // );
      // let avg=compLine.reduce((avg,energycons)=>
      // parseFloat(avg)+parseFloat(energycons.cl_avg_icfm),0
      // );
      let sum=compLine.reduce((sum,energycons)=>
      parseFloat(sum)+parseFloat(
        Number(decimalFloat(
          ((energycons.cl_power *
            (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
            (energycons.cl_efficiency / 100)) *
            energycons.cl_running_hrs_per_day *
            (energycons.cl_load_factor / 100) +
            ((energycons.cl_power *
              (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
              (energycons.cl_efficiency / 100)) *
              (energycons.cl_unload / 100) *
              energycons.cl_running_hrs_per_day *
              (1 -
                energycons.cl_load_factor / 100) *
              energycons.cl_qty
         ))
        ),0
      );
      let avg=compLine.reduce((avg,energycons)=>
      parseFloat(avg)+parseFloat(
        // energycons.cl_avg_icfm
        decimalFloat(
          Number(
            energycons.cl_capacity_flow *
            (EventParams1 === "ch_capacity_unit"?result:CapDefaultRate) *
              (energycons.cl_comp_vol_efficiency /
                100) *
              energycons.cl_qty *
              (energycons.cl_load_factor / 100)
          )
        )
        ),0
      );
      console.log("srf1 sum",sum);
      setcompheader((prevState) => ({
        ...prevState,
        ch_energy_consumption_per_day: 
        decimalFloat(sum)
        +Number(decimalFloat(
          ((addLine[0].cl_power *
            (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
            (addLine[0].cl_efficiency / 100)) *
            addLine[0].cl_running_hrs_per_day *
            (addLine[0].cl_load_factor / 100) +
            ((addLine[0].cl_power *
              (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
              (addLine[0].cl_efficiency / 100)) *
              (addLine[0].cl_unload / 100) *
              addLine[0].cl_running_hrs_per_day *
              (1 -
                addLine[0].cl_load_factor / 100) *
              addLine[0].cl_qty
         )),
        ch_avg_icfm: decimalFloat(avg)+
        decimalFloat(
          Number(
            addLine[0].cl_capacity_flow *
               (EventParams1 === "ch_capacity_unit"?result:CapDefaultRate)*
              (addLine[0].cl_comp_vol_efficiency /
                100) *
              addLine[0].cl_qty *
              (addLine[0].cl_load_factor / 100)
          )
        )
        
      }));
      console.log("srf1 sum",compheader.ch_energy_consumption_per_day);
    }
    else if(action=="Edit"){
      let compExclude=compLine.filter(data=>data.cl_line_id!=editLine[0].cl_line_id);
      console.log("srf1 compExclude",compExclude);
      let sum=compExclude.reduce((sum,energycons)=>
      parseFloat(sum)+parseFloat(
        Number(decimalFloat(
          ((energycons.cl_power *
            (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
            (energycons.cl_efficiency / 100)) *
            energycons.cl_running_hrs_per_day *
            (energycons.cl_load_factor / 100) +
            ((energycons.cl_power *
              (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
              (energycons.cl_efficiency / 100)) *
              (energycons.cl_unload / 100) *
              energycons.cl_running_hrs_per_day *
              (1 -
                energycons.cl_load_factor / 100) *
              energycons.cl_qty
         ))
        ),0
      );
      let avg=compExclude.reduce((avg,energycons)=>
      parseFloat(avg)+parseFloat(
        // energycons.cl_avg_icfm
        decimalFloat(
          Number(
            energycons.cl_capacity_flow *
            (EventParams1 === "ch_capacity_unit"?result:CapDefaultRate) *
              (energycons.cl_comp_vol_efficiency /
                100) *
              energycons.cl_qty *
              (energycons.cl_load_factor / 100)
          )
        )
        ),0
      );
      console.log("srf1 sum",sum);
      setcompheader((prevState) => ({
        ...prevState,
        ch_energy_consumption_per_day: 
        decimalFloat(sum)
        +Number(decimalFloat(
          ((editLine[0].cl_power *
            (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
            (editLine[0].cl_efficiency / 100)) *
            editLine[0].cl_running_hrs_per_day *
            (editLine[0].cl_load_factor / 100) +
            ((editLine[0].cl_power *
              (EventParams1 === "ch_power_unit"?result:PowerDefaultRate)) /
              (editLine[0].cl_efficiency / 100)) *
              (editLine[0].cl_unload / 100) *
              editLine[0].cl_running_hrs_per_day *
              (1 -
                editLine[0].cl_load_factor / 100) *
              editLine[0].cl_qty
         )),
        ch_avg_icfm: decimalFloat(avg)+
        decimalFloat(
          Number(
            editLine[0].cl_capacity_flow *
            (EventParams1 === "ch_capacity_unit"?result:CapDefaultRate) *
              (editLine[0].cl_comp_vol_efficiency /
                100) *
              editLine[0].cl_qty *
              (editLine[0].cl_load_factor / 100)
          )
        )
      }));
      console.log("comphouse",compHouse[active]);

      
      
    }

    setcompheader((prevState) => ({
      // setCompHouse((prevState) => ({
      ...prevState,
      [EventParams1]: EventParams,
    }));

    setShowUnitHeader(false);
  };

  function handlcloses() {
    console.log("Hi close");
    setShowUnitHeader(false);
  }
  return (
    <>
      <UnitConfirmModal
        show={ShowUnitHeader}
        handleYes={handleyess}
        handleClose={handlcloses}
      />
      {/* <Modal
        {...props}
        size="lg"
        className={showForm || show || show1 ? "opacity-0" : "opacity-100"}
      ></Modal> */}
      <div
        className="d-none d-md-block d-lg-block table-container"
        id={`AddCompressor-table-${id}`}
      >
        <Form name="compressorhouseform" autoComplete="off">
        {loading ? (
        <Loader />
      ) : null}

        
            <div className="tableWrapperCompressorModal">
              <div className="table-head tableHeadCompressorModal">
                <div className="table-row">
                  <div className="table-cell">
                    <span>Sr. No.</span>
                  </div>
                  <div className="table-cell">
                    <span>Make</span>
                  </div>
                  <div className="table-cell">
                    <span>Model</span>
                  </div>
                  <div className="table-cell">
                    <span>Comp Type</span>
                  </div>
                  <div className="table-cell">
                    <span>
                      Lubricated/
                      <br />
                      Non Lubricated
                    </span>
                  </div>

                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Control Setting
                      <div className="filter-input mt-1">
                        <span className="tableHeaderLabel">Parameter</span>
                        <select
                          disabled={
                            !(addLine.length > 0 || editLine.length > 0)
                          }
                          className="headerSelect"
                          value={compheader.ch_controlsettings_unit || ch_csu}
                          onChange={(e) => {
                            //debugger;
                            handleHeaderChange(e);
                            ch_csu = e.target.value;
                          }}
                          name="ch_controlsettings_unit"
                          id="ch_controlsettings_unit"
                        >
                          <option value="">Pressure</option>
                          {Pressure.map((fromunit, index) => (
                            <option value={fromunit}>{fromunit}</option>
                          ))}
                        </select>
                      </div>
                    </span>
                  </div>
                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Capacity
                      <div className="filter-input mt-1">
                        <span className="tableHeaderLabel">Parameter</span>
                        <select
                          disabled={
                            !(addLine.length > 0 || editLine.length > 0)
                          }
                          className="headerSelect"
                          value={compheader.ch_capacity_unit || ch_cfu}
                          onChange={(e) => {
                            handleHeaderChange(e);
                            ch_cfu = e.target.value;
                          }}
                          name="ch_capacity_unit"
                          id="ch_capacity_unit"
                        >
                          <option value="">Capacity</option>
                          {Capacity.map((fromunit, index) => (
                            <option value={fromunit}>{fromunit}</option>
                          ))}
                        </select>
                      </div>
                    </span>
                  </div>
                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Motor
                      <div className="filter-input mt-1">
                        <span className="tableHeaderLabel">Parameter</span>
                        <select
                          value={compheader.ch_power_unit || ch_pu}
                          disabled={
                            !(addLine.length > 0 || editLine.length > 0)
                          }
                          className="headerSelect"
                          onChange={(e) => {
                            handleHeaderChange(e);
                            ch_pu = e.target.value;
                          }}
                          name="ch_power_unit"
                          id="ch_power_unit"
                        >
                          <option value="">Power</option>
                          {Power.map((fromunit, index) => (
                            <option value={fromunit.value}>{fromunit}</option>
                          ))}
                        </select>
                      </div>
                    </span>
                  </div>
                  {/* </>
              ))} */}
                  <div className="table-cell">
                    <span>Motor Efficiency</span>
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel"> %</span>
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Motor Voltage </span>
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel">Volt</span>
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>Motor Starter Type</span>
                  </div>
                  <div className="table-cell">
                    <span>Comp. Vol. Efficiency</span>
                    <div className="filter-input mt-1">
                      <span className="tableHeaderLabel"> %</span>
                    </div>
                  </div>
                  <div className="table-cell">
                    <span>QTY</span>
                  </div>
                  <div className="table-cell">
                    <span>Running hrs Per Day</span>
                  </div>
                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Unloading Power
                      <div className="filter-input mt-1">
                        <span className="tableHeaderLabel">%</span>
                      </div>
                    </span>
                  </div>
                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Load Factor
                      <div className="filter-input mt-1">
                        <span className="tableHeaderLabel">%</span>
                      </div>
                    </span>
                  </div>
                  <div className="table-cell">
                    <span style={{ width: "190px" }}>
                      Energy Consumption Per Day
                      <span className="tableHeaderLabel"> kWH/day</span>
                    </span>
                  </div>
                  <div className="table-cell">
                    <span>Avg iCFM</span>
                  </div>
                  <div className="table-cell">
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="table-body scroll-compline tableBodyCompressorModal">
                {compLine?.length > 0 &&
                  compLine.map((item, index) => {
                    return (
                      <>
                        <div
                          className="table-row"
                          key={`row_${index}`}
                          id={`row_${index}`}
                        >
                          <div className="table-cell">
                            {item.edit ? (
                              <input
                                type="text"
                                name="cl_srno"
                                className="compTextWidth"
                                id={`cl_srno${index}`}
                                value={`${index + 1}`}
                                onChange={(e) => handleChange(e, index, "edit")}
                                readOnly
                              />
                            ) : (
                              index + 1
                            )}
                          </div>
                          <div className="table-cell tableCellPopup">
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    required
                                    readOnly
                                    name="cl_make"
                                    className="compTextWidth"
                                    id={`cl_make${index}`}
                                    value={item.cl_make}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Air Compressor Make",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_make
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_make && (
                                  <div className="text-danger">
                                    {errorLine.cl_make}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_make
                            )}

                            {/* compParameter */}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_model"
                                  id={`cl_model${index}`}
                                  className="compTextWidth"
                                  //value={}
                                  value={item.cl_model}
                                  maxLength={50}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = alphaNumeric50(
                                      e.target.value,
                                      "CL Model"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_model: error,
                                    }));
                                  }}
                                />
                                {errorLine.cl_model && (
                                  <div className="text-danger">
                                    {errorLine.cl_model}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_model
                            )}
                          </div>
                          <div className="table-cell tableCellPopup">
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    className="compTextWidth"
                                    required
                                    readOnly
                                    name="cl_compressor_type"
                                    id={`cl_compressor_type${index}`}
                                    value={item.cl_compressor_type}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Air Compressor Type",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_compressor_type
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_compressor_type && (
                                  <div className="text-danger">
                                    {errorLine.cl_compressor_type}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_compressor_type
                            )}
                            {/* compParameter */}
                          </div>
                          <div className="table-cell tableCellPopup">
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    className="compTextWidth"
                                    required
                                    readOnly
                                    name="cl_lub_non_lub"
                                    id={`cl_lub_non_lub${index}`}
                                    value={compLine[index].cl_lub_non_lub}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Lub/Non Lub",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_lub_non_lub
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_lub_non_lub && (
                                  <div className="text-danger">
                                    {errorLine.cl_lub_non_lub}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_lub_non_lub
                            )}
                            {/* compParameter */}
                            {/* popUp */}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_pressure"
                                  className="compTextWidth"
                                  id={`cl_pressure${index}`}
                                  value={compLine[index].cl_pressure}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = isAlphanumericWithDash(
                                      e.target.value,
                                      "CL Pressure"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_pressure: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_pressure && (
                                  <div className="text-danger">
                                    {errorLine.cl_pressure}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_pressure
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_capacity_flow"
                                  id={`cl_capacity_flow${index}`}
                                  value={compLine[index].cl_capacity_flow}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validTenDecimals(
                                      e.target.value,
                                      "Capacity Flow"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_capacity_flow: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_capacity_flow && (
                                  <div className="text-danger">
                                    {errorLine.cl_capacity_flow}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_capacity_flow
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_power"
                                  id={`cl_power${index}`}
                                  value={compLine[index].cl_power}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validFourDecimals(
                                      e.target.value,
                                      "Power"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_power: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_power && (
                                  <div className="text-danger">
                                    {errorLine.cl_power}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_power
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_efficiency"
                                  id={`cl_efficiency${index}`}
                                  value={compLine[index].cl_efficiency}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_efficiency: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_efficiency && (
                                  <div className="text-danger">
                                    {errorLine.cl_efficiency}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_efficiency
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_voltage"
                                  id={`cl_voltage${index}`}
                                  value={compLine[index].cl_voltage}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validFourDecimals(
                                      e.target.value,
                                      "Voltage"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_voltage: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_voltage && (
                                  <div className="text-danger">
                                    {errorLine.cl_voltage}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_voltage
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <select
                                  className="bodySelect compTextWidth"
                                  required
                                  name="cl_starter_type"
                                  id={`cl_starter_type${index}`}
                                  value={item.cl_starter_type}
                                  onChange={(e) => {
                                    handleChange(e, index, "edit");
                                  }}
                                  // onInput={(e) => {
                                  //   let error = isSelectEmpty(
                                  //     e.target.value,
                                  //     "Select a Value"
                                  //   );
                                  //   setErrorLine((prevState) => ({
                                  //     ...prevState,
                                  //     cl_starter_type: error,
                                  //   }));
                                  // }}
                                >
                                  <option value="">Starter Type</option>
                                  <option value="STAR DELTA">STAR DELTA</option>
                                  <option value="DELTA">DELTA</option>
                                  <option value="VFD">VFD</option>
                                  <option value="Centrifugal">
                                    Centrifugal
                                  </option>
                                </select>
                                {errorLine.cl_starter_type && (
                                  <div className="text-danger">
                                    {errorLine.cl_starter_type}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_starter_type
                            )}
                          </div>

                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_comp_vol_efficiency"
                                  value={compLine[index].cl_comp_vol_efficiency}
                                  id={`cl_comp_vol_efficiency${index}`}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_comp_vol_efficiency: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_comp_vol_efficiency && (
                                  <div className="text-danger">
                                    {errorLine.cl_comp_vol_efficiency}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_comp_vol_efficiency
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_qty"
                                  id={`cl_qty${index}`}
                                  value={compLine[index].cl_qty}
                                  readOnly
                                  // onChange={(e) => handleChange(e, index, "edit")}
                                  required
                                />
                              </>
                            ) : (
                              item.cl_qty
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_running_hrs_per_day"
                                  id={`cl_running_hrs_per_day${index}`}
                                  value={compLine[index].cl_running_hrs_per_day}
                                  onChange={(e) => {
                                    
                                   handleChange(e, index, "edit");
                                    if (e.target.value == 0) {
                                      let temp = [...compLine];
                                      temp[index].cl_load_factor = 0;
                                      setCompLine([...temp]);
                                    }
                                  }}
                                  onInput={(e) => {
                                    
                                    let error = number24(
                                      e.target.value,
                                      "Enter Value between 0 - 24"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_running_hrs_per_day: error,
                                    }));
                                  }}
                                  
                                  required
                                />
                                {errorLine.cl_running_hrs_per_day && (
                                  <div className="text-danger">
                                    {errorLine.cl_running_hrs_per_day}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_running_hrs_per_day
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_unload"
                                  id={`cl_unload${index}`}
                                  value={compLine[index].cl_unload}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_unload: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_unload && (
                                  <div className="text-danger">
                                    {errorLine.cl_unload}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_unload
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  className="compTextWidth"
                                  name="cl_load_factor"
                                  value={compLine[index].cl_load_factor}
                                  disabled={
                                    compLine[index].cl_running_hrs_per_day ==
                                      0 ||
                                    compLine[index].cl_running_hrs_per_day ===
                                      ""
                                  }
                                  id={`cl_load_factor${index}`}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_load_factor: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_load_factor && (
                                  <div className="text-danger">
                                    {errorLine.cl_load_factor}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_load_factor
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <input
                                type="number"
                                className="compTextWidth"
                                name="cl_energy_consumption_per_day"
                                id={`cl_energy_consumption_per_day${index}`}
                                //value=""
                                // onFocus={(e) => calculate(e, index)}
                                //defaultValue=" "

                                // ((compLine[index].cl_power *
                                //   PowerDefaultRate) /
                                //   (compLine[index].cl_efficiency / 100)) *
                                //   compLine[index].cl_running_hrs_per_day *
                                //   (compLine[index].cl_load_factor / 100) +
                                //   ((compLine[index].cl_power *
                                //     PowerDefaultRate) /
                                //     (compLine[index].cl_efficiency / 100)) *
                                //     (compLine[index].cl_unload / 100) *
                                //     compLine[index].cl_running_hrs_per_day *
                                //     (100 -
                                //       compLine[index].cl_load_factor / 100) *
                                //     compLine[index].cl_qty
                                value={decimalFloat(
                                  ((compLine[index].cl_power *
                                    PowerDefaultRate) /
                                    (compLine[index].cl_efficiency / 100)) *
                                    compLine[index].cl_running_hrs_per_day *
                                    (compLine[index].cl_load_factor / 100) +
                                    ((compLine[index].cl_power *
                                      PowerDefaultRate) /
                                      (compLine[index].cl_efficiency / 100)) *
                                      (compLine[index].cl_unload / 100) *
                                      compLine[index].cl_running_hrs_per_day *
                                      (1 -
                                        compLine[index].cl_load_factor / 100) *
                                      compLine[index].cl_qty
                                )}
                                // onChange={(e) => {
                                //   handleChange(e, index, "edit");
                                //   console.log("ch_energy_consumption_per_day",e.target.value)
                                //   setcompheader((prevState) => ({
                                //     ...prevState,
                                //     ch_energy_consumption_per_day: Number(compHouse[active]?.ch_energy_consumption_per_day)+Number(e.target.value)
                              
                                //   }));
                                  
                                // }}
                                //readOnly
                              />
                            ) : (
                              decimalFloat(
                                Number(
                                  ((compLine[index].cl_power *
                                    PowerDefaultRate) /
                                    (compLine[index].cl_efficiency / 100)) *
                                    compLine[index].cl_running_hrs_per_day *
                                    (compLine[index].cl_load_factor / 100) +
                                    ((compLine[index].cl_power *
                                      PowerDefaultRate) /
                                      (compLine[index].cl_efficiency / 100)) *
                                      (compLine[index].cl_unload / 100) *
                                      compLine[index].cl_running_hrs_per_day *
                                      (1 -
                                        compLine[index].cl_load_factor / 100) *
                                      compLine[index].cl_qty
                                )
                              )
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <input
                                type="number"
                                className="compTextWidth"
                                name="cl_avg_icfm"
                                id={`cl_avg_icfm${index}`}
                                //value={compLine.cl_avg_icfm}
                                value={decimalFloat(
                                  Number(
                                    compLine[index].cl_capacity_flow *
                                      CapDefaultRate *
                                      (compLine[index].cl_comp_vol_efficiency /
                                        100) *
                                      compLine[index].cl_qty *
                                      (compLine[index].cl_load_factor / 100)
                                  )
                                )}
                                onChange={(e) => handleChange(e, index, "edit")}
                                readOnly
                              />
                            ) : (
                              decimalFloat(
                                Number(
                                  compLine[index].cl_capacity_flow *
                                    CapDefaultRate *
                                    (compLine[index].cl_comp_vol_efficiency /
                                      100) *
                                    compLine[index].cl_qty *
                                    (compLine[index].cl_load_factor / 100)
                                )
                              )
                            )}
                          </div>
                          <div className="table-cell cell-action cellActionCustom tableCellPopup">
                            {/* <div className="table-cell tableCellPopup"> */}
                            {!editAction && !item.edit && (
                              <div className="tableButtons">
                                <img
                                  src={Duplicate}
                                  alt="search"
                                  title="Duplicate"
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={(e) => handleAddClick2(item, index)}
                                />
                                {/* <BiEdit
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  size={32}
                                  style={{ padding: "5px" }}
                                  color={"blue"}
                                  onClick={(e) => handleEditRow(item, index)}
                                /> */}
                                <img
                                  src={Edit}
                                  alt="search"
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={(e) => handleEditRow(item, index)}
                                />
                                {/* <BiTrash
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  size={32}
                                  style={{ padding: "5px" }}
                                  color={"blue"}
                                  onClick={() => {
                                    // handleDelete(item, index);
                                    // setDeleteData(item);
                                    props.setShow1(
                                      true,
                                      item,
                                      compLine.length === 1 ? "last" : "regular"
                                    );
                                  }}
                                /> */}
                                <img
                                  src={Delete}
                                  alt="delete"
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={() => {
                                    props.setShow1(
                                      true,
                                      item,
                                      compLine.length === 1 ? "last" : "regular"
                                    );
                                  }}
                                />
                                
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                {addLine.length > 0 &&
                  addLine.map((item, index) => {
                    return (
                      <>
                        <div
                          className="table-row"
                          key={`row_${index}`}
                          id={`row_${index}`}
                        >
                          <div className="table-cell">
                            <input
                              type="text"
                              name="srno"
                              id={`srno${index}`}
                              value={`${compLine?.length + 1}`}
                              onChange={(e) => handleChange(e, index, "add")}
                              className="compTextWidth"
                              readOnly
                            />
                          </div>
                          <div className="table-cell">
                            <div className="tableCellPopup">
                              <input
                                type="text"
                                className="compTextWidth"
                                required
                                readOnly
                                name="cl_make"
                                id={`cl_make${index}`}
                                value={addLine[index].cl_make}
                                onChange={(e) => handleChange(e, index, "add")}
                              />
                              <BiSelectMultiple
                                size={20}
                                onClick={() =>
                                  showPopUp(
                                    "Air Compressor Make",
                                    "Single",
                                    "comp_parametervalue",
                                    index,
                                    "add",
                                    addLine[index].cl_make
                                  )
                                }
                                color={"gray"}
                                style={{ marginLeft: "-2rem" }}
                              />
                            </div>
                            {errorLine.cl_make && (
                              <div className="text-danger">
                                {errorLine.cl_make}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_model"
                              id={`cl_model${index}`}
                              className="compTextWidth"
                              //value={}
                              value={addLine[index].cl_model}
                              maxLength={50}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = alphaNumeric50(
                                  e.target.value,
                                  "CL Model"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_model: error,
                                }));
                              }}
                            />
                            {errorLine.cl_model && (
                              <div className="text-danger">
                                {errorLine.cl_model}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <div className="tableCellPopup">
                              <input
                                type="text"
                                required
                                readOnly
                                className="compTextWidth"
                                name="cl_compressor_type"
                                id={`cl_compressor_type${index}`}
                                value={addLine[index].cl_compressor_type}
                                onChange={(e) => handleChange(e, index, "add")}
                              />
                              <BiSelectMultiple
                                size={20}
                                onClick={() =>
                                  showPopUp(
                                    "Air Compressor Type",
                                    "Single",
                                    "comp_parametervalue",
                                    index,
                                    "add",
                                    addLine[index].cl_compressor_type
                                  )
                                }
                                color={"gray"}
                                style={{ marginLeft: "-2rem" }}
                              />
                            </div>
                            {errorLine.cl_compressor_type && (
                              <div className="text-danger">
                                {errorLine.cl_compressor_type}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <div className="tableCellPopup">
                              <input
                                type="text"
                                required
                                readOnly
                                className="compTextWidth"
                                name="cl_lub_non_lub"
                                id={`cl_lub_non_lub${index}`}
                                value={addLine[index].cl_lub_non_lub}
                                onChange={(e) => handleChange(e, index, "add")}
                              />
                              <BiSelectMultiple
                                size={20}
                                onClick={() =>
                                  showPopUp(
                                    "Lub/Non Lub",
                                    "Single",
                                    "comp_parametervalue",
                                    index,
                                    "add",
                                    addLine[index].cl_lub_non_lub
                                  )
                                }
                                color={"gray"}
                                style={{ marginLeft: "-2rem" }}
                              />
                            </div>
                            {errorLine.cl_lub_non_lub && (
                              <div className="text-danger">
                                {errorLine.cl_lub_non_lub}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              //mayurp
                              type="text"
                              name="cl_pressure"
                              id={`cl_pressure${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_pressure}
                              maxlength="10"
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = isAlphanumericWithDash(
                                  e.target.value,
                                  "CL Pressure"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_pressure: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_pressure && (
                              <div className="text-danger">
                                {errorLine.cl_pressure}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_capacity_flow"
                              id={`cl_capacity_flow${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_capacity_flow}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = validTenDecimals(
                                  e.target.value,
                                  "Capacity Flow"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_capacity_flow: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_capacity_flow && (
                              <div className="text-danger">
                                {errorLine.cl_capacity_flow}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="number"
                              name="cl_power"
                              id={`cl_power${index}`}
                              className="compTextWidth"
                              maxlength="4"
                              value={addLine[index].cl_power}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = validFourDecimals(
                                  e.target.value,
                                  "Power"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_power: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_power && (
                              <div className="text-danger">
                                {errorLine.cl_power}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_efficiency"
                              id={`cl_efficiency${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_efficiency}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = percent100(
                                  e.target.value,
                                  "Enter Value between 0 - 100"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_efficiency: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_efficiency && (
                              <div className="text-danger">
                                {errorLine.cl_efficiency}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_voltage"
                              id={`cl_voltage${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_voltage}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = validFourDecimals(
                                  e.target.value,
                                  "Voltage"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_voltage: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_voltage && (
                              <div className="text-danger">
                                {errorLine.cl_voltage}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <select
                              className="bodySelect compTextWidth"
                              required
                              name="cl_starter_type"
                              id={`cl_starter_type${index}`}
                              value={addLine[index].cl_starter_type}
                              onChange={(e) => handleChange(e, index, "add")}
                            >
                              <option value="">Starter Type</option>
                              <option value="STAR DELTA">STAR DELTA</option>
                              <option value="DELTA">DELTA</option>
                              <option value="VFD">VFD</option>
                              <option value="Centrifugal">Centrifugal</option>
                            </select>
                            {errorLine.cl_starter_type && (
                              <div className="text-danger">
                                {errorLine.cl_starter_type}
                              </div>
                            )}
                          </div>

                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_comp_vol_efficiency"
                              value={addLine[index].cl_comp_vol_efficiency}
                              id={`cl_comp_vol_efficiency${index}`}
                              className="compTextWidth"
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = percent100(
                                  e.target.value,
                                  "Enter Value between 0 - 100"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_comp_vol_efficiency: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_comp_vol_efficiency && (
                              <div className="text-danger">
                                {errorLine.cl_comp_vol_efficiency}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_qty"
                              id={`cl_qty${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_qty}
                              readOnly
                              required
                            />
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_running_hrs_per_day"
                              id={`cl_running_hrs_per_day${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_running_hrs_per_day}
                              onChange={(e) => {
                                handleChange(e, index, "add");
                                
                                if (e.target.value == 0) {
                                  let temp = [...addLine];
                                  temp[index].cl_load_factor = 0;
                                  setAddLine([...temp]);
                                }
                              }}
                              onInput={(e) => {
                                let error = number24(
                                  e.target.value,
                                  "Enter Value between 0 - 24"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_running_hrs_per_day: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_running_hrs_per_day && (
                              <div className="text-danger">
                                {errorLine.cl_running_hrs_per_day}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_unload"
                              id={`cl_unload${index}`}
                              className="compTextWidth"
                              value={addLine[index].cl_unload}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = percent100(
                                  e.target.value,
                                  "Enter Value between 0 - 100"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_unload: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_unload && (
                              <div className="text-danger">
                                {errorLine.cl_unload}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="text"
                              name="cl_load_factor"
                              className="compTextWidth"
                              disabled={
                                addLine[index].cl_running_hrs_per_day == 0 ||
                                addLine[index].cl_running_hrs_per_day === ""
                              }
                              value={addLine[index].cl_load_factor}
                              id={`cl_load_factor${index}`}
                              onChange={(e) => handleChange(e, index, "add")}
                              onInput={(e) => {
                                let error = percent100(
                                  e.target.value,
                                  "Enter Value between 0 - 100"
                                );
                                setErrorLine((prevState) => ({
                                  ...prevState,
                                  cl_load_factor: error,
                                }));
                              }}
                              required
                            />
                            {errorLine.cl_load_factor && (
                              <div className="text-danger">
                                {errorLine.cl_load_factor}
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <input
                              type="number"
                              className="compTextWidth"
                              name="cl_energy_consumption_per_day"
                              id={`cl_energy_consumption_per_day${index}`}
                              value={decimalFloat(
                                Number(
                                  ((addLine[index].cl_power *
                                    PowerDefaultRate) /
                                    (addLine[index].cl_efficiency / 100)) *
                                    addLine[index].cl_running_hrs_per_day *
                                    (addLine[index].cl_load_factor / 100) +
                                    ((addLine[index].cl_power *
                                      PowerDefaultRate) /
                                      (addLine[index].cl_efficiency / 100)) *
                                      (addLine[index].cl_unload / 100) *
                                      addLine[index].cl_running_hrs_per_day *
                                      (1 -
                                        addLine[index].cl_load_factor / 100) *
                                      addLine[index].cl_qty
                                )
                              )}
                    
                              
                              readOnly
                            />
                          </div>
                          <div className="table-cell">
                            <input
                              type="number"
                              name="cl_avg_icfm"
                              className="compTextWidth"
                              id={`cl_avg_icfm${index}`}
                              value={decimalFloat(
                                Number(
                                  addLine[index].cl_capacity_flow *
                                    CapDefaultRate *
                                    (addLine[index].cl_comp_vol_efficiency /
                                      100) *
                                    addLine[index].cl_qty *
                                    (addLine[index].cl_load_factor / 100)
                                )
                              )}
                              onChange={(e) => handleChange(e, index, "add")}
                              readOnly
                            />
                          </div>
                          <div className="table-cell cell-action cellActionCustom"></div>
                        </div>
                        {validateMessage && (
                          <div className="text-danger">{validateMessage}</div>
                        )}
                      </>
                    );
                  })}
                <div>
                  <div
                    ref={textInput}
                    style={{ float: "left", clear: "both" }}
                  />
                </div>
              </div>
            </div>
          
        </Form>
      </div>
      <div
        className="d-md-none d-lg-none table-container"
        id={`AddCompressor-table-${id}`}
      >
        <Form name="compressorhouseform" autoComplete="off">
          <div className="tableWrapperCompressorModal mobileTableModal">
            <div className="table-body tableBodyCompressorModal">
              {compLine?.length > 0 &&
                compLine.map((item, index) => {
                  return (
                    <>
                      <div className="outerTabDiv">
                        <div className="statusHighlight"></div>
                        <div
                          className={
                            "table-row1 " +
                            (item.edit
                              ? "mobileTableRowEdit "
                              : "mobileTableRow ") +
                            (showmoreless.includes(index)? "showLess" : "showFull")
                          }
                          key={`row_${index}`}
                          id={`row_${index}`}
                        >
                          <div
                            className="table-cell justify-content-end tableCellPopup"
                            style={{ position: "relative" }}
                          >
                            {!editAction && !item.edit && (
                              <div className="tableButtons">
                                <img
                                  src={Duplicate}
                                  alt="search"
                                  style={{ padding: "0px 15px 0px 10px" }}
                                  title="Duplicate"
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={(e) => handleAddClick2(item, index)}
                                />
                                {/* <BiEdit
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  size={32}
                                  style={{ padding: "5px" }}
                                  color={"blue"}
                                  onClick={(e) => {
                                    handleEditRow(item, index);
                                    compLine[index].show = true;
                                  }}
                                /> */}
                                <img
                                  src={Edit}
                                  alt="search"
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={(e) => {
                                    handleEditRow(item, index);
                                    compLine[index].show = true;
                                  }}
                                />
                                {/* <BiTrash
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  size={32}
                                  style={{ padding: "5px" }}
                                  color={"blue"}
                                  onClick={() => {
                                    props.setShow1(
                                      true,
                                      item,
                                      compLine.length === 1 ? "last" : "regular"
                                    );
                                  }}
                                /> */}
                                <img
                                  src={Delete}
                                  alt="delete"
                                  style={{ padding: "0px 10px" }}
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  onClick={() => {
                                    props.setShow1(
                                      true,
                                      item,
                                      compLine.length === 1 ? "last" : "regular"
                                    );
                                  }}
                                />
                                
                              </div>
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Sr. No.</span>
                            {item.edit ? (
                              <input
                                type="text"
                                name="cl_srno"
                                id={`cl_srno${index}`}
                                value={`${index + 1}`}
                                onChange={(e) => handleChange(e, index, "edit")}
                                readOnly
                              />
                            ) : (
                              index + 1
                            )}
                          </div>
                          <div className="table-cell tableCellPopup">
                            <span>Make</span>
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    required
                                    readOnly
                                    name="cl_make"
                                    id={`cl_make${index}`}
                                    value={item.cl_make}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Air Compressor Make",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_make
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_make && (
                                  <div className="text-danger">
                                    {errorLine.cl_make}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_make
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Model</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_model"
                                  id={`cl_model${index}`}
                                  value={item.cl_model}
                                  maxLength={50}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = alphaNumeric50(
                                      e.target.value,
                                      "CL Model"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_model: error,
                                    }));
                                  }}
                                />
                                {errorLine.cl_model && (
                                  <div className="text-danger">
                                    {errorLine.cl_model}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_model
                            )}
                          </div>
                          <div className="table-cell tableCellPopup">
                            <span>Comp Type</span>
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    required
                                    readOnly
                                    name="cl_compressor_type"
                                    id={`cl_compressor_type${index}`}
                                    value={item.cl_compressor_type}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Air Compressor Type",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_compressor_type
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_compressor_type && (
                                  <div className="text-danger">
                                    {errorLine.cl_compressor_type}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_compressor_type
                            )}
                          </div>
                          <div className="table-cell tableCellPopup">
                            <span>Lubricate/Non Lubricate</span>
                            {item.edit ? (
                              <>
                                <div className="tableCellPopup">
                                  <input
                                    type="text"
                                    required
                                    readOnly
                                    name="cl_lub_non_lub"
                                    id={`cl_lub_non_lub${index}`}
                                    value={compLine[index].cl_lub_non_lub}
                                    onChange={(e) =>
                                      handleChange(e, index, "edit")
                                    }
                                  />
                                  <BiSelectMultiple
                                    size={20}
                                    onClick={() =>
                                      showPopUp(
                                        "Lub/Non Lub",
                                        "Single",
                                        "comp_parametervalue",
                                        index,
                                        "edit",
                                        item.cl_lub_non_lub
                                      )
                                    }
                                    color={"gray"}
                                    style={{ marginLeft: "-2rem" }}
                                  />
                                </div>
                                {errorLine.cl_lub_non_lub && (
                                  <div className="text-danger">
                                    {errorLine.cl_lub_non_lub}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_lub_non_lub
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <span style={{ width: "100%" }}>
                                  Control Setting
                                  <div className="filter-input mt-1">
                                    <span className="tableHeaderLabel">
                                      Parameter
                                    </span>
                                    <select
                                      disabled={
                                        !(
                                          addLine.length > 0 ||
                                          editLine.length > 0
                                        )
                                      }
                                      className="headerSelect"
                                      value={
                                        compheader.ch_controlsettings_unit ||
                                        ch_csu
                                      }
                                      onChange={(e) => {
                                        handleHeaderChange(e);
                                        ch_csu = e.target.value;
                                      }}
                                      name="ch_controlsettings_unit"
                                      id="ch_controlsettings_unit"
                                    >
                                      <option value="">Pressure</option>
                                      {Pressure.map((fromunit, index) => (
                                        <option value={fromunit}>
                                          {fromunit}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </span>
                                <input
                                  type="text"
                                  name="cl_pressure"
                                  id={`cl_pressure${index}`}
                                  value={compLine[index].cl_pressure}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = isAlphanumericWithDash(
                                      e.target.value,
                                      "CL Pressure"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_pressure: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_pressure && (
                                  <div className="text-danger">
                                    {errorLine.cl_pressure}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <span>Pressure</span>
                                {item.cl_pressure}
                              </>
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <span style={{ width: "100%" }}>
                                  Capacity
                                  <div className="filter-input mt-1">
                                    <span className="tableHeaderLabel">
                                      Parameter
                                    </span>
                                    <select
                                      disabled={
                                        !(
                                          addLine.length > 0 ||
                                          editLine.length > 0
                                        )
                                      }
                                      className="headerSelect"
                                      value={
                                        compheader.ch_capacity_unit || ch_cfu
                                      }
                                      onChange={(e) => {
                                        handleHeaderChange(e);
                                        ch_cfu = e.target.value;
                                      }}
                                      name="ch_capacity_unit"
                                      id="ch_capacity_unit"
                                    >
                                      <option value="">Capacity</option>
                                      {Capacity.map((fromunit, index) => (
                                        <option value={fromunit}>
                                          {fromunit}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </span>{" "}
                                <input
                                  type="text"
                                  name="cl_capacity_flow"
                                  id={`cl_capacity_flow${index}`}
                                  value={compLine[index].cl_capacity_flow}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validTenDecimals(
                                      e.target.value,
                                      "Capacity Flow"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_capacity_flow: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_capacity_flow && (
                                  <div className="text-danger">
                                    {errorLine.cl_capacity_flow}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <span>Capacity</span>
                                {item.cl_capacity_flow}
                              </>
                            )}
                          </div>
                          <div className="table-cell">
                            {item.edit ? (
                              <>
                                <span style={{ width: "100%" }}>
                                  Motor
                                  <div className="filter-input mt-1">
                                    <span className="tableHeaderLabel">
                                      Parameter
                                    </span>
                                    <select
                                      value={compheader.ch_power_unit || ch_pu}
                                      disabled={
                                        !(
                                          addLine.length > 0 ||
                                          editLine.length > 0
                                        )
                                      }
                                      className="headerSelect"
                                      onChange={(e) => {
                                        handleHeaderChange(e);
                                        ch_pu = e.target.value;
                                      }}
                                      name="ch_power_unit"
                                      id="ch_power_unit"
                                    >
                                      <option value="">Power</option>
                                      {Power.map((fromunit, index) => (
                                        <option value={fromunit.value}>
                                          {fromunit}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </span>
                                <input
                                  type="text"
                                  name="cl_power"
                                  id={`cl_power${index}`}
                                  value={compLine[index].cl_power}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validFourDecimals(
                                      e.target.value,
                                      "Power"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_power: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_power && (
                                  <div className="text-danger">
                                    {errorLine.cl_power}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <span>Motor</span>
                                {item.cl_power}
                              </>
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Motor Efficiency</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_efficiency"
                                  id={`cl_efficiency${index}`}
                                  value={compLine[index].cl_efficiency}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_efficiency: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_efficiency && (
                                  <div className="text-danger">
                                    {errorLine.cl_efficiency}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_efficiency
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Motor Voltage</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_voltage"
                                  id={`cl_voltage${index}`}
                                  value={compLine[index].cl_voltage}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = validFourDecimals(
                                      e.target.value,
                                      "Voltage"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_voltage: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_voltage && (
                                  <div className="text-danger">
                                    {errorLine.cl_voltage}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_voltage
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Motor Starter Type</span>
                            {item.edit ? (
                              <>
                                <select
                                  className="bodySelect"
                                  required
                                  name="cl_starter_type"
                                  id={`cl_starter_type${index}`}
                                  value={item.cl_starter_type}
                                  onChange={(e) => {
                                    handleChange(e, index, "edit");
                                  }}
                                >
                                  <option value="">Starter Type</option>
                                  <option value="STAR DELTA">STAR DELTA</option>
                                  <option value="DELTA">DELTA</option>
                                  <option value="VFD">VFD</option>
                                  <option value="Centrifugal">
                                    Centrifugal
                                  </option>
                                </select>
                                {errorLine.cl_starter_type && (
                                  <div className="text-danger">
                                    {errorLine.cl_starter_type}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_starter_type
                            )}
                          </div>

                          <div className="table-cell">
                            <span>Comp. Vol. Efficiency</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_comp_vol_efficiency"
                                  value={compLine[index].cl_comp_vol_efficiency}
                                  id={`cl_comp_vol_efficiency${index}`}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_comp_vol_efficiency: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_comp_vol_efficiency && (
                                  <div className="text-danger">
                                    {errorLine.cl_comp_vol_efficiency}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_comp_vol_efficiency
                            )}
                          </div>
                          <div className="table-cell">
                            <span>QTY</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_qty"
                                  id={`cl_qty${index}`}
                                  value={compLine[index].cl_qty}
                                  readOnly
                                  required
                                />
                              </>
                            ) : (
                              item.cl_qty
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Running hrs Per Day</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_running_hrs_per_day"
                                  id={`cl_running_hrs_per_day${index}`}
                                  value={compLine[index].cl_running_hrs_per_day}
                                  onChange={(e) => {
                                    handleChange(e, index, "edit");
                                    
                                    if (e.target.value == 0) {
                                      let temp = [...editLine];
                                      temp[index].cl_load_factor = 0;
                                      setEditLine([...temp]);
                                    }
                                  }}
                                  onInput={(e) => {
                                    let error = number24(
                                      e.target.value,
                                      "Enter Value between 0 - 24"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_running_hrs_per_day: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_running_hrs_per_day && (
                                  <div className="text-danger">
                                    {errorLine.cl_running_hrs_per_day}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_running_hrs_per_day
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Unload</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_unload"
                                  id={`cl_unload${index}`}
                                  value={compLine[index].cl_unload}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_unload: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_unload && (
                                  <div className="text-danger">
                                    {errorLine.cl_unload}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_unload
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Load Factor</span>
                            {item.edit ? (
                              <>
                                <input
                                  type="text"
                                  name="cl_load_factor"
                                  value={compLine[index].cl_load_factor}
                                  id={`cl_load_factor${index}`}
                                  onChange={(e) =>
                                    handleChange(e, index, "edit")
                                  }
                                  onInput={(e) => {
                                    let error = percent100(
                                      e.target.value,
                                      "Enter Value between 0 - 100"
                                    );
                                    setErrorLine((prevState) => ({
                                      ...prevState,
                                      cl_load_factor: error,
                                    }));
                                  }}
                                  required
                                />
                                {errorLine.cl_load_factor && (
                                  <div className="text-danger">
                                    {errorLine.cl_load_factor}
                                  </div>
                                )}
                              </>
                            ) : (
                              item.cl_load_factor
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Energy Consumption Per Day</span>
                            {item.edit ? (
                              <input
                                type="number"
                                name="cl_energy_consumption_per_day1"
                                id={`cl_energy_consumption_per_day1${index}`}
                                value={decimalFloat(
                                  Number(
                                    ((compLine[index].cl_power *
                                      PowerDefaultRate) /
                                      (compLine[index].cl_efficiency / 100)) *
                                      compLine[index].cl_running_hrs_per_day *
                                      (compLine[index].cl_load_factor / 100) +
                                      ((compLine[index].cl_power *
                                        PowerDefaultRate) /
                                        (compLine[index].cl_efficiency / 100)) *
                                        (compLine[index].cl_unload / 100) *
                                        compLine[index].cl_running_hrs_per_day *
                                        (1 -
                                          compLine[index].cl_load_factor /
                                            100) *
                                        compLine[index].cl_qty
                                  )
                                )}
                                onChange={(e) => handleChange(e, index, "edit")}
                                readOnly
                              />
                            ) : (
                              decimalFloat(
                                Number(
                                  ((compLine[index].cl_power *
                                    PowerDefaultRate) /
                                    (compLine[index].cl_efficiency / 100)) *
                                    compLine[index].cl_running_hrs_per_day *
                                    (compLine[index].cl_load_factor / 100) +
                                    ((compLine[index].cl_power *
                                      PowerDefaultRate) /
                                      (compLine[index].cl_efficiency / 100)) *
                                      (compLine[index].cl_unload / 100) *
                                      compLine[index].cl_running_hrs_per_day *
                                      (1 -
                                        compLine[index].cl_load_factor / 100) *
                                      compLine[index].cl_qty
                                )
                              )
                            )}
                          </div>
                          <div className="table-cell">
                            <span>Avg iCFM</span>
                            {item.edit ? (
                              <input
                                type="number"
                                name="cl_avg_icfm"
                                id={`cl_avg_icfm${index}`}
                                value={decimalFloat(
                                  Number(
                                    compLine[index].cl_capacity_flow *
                                      CapDefaultRate *
                                      (compLine[index].cl_comp_vol_efficiency /
                                        100) *
                                      compLine[index].cl_qty *
                                      (compLine[index].cl_load_factor / 100)
                                  )
                                )}
                                onChange={(e) => handleChange(e, index, "edit")}
                                readOnly
                              />
                            ) : (
                              decimalFloat(
                                Number(
                                  compLine[index].cl_capacity_flow *
                                    CapDefaultRate *
                                    (compLine[index].cl_comp_vol_efficiency /
                                      100) *
                                    compLine[index].cl_qty *
                                    (compLine[index].cl_load_factor / 100)
                                )
                              )
                            )}
                          </div>
                        </div>
                        {!item.edit && (
                          <div className="showButton">
                            {!showmoreless.includes(index) && (
                              <span
                                onClick={() => {
                                  // let temp = [...compLine];
                                  // temp[index].show = true;
                                  // setCompLine([...temp]);
                                  let temp = [...showmoreless];
                                  temp.push(index);
                                  setshowmoreless([...temp])
                                }}
                              >
                                Show More
                              </span>
                            )}
                            {showmoreless.includes(index) && (
                              <span
                                onClick={() => {
                                  // let temp = [...compLine];
                                  // temp[index].show = false;
                                  // setCompLine([...temp]);
                                  let temp = [...showmoreless];
                                  const element = temp.indexOf(index);
                                    if (element > -1) { 
                                      temp.splice(element, 1); 
                                    }
                                  setshowmoreless([...temp])
                                }}
                              >
                                Show Less
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              {addLine.length > 0 &&
                addLine.map((item, index) => {
                  return (
                    <>
                      <div
                        className="table-row mobileTableRowEdit"
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        <div className="table-cell">
                          <span>Sr. No.</span>
                          <input
                            type="text"
                            name="srno"
                            id={`srno${index}`}
                            value={`${compLine?.length + 1}`}
                            onChange={(e) => handleChange(e, index, "add")}
                            readOnly
                          />
                        </div>
                        <div className="table-cell">
                          <div className="tableCellPopup">
                            <span>Make</span>
                            <input
                              type="text"
                              required
                              readOnly
                              name="cl_make"
                              id={`cl_make${index}`}
                              value={addLine[index].cl_make}
                              onChange={(e) => handleChange(e, index, "add")}
                            />
                            <BiSelectMultiple
                              size={20}
                              onClick={() =>
                                showPopUp(
                                  "Air Compressor Make",
                                  "Single",
                                  "comp_parametervalue",
                                  index,
                                  "add",
                                  addLine[index].cl_make
                                )
                              }
                              color={"gray"}
                              style={{ marginLeft: "-2rem" }}
                            />
                          </div>
                          {errorLine.cl_make && (
                            <div className="text-danger">
                              {errorLine.cl_make}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Model</span>
                          <input
                            type="text"
                            name="cl_model"
                            id={`cl_model${index}`}
                            //value={}
                            value={addLine[index].cl_model}
                            maxLength={50}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = alphaNumeric50(
                                e.target.value,
                                "CL Model"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_model: error,
                              }));
                            }}
                          />
                          {errorLine.cl_model && (
                            <div className="text-danger">
                              {errorLine.cl_model}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <div className="tableCellPopup">
                            <span>Comp Type</span>
                            <input
                              type="text"
                              required
                              readOnly
                              name="cl_compressor_type"
                              id={`cl_compressor_type${index}`}
                              value={addLine[index].cl_compressor_type}
                              onChange={(e) => handleChange(e, index, "add")}
                            />
                            <BiSelectMultiple
                              size={20}
                              onClick={() =>
                                showPopUp(
                                  "Air Compressor Type",
                                  "Single",
                                  "comp_parametervalue",
                                  index,
                                  "add",
                                  addLine[index].cl_compressor_type
                                )
                              }
                              color={"gray"}
                              style={{ marginLeft: "-2rem" }}
                            />
                          </div>
                          {errorLine.cl_compressor_type && (
                            <div className="text-danger">
                              {errorLine.cl_compressor_type}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <div className="tableCellPopup">
                            <span>Lubricate/Non Lubricate</span>
                            <input
                              type="text"
                              required
                              readOnly
                              name="cl_lub_non_lub"
                              id={`cl_lub_non_lub${index}`}
                              value={addLine[index].cl_lub_non_lub}
                              onChange={(e) => handleChange(e, index, "add")}
                            />
                            <BiSelectMultiple
                              size={20}
                              onClick={() =>
                                showPopUp(
                                  "Lub/Non Lub",
                                  "Single",
                                  "comp_parametervalue",
                                  index,
                                  "add",
                                  addLine[index].cl_lub_non_lub
                                )
                              }
                              color={"gray"}
                              style={{ marginLeft: "-2rem" }}
                            />
                          </div>
                          {errorLine.cl_lub_non_lub && (
                            <div className="text-danger">
                              {errorLine.cl_lub_non_lub}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "190px" }}>
                            Control Setting
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                disabled={
                                  !(addLine.length > 0 || editLine.length > 0)
                                }
                                className="headerSelect"
                                value={
                                  compheader.ch_controlsettings_unit || ch_csu
                                }
                                onChange={(e) => {
                                  handleHeaderChange(e);
                                  ch_csu = e.target.value;
                                }}
                                name="ch_controlsettings_unit"
                                id="ch_controlsettings_unit"
                              >
                                <option value="">Pressure</option>
                                {Pressure.map((fromunit, index) => (
                                  <option value={fromunit}>{fromunit}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="text"
                            name="cl_pressure"
                            id={`cl_pressure${index}`}
                            value={addLine[index].cl_pressure}
                            maxlength="10"
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = isAlphanumericWithDash(
                                e.target.value,
                                "CL Pressure"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_pressure: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_pressure && (
                            <div className="text-danger">
                              {errorLine.cl_pressure}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "190px" }}>
                            Capacity
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                disabled={
                                  !(addLine.length > 0 || editLine.length > 0)
                                }
                                className="headerSelect"
                                value={compheader.ch_capacity_unit || ch_cfu}
                                onChange={(e) => {
                                  handleHeaderChange(e);
                                  ch_cfu = e.target.value;
                                }}
                                name="ch_capacity_unit"
                                id="ch_capacity_unit"
                              >
                                <option value="">Capacity</option>
                                {Capacity.map((fromunit, index) => (
                                  <option value={fromunit}>{fromunit}</option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="text"
                            name="cl_capacity_flow"
                            id={`cl_capacity_flow${index}`}
                            value={addLine[index].cl_capacity_flow}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = validTenDecimals(
                                e.target.value,
                                "Capacity Flow"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_capacity_flow: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_capacity_flow && (
                            <div className="text-danger">
                              {errorLine.cl_capacity_flow}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span style={{ width: "190px" }}>
                            Motor
                            <div className="filter-input mt-1">
                              <span className="tableHeaderLabel">
                                Parameter
                              </span>
                              <select
                                value={compheader.ch_power_unit || ch_pu}
                                disabled={
                                  !(addLine.length > 0 || editLine.length > 0)
                                }
                                className="headerSelect"
                                onChange={(e) => {
                                  handleHeaderChange(e);
                                  ch_pu = e.target.value;
                                }}
                                name="ch_power_unit"
                                id="ch_power_unit"
                              >
                                <option value="">Power</option>
                                {Power.map((fromunit, index) => (
                                  <option value={fromunit.value}>
                                    {fromunit}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </span>
                          <input
                            type="number"
                            name="cl_power"
                            id={`cl_power${index}`}
                            maxlength="4"
                            value={addLine[index].cl_power}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = validFourDecimals(
                                e.target.value,
                                "Power"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_power: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_power && (
                            <div className="text-danger">
                              {errorLine.cl_power}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Motor Efficiency %</span>
                          <input
                            type="text"
                            name="cl_efficiency"
                            id={`cl_efficiency${index}`}
                            value={addLine[index].cl_efficiency}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = percent100(
                                e.target.value,
                                "Enter Value between 0 - 100"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_efficiency: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_efficiency && (
                            <div className="text-danger">
                              {errorLine.cl_efficiency}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Motor Voltage Volt</span>
                          <input
                            type="text"
                            name="cl_voltage"
                            id={`cl_voltage${index}`}
                            value={addLine[index].cl_voltage}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = validFourDecimals(
                                e.target.value,
                                "Voltage"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_voltage: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_voltage && (
                            <div className="text-danger">
                              {errorLine.cl_voltage}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Motor Starter Type</span>
                          <select
                            className="bodySelect"
                            required
                            name="cl_starter_type"
                            id={`cl_starter_type${index}`}
                            value={addLine[index].cl_starter_type}
                            onChange={(e) => handleChange(e, index, "add")}
                            // onInput={(e) => {
                            //   let error = isSelectEmpty(
                            //     e.target.value,
                            //     "Select a Value"
                            //   );
                            //   setErrorLine((prevState) => ({
                            //     ...prevState,
                            //     cl_starter_type: error,
                            //   }));
                            // }}
                          >
                            <option value="">Starter Type</option>
                            <option value="STAR DELTA">STAR DELTA</option>
                            <option value="DELTA">DELTA</option>
                            <option value="VFD">VFD</option>
                            <option value="Centrifugal">Centrifugal</option>
                          </select>
                          {errorLine.cl_starter_type && (
                            <div className="text-danger">
                              {errorLine.cl_starter_type}
                            </div>
                          )}
                        </div>

                        <div className="table-cell">
                          <span>Comp. Vol. Efficiency %</span>
                          <input
                            type="text"
                            name="cl_comp_vol_efficiency"
                            value={addLine[index].cl_comp_vol_efficiency}
                            id={`cl_comp_vol_efficiency${index}`}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = percent100(
                                e.target.value,
                                "Enter Value between 0 - 100"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_comp_vol_efficiency: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_comp_vol_efficiency && (
                            <div className="text-danger">
                              {errorLine.cl_comp_vol_efficiency}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>QTY</span>
                          <input
                            type="text"
                            name="cl_qty"
                            id={`cl_qty${index}`}
                            value={addLine[index].cl_qty}
                            readOnly
                            // onChange={(e) => handleChange(e, index, "add")}
                            required
                          />
                        </div>
                        <div className="table-cell">
                          <span>Running hrs Per Day</span>
                          <input
                            type="text"
                            name="cl_running_hrs_per_day"
                            id={`cl_running_hrs_per_day${index}`}
                            value={addLine[index].cl_running_hrs_per_day}
                            onChange={(e) => {
                              
                              handleChange(e, index, "add");
                              if (e.target.value == 0) {
                                let temp = [...addLine];
                                temp[index].cl_load_factor = 0;
                                setAddLine([...temp]);
                              }
                            }}
                            onInput={(e) => {
                              let error = number24(
                                e.target.value,
                                "Enter Value between 0 - 24"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_running_hrs_per_day: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_running_hrs_per_day && (
                            <div className="text-danger">
                              {errorLine.cl_running_hrs_per_day}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Unloading Power %</span>
                          <input
                            type="text"
                            name="cl_unload"
                            id={`cl_unload${index}`}
                            value={addLine[index].cl_unload}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = percent100(
                                e.target.value,
                                "Enter Value between 0 - 100"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_unload: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_unload && (
                            <div className="text-danger">
                              {errorLine.cl_unload}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Load Factor %</span>
                          <input
                            type="text"
                            name="cl_load_factor"
                            disabled={
                              addLine[index].cl_running_hrs_per_day == 0 ||
                              addLine[index].cl_running_hrs_per_day === ""
                            }
                            value={addLine[index].cl_load_factor}
                            id={`cl_load_factor${index}`}
                            onChange={(e) => handleChange(e, index, "add")}
                            onInput={(e) => {
                              let error = percent100(
                                e.target.value,
                                "Enter Value between 0 - 100"
                              );
                              setErrorLine((prevState) => ({
                                ...prevState,
                                cl_load_factor: error,
                              }));
                            }}
                            required
                          />
                          {errorLine.cl_load_factor && (
                            <div className="text-danger">
                              {errorLine.cl_load_factor}
                            </div>
                          )}
                        </div>
                        <div className="table-cell">
                          <span>Energy Consumption Per Day kWH/day</span>
                          <input
                            type="number"
                            name="cl_energy_consumption_per_day"
                            id={`cl_energy_consumption_per_day${index}`}
                            //value=""
                            // onFocus={(e) => calculate(e, index)}
                            //defaultValue=" "
                            value={decimalFloat(
                              ((+addLine[0].cl_power * PowerDefaultRate) /
                                (+addLine[0].cl_efficiency / 100)) *
                                +addLine[0].cl_running_hrs_per_day *
                                (+addLine[0].cl_load_factor / 100) +
                                ((+addLine[0].cl_power * PowerDefaultRate) /
                                  (+addLine[0].cl_efficiency / 100)) *
                                  (+addLine[0].cl_unload / 100) *
                                  +addLine[0].cl_running_hrs_per_day *
                                  (1 - +addLine[0].cl_load_factor / 100) *
                                  +addLine[0].cl_qty
                            )}
                            onChange={(e) => handleChange(e, index, "add")}
                          />
                        </div>
                        <div className="table-cell">
                          <span>Avg iCFM</span>
                          <input
                            type="number"
                            name="cl_avg_icfm"
                            id={`cl_avg_icfm${index}`}
                            //value={addLine.cl_avg_icfm}
                            value={decimalFloat(
                              Number(
                                addLine[index].cl_capacity_flow *
                                  CapDefaultRate *
                                  (addLine[index].cl_comp_vol_efficiency /
                                    100) *
                                  addLine[index].cl_qty *
                                  (addLine[index].cl_load_factor / 100)
                              )
                            )}
                            onChange={(e) => handleChange(e, index, "add")}
                            readOnly
                          />
                        </div>
                        <div className="table-cell cell-action cellActionCustom"></div>
                      </div>
                      {validateMessage && (
                        <div className="text-danger">{validateMessage}</div>
                      )}
                    </>
                  );
                  // }); //complinedata end
                })}
            </div>
          </div>
        </Form>
      </div>
      <div className="justify-content-start" id="addcompressorbutton">
        <Row className="gx-2 mx-0 mt-2">
          {addLine.length || editAction ? (
            <Col className="d-flex gap-2">
              <Button
                hidden={props.IsDisabled === false ? true : false}
                type="submit"
                bsPrefix="btn-add"
                onClick={() => {
                  if (editLine.length > 0) {
                    editCompressor();
                  } else {
                    addCompressor();
                  }
                }}
                // onClick={(e) => handleSubmit(e, index)}
              >
                {"Save"}
              </Button>
              <Button
                hidden={props.IsDisabled === false ? true : false}
                variant="outline-primary"
                //  className="ml-2"
                // onClick={(e) => props.closeModal()}
                onClick={() => {
                  if (editLine.length > 0) {
                    setEditLine([]);
                    setEditAction(false);
                    props.reloadModal();
                  } else {
                    setAddLine([]);
                    setEditAction(false);
                  }
                  setScrollFlag(false);
                  setcompheader((prevState) => ({
                    // setCompHouse((prevState) => ({
                    ...prevState,
                    ch_energy_consumption_per_day: Number(compHouse[active]?.ch_energy_consumption_per_day),
                    ch_avg_icfm: Number(compHouse[active]?.ch_avg_icfm),

                  }));
                }}
              >
                Cancel
              </Button>
            </Col>
          ) : (
            <Col md={2}>
              <Button
                hidden={props.IsDisabled === false ? true : false}
                variant="outline-primary"
                style={{ lineHeight: "1" }}
                bsPrefix="btn-primary action-btn mt-2"
                onClick={() => {
                  handleAddClick();
                }}
              >
                <BsPlusLg
                  size={32}
                  className="p-2 ms-2"
                  alignmentBaseline="left"
                />
                <span className="btn-text">Add Compressor</span>
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.actualValue}
          //paramkey1={Defaultmake}
          // paramvalue1={true}
        />
      </RenderIf>
      {/* {showAppForm && (
        <AddAirTreatment
          active={active}
          id={id}
          enquiryID={enquiryID}
          compHouse={compHouse}
          airTreatment={airTreatment}
          setAirTreatment={setAirTreatment}
          compHeader={compheader}
          compLine={compLine}
        />
      )} */}
    </>
  );
};

export default ADC;
