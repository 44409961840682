import {
  Breadcrumb,
  Button,
  Form,
  Alert,
  Row,
  Col,
  NavLink,
} from "react-bootstrap";
import { BsPlusLg, BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import Edit from "../../icons/EditIcon.svg";
import { getAllUserMaster } from "../../Services/user-service";
import { useEffect, useMemo, useRef, useState } from "react";
import GolbalSearch from "../../Components/utility/GolbalSearch";
import { getAllLocationMaster } from "../../Services/location-service";
import { getActiveRoles } from "../../Services/role-service";
import exportFromJSON from "export-from-json";
import { useHistory } from "react-router-dom";
import {
  DeleteUser,
  deletefromuserlocation,
} from "../../Services/user-service";
import { getAllActiveDealers } from "../../Services/dealer-service";
import Scrollbar from "smooth-scrollbar";
import Export from "../../icons/Export.svg";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

import {
  FilterChange,
  formatDateDD_MM_YYYY,
  resetAllFilter,
  resetFilter,
} from "../../functions/functions";
import AllSolutionApprovalHoc from "./AllSolutionApprovalHoc";
import Customtable from "../../Components/layout/customTable";
import escapeExcelData from "../../utils/customValidation";
import { getDataMaskStatus } from "../../Services/user-service";
import { store } from "../../redux/store";

import { ExcelDownloadLog } from "../../Services/excel-download-service";
const AllSolutionApproval = (props) => {
  const userRole = store.getState().global?.userDetails?.um_role;
  const [dataMask, setDataMask] = useState(false);

  console.log(props);
  console.log(props.tableData);

  //BOC Vikas K on 13/09/2022
  let filteredResult1 = props.tableData;
  console.log(filteredResult1);
  let flag = false;
  //EOC Vikas K on 13/09/2022

  const gridRef = useRef();
  const [users, setUsers] = useState(null);
  const [reload, setReload] = useState(false);
  const [globalText, setGlobalText] = useState("");
  const [userObj, setUserObj] = useState({
    // um_status: "",
    // um_id: "",
    // um_name: "",
    // um_type: "",
    // um_role: "",
    // um_location: "",
    // um_location_: "",
    // um_company: "",
    // um_company_: "",
    // um_approval: "",
    // um_role_: "",
    // um_mobileno: "",
    // um_email: "",
    // locationlist: "",
    // locationname: "",

    //BOC Vikas K on 12/09/2022
    ed_enqstatus: "",
    ed_enquirynumber: "",
    ed_insertedon: "",
    username: "",
    dm_dealercode: "",
    dm_dealercompanyname: "",
    ed_custname: "",
    ed_country: "",
    ed_telno: "",
    ed_sector: "",
    ed_pendingwith: "",
    ed_roi: "",
    //EOC Vikas K on 12/09/2022
  });
  const [filteredResult, setFilteredResult] = useState([]);
  const [rolesCombo, setRolesCombo] = useState("");
  const [dealersCombo, setDealersCombo] = useState("");
  const [locationsCombo, setLocationsCombo] = useState("");
  const [filterDisplay, setFilterDisplay] = useState("filter-input d-none");
  const [filteredCol, setFilteredCol] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [rowData, setRowData] = useState({});
  const history = useHistory();
  const getRoles = async () => {
    const result = await getActiveRoles();
    setRolesCombo(result);
  };
  const getLocations = async () => {
    const result = await getAllLocationMaster();
    setLocationsCombo(result);
  };
  const getDealers = async () => {
    let result = await getAllActiveDealers();
    setDealersCombo(result);
  };
  const fetchUserMaster = async () => {
    // const result = await getAllUserMaster();
    // setUsers(result);
    console.log("tabledata", props.tableData);
    console.log(filteredResult1);
    setFilteredResult(filteredResult1);
    //filteredResult = props.tableData;
  };

  useEffect(() => {
    fetchDataMaskingSts();
    // getRoles();
    // getLocations();
    // getDealers();
    fetchUserMaster();
    Scrollbar.init(document.querySelector(".app-content"));
  }, []);

  // useEffect(() => {
  //   users && globalText && handleGlobalSearch(globalText);
  // }, [users]);

  // useEffect(() => {
  //   if (users != null) filterData();
  // }, [users]);

  // const handleEdit = (row) => {
  //   setAction("Edit");
  //   setRowData(row);
  //   setShowForm(true);
  // };
  // const handleDelete = (row) => {
  //   let result = DeleteUser(row);
  //   result
  //     .then((resp) => {
  //       if (resp.status) {
  //         alert(resp.data.message);
  //         console.log("result " + row.um_id);
  //         deletefromuserlocation(row.um_id);
  //       } else {
  //         alert(resp.data.message);
  //       }
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.log("Unable to process request");
  //     });
  // };
  // const handleAdd = () => {
  //   setAction("Add");
  //   setShowForm(true);
  // };
  const fetchDataMaskingSts = async () => {
    const result = await getDataMaskStatus(userRole);
    console.log("getDataMaskStatus", result.result, userRole);
    setDataMask(result.result);
  };
  const handleExport = () => {
    let gridData = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      gridData.push(node.data);
    });
    const data = [];
    for (let n = 0; n < gridData.length; n++) {
      data.push({
        Status: gridData[n].ed_enqstatus,
        "Enquiry No.": gridData[n].ed_enquirynumber,
        "Enquiry Generation Date": gridData[n].ed_insertedon,
        "Enquiry Created By": gridData[n].username,
        "Dealer Code": escapeExcelData(gridData[n].dm_dealercode),
        "Dealer Name": escapeExcelData(gridData[n].dm_dealercompanyname),
        "Customer Name": escapeExcelData(gridData[n].ed_custname),
        "Customer Country": escapeExcelData(gridData[n].ed_country),
        "Customer Telephone No.": escapeExcelData(
          gridData[n].ed_telno,
          dataMask
        ),
        "Customer Business Sector": escapeExcelData(gridData[n].ed_sector),
        "Enquiry Pending With": escapeExcelData(gridData[n].ed_pendingwith),
        ROI: escapeExcelData(gridData[n].ed_roi),
      });
    }
    const fileName = "AllSolutionForApproval";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  const handleGlobalSearch = (text) => {
    //BOC Vikas K on 13/09/2022
    flag = true;
    //EOC Vikas K on 13/09/2022
    let searchText = text.toLowerCase();

    setGlobalText(searchText);
    console.log(searchText);
    if (searchText) {
      //BOC Vikas K on 13/09/2022
      let result = props.tableData.filter((item) => {
        //EOC Vikas K on 13/09/2022
        console.log(item, searchText);
        console.log(item.ed_enqstatus?.toLowerCase().indexOf(searchText));
        return (
          item.ed_enqstatus?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_enquirynumber?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_insertedon?.toLowerCase().indexOf(searchText) >= 0 ||
          item.username?.toLowerCase().indexOf(searchText) >= 0 ||
          item.dm_dealercode?.toLowerCase().indexOf(searchText) >= 0 ||
          item.dm_dealercompanyname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_custname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_country?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_telno?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_sector?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_pendingwith?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_roi?.toLowerCase().indexOf(searchText) >= 0
        );
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(props.tableData);
    }
  };

  const resetForm = () => {
    document.filterForm.reset();
    setUserObj({
      // um_status: "",
      // um_id: "",
      // um_name: "",
      // um_company: "",
      // um_company_: "",
      // um_role: "",
      // um_role_: "",
      // um_type: "",
      // um_location: "",
      // um_location_: "",
      // um_approval: "",
      // locationlist: "",
      // locationname: "",

      //BOC Vikas K on 12/09/2022
      ed_enqstatus: "",
      ed_enquirynumber: "",
      ed_insertedon: "",
      username: "",
      dm_dealercode: "",
      dm_dealercompanyname: "",
      ed_custname: "",
      ed_country: "",
      ed_telno: "",
      ed_sector: "",
      ed_pendingwith: "",
      ed_roi: "",
      //EOC Vikas K on 12/09/2022
    });

    setFilteredResult(users);
  };

  const headerName = (field) => {
    var name;
    switch (field) {
      // case "um_status":
      //   name = "Status";
      //   break;
      // case "um_id":
      //   name = "User Id";
      //   break;
      // case "um_name":
      //   name = "User Name";
      //   break;
      // case "um_type":
      //   name = "User Type";
      //   break;
      // case "um_role":
      //   name = "User Role";
      //   break;
      // case "locationname":
      //   name = "Location";
      //   break;
      // case "um_company":
      //   name = "Company Name";
      //   break;
      // case "um_approval":
      //   name = "Approval Workflow Applicable";
      //   break;

      //BOC Vikas K on 12/09/2022
      case "ed_enqstatus":
        name = "Status";
        break;
      case "ed_enquirynumber":
        name = "Enquiry No.";
        break;
      case "ed_insertedon":
        name = "Enquiry Generation Date";
        break;
      case "username":
        name = "Enquiry Created By";
        break;
      case "dm_dealercode":
        name = "Dealer Code";
        break;
      case "dm_dealercompanyname":
        name = "Dealer Name";
        break;
      case "ed_custname":
        name = "Customer Name";
        break;
      // case "Location Type":
      //   name = "ed_custlocation";
      //   break;
      case "ed_country":
        name = "Country";
        break;
      case "ed_telno":
        name = "Customer Telephone No.";
        break;
      case "ed_sector":
        name = "Customer Business Sector";
        break;
      case "ed_pendingwith":
        name = "Enquiry Pending With";
        break;
      case "ed_roi":
        name = "ROI";
        break;

      // case "System Generated Quotation":
      //   name = "ed_sector";
      //   break;
      //EOC Vikas K on 12/09/2022

      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      // case "Status":
      //   name = "um_status";
      //   break;
      // case "User Id":
      //   name = "um_id";
      //   break;
      // case "User Name":
      //   name = "um_name";
      //   break;
      // case "User Type":
      //   name = "um_type";
      //   break;
      // case "User Role":
      //   name = "um_role";
      //   break;
      // case "Location":
      //   name = "locationname";
      //   break;
      // case "Company Name":
      //   name = "um_company";
      //   break;
      // case "Approval Workflow Applicable":
      //   name = "um_approval";
      //   break;

      //BOC Vikas K on 12/09/2022
      case "Status":
        name = "ed_enqstatus";
        break;
      case "Enquiry No.":
        name = "ed_enquirynumber";
        break;
      case "Enquiry Generation Date":
        name = "ed_insertedon";
        break;
      case "Enquiry Created By":
        name = "username";
        break;
      case "Dealer Code":
        name = "dm_dealercode";
        break;
      case "Dealer Name":
        name = "dm_dealercompanyname";
        break;
      case "Customer Name":
        name = "ed_custname";
        break;
      // case "Location Type":
      //   name = "ed_custlocation";
      //   break;
      case "Country":
        name = "ed_country";
        break;
      case "Customer Telephone No.":
        name = "ed_telno";
        break;
      case "Customer Business Sector":
        name = "ed_sector";
        break;
      case "Enquiry Pending With":
        name = "ed_pendingwith";
        break;
      case "ROI":
        name = "ed_roi";
        break;
      //EOC Vikas K on 12/09/2022
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const actionRenderer = (params) => {
    return (
      <div className="cell-action">
        {/* <BiEdit
          size={32}
          className="p-1"
          color={"blue"}
          onClick={() =>
            history.push({
              pathname: "/newenquiry",
              data: params.data,
            })
          }
        /> */}
        <img
          src={Edit}
          alt="search"
          className="p-1"
          onClick={() =>
            history.push({
              pathname: "/newenquiry",
              data: params.data,
            })
          }
        />
      </div>
    );
  };

  // const statusRenderer = (params) => {
  //   let status_color = params.value ? "#3CB23D" : "#A7AEC6";
  //   return (
  //     <>
  //       <div
  //         className="status-color"
  //         style={{ backgroundColor: status_color }}
  //       ></div>
  //       <span
  //         style={{
  //           color: status_color,
  //           fontWeight: "600",
  //         }}
  //         className="ms-3"
  //       >
  //         {params.value ? "Active" : "Inactive"}
  //       </span>
  //     </>
  //   );
  // };

  const statusRenderer = (params) => {
    console.log("Params ", params);
    // let status_color = params.value ? "#D262E6" : params.value ? "#4285F0" : params.value ? "#25C36E" : params.value ? "#FF6565" : "";
    let status_color;
    if (params.value == "Pending with Regional Manager") {
      status_color = "#D262E6";
    } else if (params.value == "Pending to Approve") {
      status_color = "#4285F0";
    } else if (params.value == "Pending for Proposal Status") {
      status_color = "#BE596C";
    } else if (params.value == "Approved by Manager") {
      status_color = "#25C36E";
    } else if (
      params.value == "Rejected by Regional Engineer" ||
      params.value == "Rejected by Manager" ||
      params.value == "Rejected by HO Engineer"
    ) {
      status_color = "#F2BF60";
    } else if (params.value == "Pending with Regional Engineer") {
      status_color = "#D262E6";
    } else if (params.value == "Pending with Regional In-charge") {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Regional Incharge") {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Manager") {
      status_color = "#06A4A5";
    } else if (params.value == "Pending with HO Engineer") {
      status_color = "#FF6565";
    } else if (params.value == "In-Process") {
      status_color = "#0693E3";
    } else if (params.value == "Created") {
      status_color = "#7ABE35";
    } else if (params.value == "Closed") {
      status_color = "#A7A7AB";
    } else {
      status_color = "";
    }
    return (
      <>
        <div
          className="status-color"
          style={{ backgroundColor: status_color }}
        ></div>
        <span
          style={{
            color: status_color,
            fontWeight: "600",
          }}
          className="ms-3"
        >
          {/* {params.value ? "Pending with Regional Manager" : params.value ? "Pending to Approve" : params.value ? "Approved" : params.value ? "Rejected" : "" } */}
          {params.value ? params.value : ""}
        </span>
      </>
    );
  };

  const contains = (target, lookingFor) => {
    return target && target.indexOf(lookingFor) >= 0;
  };
  const [columnDefs] = useState([
    // {
    //   field: "um_status",
    //   cellRenderer: statusRenderer,
    //   cellStyle: { paddingLeft: 0, display: "flex", alignItems: "center" },
    //   headerName: "Status",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     filterOptions: ["equals"],
    //     suppressAndOrCondition: true,
    //     textMatcher: ({ value, filterText }) => {
    //       var filterTextLowerCase = filterText ? filterText.toLowerCase() : "";
    //       var valueLowerCase = value.toString().toLowerCase();
    //       var aliases = {
    //         active: "true",
    //         inactive: "false",
    //       };
    //       return contains(valueLowerCase, aliases[filterTextLowerCase]);
    //     },
    //   },
    //   width: 50,
    // },
    {
      field: "ed_enqstatus",
      cellRenderer: statusRenderer,
      cellStyle: { paddingLeft: 0, display: "flex", alignItems: "center" },
      resizable: true,
      headerName: "Status",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enqstatus",
      minWidth: 170,
    },
    {
      field: "ed_enquirynumber",
      resizable: true,
      cellRenderer: (params) => {
        let cellValue = (
          <span>
            <NavLink
              className="uploadNavlink"
              style={{ color: "#264AE4" }}
              onClick={() => {
                history.push({
                  pathname: "/newenquiry",
                  data: params.data,
                });
                let bodyData = {
                  Session: "enquiry",
                  Records: [{ id: params.data.ed_enquiryid }],
                };
                let result = ExcelDownloadLog(bodyData);
              }}
            >
              {params.value}
            </NavLink>
          </span>
        );
        return cellValue;
      },
      headerName: "Enquiry No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enquirynumber",
      minWidth: 186,
    },
    {
      field: "ed_insertedon",
      resizable: true,
      cellRenderer: (params) => {
        return params.value && (formatDateDD_MM_YYYY(params.value) || "");
      },
      headerName: "Enquiry Generation Date",
      filter: "agDateColumnFilter",
      tooltipField: "ed_insertedon",
      minWidth: 170,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // const dateCell = new Date(cellValue);
          // if (dateCell < filterLocalDateAtMidnight) {
          //   return -1;
          // } else if (dateCell > filterLocalDateAtMidnight) {
          //   return 1;
          // }
          // return 0;
          const dateAsString = formatDateDD_MM_YYYY(cellValue);
          console.log("dateAsString : ", dateAsString);
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split("-");
          const year = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[0]);
          const dateCell = new Date(year, month, day);
          console.log("dateCell", dateCell);
          if (dateCell < filterLocalDateAtMidnight) {
            console.log("dateCell < filterLocalDateAtMidnight");
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            console.log("dateCell > filterLocalDateAtMidnight");
            return 1;
          }
          console.log("Returning 0");
          return 0;
        },
      },
    },
    {
      field: "username",
      resizable: true,
      headerName: "Enquiry Created By",
      filter: "agTextColumnFilter",
      tooltipField: "username",
      minWidth: 170,
    },
    {
      field: "dm_dealercode",
      resizable: true,
      headerName: "Dealer Code",
      filter: "agTextColumnFilter",
      tooltipField: "dm_dealercode",
      minWidth: 170,
    },
    {
      field: "dm_dealercompanyname",
      resizable: true,
      headerName: "Dealer Name",
      filter: "agTextColumnFilter",
      tooltipField: "dm_dealercompanyname",
      minWidth: 170,
    },
    {
      field: "ed_custname",
      resizable: true,
      headerName: "Customer Name",
      filter: "agTextColumnFilter",
      tooltipField: "ed_custname",
      minWidth: 170,
    },
    {
      field: "ed_country",
      resizable: true,
      headerName: "Customer Country",
      filter: "agTextColumnFilter",
      tooltipField: "ed_country",
      minWidth: 170,
    },
    {
      field: "ed_telno",
      resizable: true,
      headerName: "Customer Telephone No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_telno",
      minWidth: 170,
    },
    {
      field: "ed_sector",
      resizable: true,
      headerName: "Customer Business Sector",
      filter: "agTextColumnFilter",
      tooltipField: "ed_sector",
      minWidth: 170,
    },
    {
      field: "ed_pendingwith",
      resizable: true,
      headerName: "Enquiry Pending With",
      filter: "agTextColumnFilter",
      tooltipField: "ed_pendingwith",
      minWidth: 170,
    },
    {
      field: "ed_roi",
      resizable: true,
      headerName: "ROI",
      filter: "agTextColumnFilter",
      tooltipField: "ed_roi",
      minWidth: 170,
    },
    // EOC by Vikas K on 12/09/2022
    // {
    //   field: "um_approval",
    //   resizable: true,
    //   headerName: "Customer Code",
    //   filter: "agTextColumnFilter",
    //   tooltipField: "um_approval",
    //   minWidth: 170,
    //   cellRenderer: (params) => {
    //     return params.value ? "Yes" : "No";
    //   },
    // },

    { field: "", cellRenderer: actionRenderer, pinned: "right", width: 90 },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  // BOC Vikas K on 13/09/2022
  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredResult(props.tableData);
    }, 100);
  }, [filteredResult1]);
  // EOC Vikas K on 13/09/2022

  return (
    <>
      <div className="bread-crumb d-none d-md-block d-lg-block">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/#" active>
            All Solutions for Approval
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="place-center">
        <div className="d-md-none d-lg-none ">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/#" active>
              All Solutions for Approval
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="app-content">
        {/* <div className="action-area"> */}
        <Row>
          <Col>
            <div className="global-search-container">
              <GolbalSearch filterFunc={handleGlobalSearch} />
              <img
                src={Export}
                alt="export"
                className="ms-2 p-1"
                onClick={handleExport}
              />
            </div>
          </Col>
        </Row>
        {/* </div> */}
        <div className="d-none mt-3 d-flex" id="filterDisplay">
          {filteredCol &&
            filteredCol.map((item, index) => {
              return (
                <div key={`FilterCol${index}`} className="filter-col pr-0">
                  <span className="ps-2 btn-text">{item}</span>
                  <div onClick={() => resetFilter(gridRef, item, feildName)}>
                    <BsX size={32} />
                  </div>
                </div>
              );
            })}
          <div>
            <Button
              variant="outline-primary"
              className="ms-2 lh-1"
              onClick={() => {
                resetAllFilter(gridRef);
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
        <div id="myGrid" className="d-none d-md-block d-lg-block">
          {props.tableData.length > 0 && (
            <div className="ag-theme-material" style={{ width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                //rowData={props.tableData}
                rowData={filteredResult}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                headerHeight={56}
                rowHeight={56}
                pagination={true}
                paginationPageSize={10}
                onFilterChanged={(params) => {
                  FilterChange(params, setFilteredCol, headerName);
                }}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
              ></AgGridReact>
            </div>
          )}
          {props.tableData.length <= 0 && (
            <div
              className="ag-theme-material text-center"
              style={{ width: "100%" }}
            >
              <br />
              No Records Found
            </div>
          )}
        </div>
        <div className="d-md-none d-lg-none table-container1">
          <Customtable
            header={[
              {
                id: "ed_enqstatus",
                name: "Status",
                class: "",
              },
              {
                id: "ed_enquirynumber",
                name: "Enquiry No.",
                class: "",
              },
              {
                id: "ed_insertedon",
                name: "Enquiry Generation Date",
                type: "date",
                class: "",
              },
              {
                id: "username",
                name: "Enquiry Created By",
                type: "date",
                class: "",
              },
              {
                id: "dm_dealercode",
                name: "Dealer Code",
                class: "",
              },
              {
                id: "dm_dealercompanyname",
                name: "Dealer Name",
                class: "",
              },
              {
                id: "ed_custname",
                name: "Customer Name",
                class: "",
              },
              {
                id: "ed_country",
                name: "Customer Country",
                class: "",
              },
              {
                id: "ed_hoaddress",
                name: "HO Address",
                class: "",
              },
              {
                id: "ed_telno",
                name: "Tel No",
                class: "",
              },
              {
                id: "ed_webaddress",
                name: "Website Address",
                class: "",
              },
              {
                id: "ed_roi",
                name: "ROI",
                class: "",
              },
            ]}
            highlightKey="ed_enqstatus"
            enableEdit={true}
            enableDelete={false}
            editFunction={(data) =>
              history.push({
                pathname: "/newenquiry",
                data: data,
              })
            }
            data={filteredResult}
          />
        </div>
      </div>
    </>
  );
};

export default AllSolutionApprovalHoc(AllSolutionApproval);
