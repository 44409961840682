import {
  Breadcrumb,
  Button,
  Form,
  NavLink,
  Col,
  Row,
  Modal,
  Alert,
} from "react-bootstrap";
//import Dropzone from "react-dropzone-uploader";
import Dropzone from "react-dropzone";
import Drop from "../../icons/Drop.svg";
import {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  EnrollNewFiles,
  getFilesByID,
} from "../../Services/file-upload-service";
import Pic_Preview from "../../Components/Masters/ProductMaster/Pic_Preview";
import uploadIcon from "../../styles/images/upload-icon.svg";
import deleteIcon from "../../styles/images/delete.svg";
import greenTick from "../../styles/images/green-tick.svg";
import WarningModal from "../Modals/warningModal";
import $ from "jquery";
import uploadPresigned, { downloadFileDirect } from "../../utils/presignUpload";

import ReactDOM from "react-dom";
import {
  getLinkedNetworkDetails,
  insertNetwork,
} from "../../Services/network-service";
import NewSolution from "./NewSolution";
import CommonModal from "../Modals/commonModal";
import { isNumberWithDecimal } from "../../functions/validations";
import {
  alphaNumeric50,
  alphaNumericWithSpace,
  validTwoDecimals,
} from "../../utils/customValidation";
import { useSelector } from "react-redux";
const FileNetworkDetails = forwardRef((props, ref) => {
  console.log("Filenetworkprops", props);

  // let enquiryID = props.enquiryID;
  //enquiryID = 50; //comment this later
  // const maxSize = 20971520;
  const [datapic, setdatapic] = useState({});
  const [currentTableData, setCurrentTableData] = useState([]);
  const [currentTableDataError, setCurrentTableDataError] = useState([]);
  const [count, setcount] = useState(0);
  const [docPercent1, setDocPercent1] = useState(0);
  const [docPercent2, setDocPercent2] = useState(0);
  const [docPercent3, setDocPercent3] = useState(0);
  const [docPercent4, setDocPercent4] = useState(0);
  const [docPercent5, setDocPercent5] = useState(0);
  const [showForm1, setShowForm1] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [showWarnMessage, setShowWarnMessage] = useState("");
  const [show, setShow] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [MaxSizeMessageSCL, setMaxSizeMessageSCL] = useState("");
  const [MaxSizeMessageHOBO, setMaxSizeMessageHOBO] = useState("");
  const [MaxSizeMessageADD1, setMaxSizeMessageADD1] = useState("");
  const [MaxSizeMessageADD2, setMaxSizeMessageADD2] = useState("");
  const [MaxSizeMessageADD3, setMaxSizeMessageADD3] = useState("");
  const [filetypeMessage1, setFiletypeMessage1] = useState("");
  const [filetypeMessage2, setFiletypeMessage2] = useState("");
  const [filetypeMessage3, setFiletypeMessage3] = useState("");
  const [showmoreless, setshowmoreless] = useState([]);

  const sidebarFlag = useSelector((state) => state.global.openSideBar);

  const axios = require("axios").default;
  const API_ENDPOINT = process.env.REACT_APP_PRESIGNED_ENDPOINT;

  // const API_ENDPOINT =
  //   "https://9zc9mapgv5.execute-api.us-east-1.amazonaws.com/default/getPresignedFileURL";

  const [fileUploadObj, setfileUploadObj] = useState({
    fu_schematiclayout: "",
    fu_hoboproject: "",
    fu_additional3: "",
    fu_additional2: "",
    fu_additional1: "",
    fu_schematiclayoutfilename: "",
    fu_hobofilename: "",
    fu_attch1filename: "",
    fu_attch2filename: "",
    fu_attch3filename: "",
    fu_enquiryid: "",
    fu_receivercapacity: "",
  });
  let enquirykey = props.enquiryid
    ? props.enquiryid
    : localStorage.getItem("enqkey");
  useImperativeHandle(ref, () => ({
    runValidate2: async () => {
      console.log("valid 3");
      let result = await insertNetworkDetails();
      return result;
    },
    TempRunValidate2: () => {
      insertNetworkDetailsTemp();
    },
    tabCheck3: async () => {
      let result1;
      let result = await getFilesByID(enquirykey);
      if (result.data.length > 0) {
        result1 = await bodyValidate(result.data[0]);
      } else {
        result1 = await bodyValidate(fileUploadObj);
      }
      return result1;
    },
  }));

  // const enquirykey = localStorage.getItem("enqkey");
  // console.log(enquirykey);

  const fetchFilesbyId = async () => {
    const result = await getFilesByID(enquirykey);
    console.log(result.data[0]);
    if (result.data.length > 0) {
      setfileUploadObj(result.data[0]);
    }
  };

  useEffect(() => {
    console.log("props.page3key123", props.page3key);
    //props.page3key;
    if (props.page3key === 3) {
      getNetworkDetails();
      console.log(props.page3key + "filenetworkhh");
      fetchFilesbyId();
    }
    //}
  }, [props.page3key]);

  // const handleChangeStatus1 = async ({ meta, remove }, status, files) => {
  //   console.log(status, meta);
  //   if (status === "rejected_file_type") {
  //     alert("FileType Not Supported");
  //   }

  //   if (status === "error_file_size") {
  //     alert("File too large");
  //   }

  //   if (status == "done") {
  //     //Get request preigned url

  //     const response = await axios({
  //       method: "GET",
  //       url: API_ENDPOINT,
  //     });

  //     console.log("Response: ", response);

  //     //put request
  //     const f = files[0];
  //     const result = fetch(response.data.uploadUrl, {
  //       method: "PUT",
  //       body: f["file"],
  //     });
  //     console.log("Result: ", result);
  //     let var2 = response.data.uploadUrl;
  //     let s3Location = var2.substring(0, var2.indexOf("?"));
  //     console.log(s3Location);

  //     alert(s3Location);
  //     window.filelocation1 = s3Location;
  //     fileUploadObj.fu_schematiclayout = s3Location;
  //     fileUploadObj.fu_schematiclayoutfilename = f.file.name;
  //     window.file1name = f.file.name;
  //     //handleattachpic(window.filelocation1)
  //     // ProductObj.pm_techdocname = meta.name;
  //   } else if (status == "removed") {
  //     var todel = window.filelocation1;
  //     console.log(todel);
  //     const result = fetch(todel, {
  //       method: "DELETE",
  //       body: todel,
  //     });
  //     fileUploadObj.fu_schematiclayout = "";
  //     fileUploadObj.fu_schematiclayoutfilename = "";
  //   }
  // };

  const handleChangeStatus1 = async (files, value1, value2) => {
    console.log(files, files[0]);
    if (files[0].size > 20971520) {
      if (value1 === "fu_schematiclayout") {
        setMaxSizeMessageSCL("File Size too Large, Expected File Size 20 MB.");
      } else if (value1 === "fu_additional1") {
        setMaxSizeMessageADD1("File Size too Large, Expected File Size 20 MB.");
      } else if (value1 === "fu_additional2") {
        setMaxSizeMessageADD2("File Size too Large, Expected File Size 20 MB.");
      } else if (value1 === "fu_hoboproject") {
        setMaxSizeMessageHOBO("File Size too Large, Expected File Size 20 MB.");
      } else if (value1 === "fu_additional3") {
        setMaxSizeMessageADD3("File Size too Large, Expected File Size 20 MB.");
      }
    } else {
      if (value1 === "fu_schematiclayout") {
        setMaxSizeMessageSCL("");
      } else if (value1 === "fu_additional1") {
        setMaxSizeMessageADD1("");
      } else if (value1 === "fu_additional2") {
        setMaxSizeMessageADD2("");
      } else if (value1 === "fu_hoboproject") {
        setMaxSizeMessageHOBO("");
      } else if (value1 === "fu_additional3") {
        setMaxSizeMessageADD3("");
      }

      const uploadFunction = (value) => {
        if (value1 === "fu_schematiclayout") {
          setDocPercent1(value);
          setValidateMessage("");
        } else if (value1 === "fu_additional1") {
          setDocPercent2(value);
        } else if (value1 === "fu_additional2") {
          setDocPercent3(value);
        } else if (value1 === "fu_hoboproject") {
          setDocPercent4(value);
        } else if (value1 === "fu_additional3") {
          setDocPercent5(value);
        }
      };
      console.log(value1);
      console.log(value2);
      let flagfortype = 0;
      let count = 0;
      console.log(files[0].name);
      const index = files[0].name.lastIndexOf(".");
      const after = files[0].name.slice(index + 1);
      //debugger;
      for (let i = 0; i < files.length; i++) {
        if (
          files[i].type === "text/csv" ||
          files[i].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          files[i].type === "application/vnd.ms-excel" ||
          files[i].type === "application/msword" ||
          files[i].type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          files[i].type === "application/pdf" ||
          files[i].type === "application/vnd.rar" ||
          files[i].type === "application/zip" ||
          files[i].type === "application/octet-stream" ||
          files[i].type === "application/vnd.openxmlformats" ||
          files[i].type === "application/rtf" ||
          files[i].type === "text/plain" ||
          files[i].type === "image/jpeg" ||
          files[i].type === "image/jpg" ||
          files[i].type === "image/JPG" ||
          files[i].type === "image/JPEG" ||
          files[i].type === "image/png" ||
          files[i].type === "image/bmp" ||
          files[i].type.includes("image")
        ) {
          //alert(files[i].type);
          //setFiletypeMessage
          if (after == "exe") {
            flagfortype = 0;
            count = count + 1;
            //alert("Invalid File Type : exe ");
            if (value1 === "fu_schematiclayout") {
              setFiletypeMessage1("Invalid File Type");
            } else if (value1 === "fu_additional1") {
              setFiletypeMessage3("Invalid File Type");
            } else if (value1 === "fu_additional2") {
              setFiletypeMessage3("Invalid File Type");
            } else if (value1 === "fu_hoboproject") {
              setFiletypeMessage2("Invalid File Type");
            } else if (value1 === "fu_additional3") {
              setFiletypeMessage3("Invalid File Type");
            }
            break;
          } else {
            flagfortype = 1;
          }
        } else {
          if (
            after == "dtf" ||
            after == "hproj" ||
            after == "pptx" ||
            after == "ppt"
          ) {
            flagfortype = 1;
            count = 0;
          } else {
            count = count + 1;
            //alert("Invalid File Type");
            flagfortype = 0;
            if (value1 === "fu_schematiclayout") {
              setFiletypeMessage1("Invalid File Type");
            } else if (value1 === "fu_additional1") {
              setFiletypeMessage3("Invalid File Type");
            } else if (value1 === "fu_additional2") {
              setFiletypeMessage3("Invalid File Type");
            } else if (value1 === "fu_hoboproject") {
              setFiletypeMessage2("Invalid File Type");
            } else if (value1 === "fu_additional3") {
              setFiletypeMessage3("Invalid File Type");
            }
            break;
          }
        }
      }

      if (flagfortype === 1 && count == 0) {
        console.log(files[0], files);
        axios
          .get(
            API_ENDPOINT +
              "/type/" +
              "FileNetworkDetails" +
              "/name/" +
              files[0].path +
              "/size/" +
              files[0].size
          )
          .then(function (response) {
            let var2 = response.data.uploadUrl;
            let s3Location = var2.substring(0, var2.indexOf("?"));

            fileUploadObj[value1] = s3Location;
            fileUploadObj[value2] = files[0].name;

            const f = files[0];
            uploadPresigned(f, response?.data?.uploadUrl, uploadFunction).then(
              (res) => {
                if (res.status == 200) {
                } else {
                  fileUploadObj[value1] = "";
                  fileUploadObj[value2] = "";
                }
              }
            );
          })
          .catch((e) => {
            console.log("error", e);
          });
      }
    }
  };

  const handleattachpic = (item) => {
    const arrpic = new Array();
    arrpic.push(item);
    console.log(arrpic);

    console.log(arrpic);

    if (
      item.includes(".JPG") ||
      item.includes(".JPEG") ||
      item.includes(".jpg") ||
      item.includes(".jpeg") ||
      item.includes(".bmp") ||
      item.includes(".png")
    ) {
      console.log("Calling pic preview");
      setdatapic(arrpic);
      setShowForm1(true);
    } else {
      downloadFileDirect(item);
    }
  };

  function deletefilesfroms3(input) {
    console.log(input);
    const result2 = fetch(input, {
      method: "DELETE",
      body: input,
    });
  }

  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log("fileUploadObj", fileUploadObj);
    if (fileUploadObj.fu_enquiryid === "") {
      fileUploadObj.fu_enquiryid = enquirykey;
    }
    let result;
    result = EnrollNewFiles(fileUploadObj);

    result
      .then((resp) => {
        let ans = alert(resp.message);
        if (ans) {
          props.onHide();
          props.setReload(!props.reload);
        }
      })
      .catch((error) => {
        console.log("Unable to process request");
      });
  };

  let PageSize = 200;
  const [showInput1, setShowInput1] = useState(
    "form-control1-padding form-control1 d-block"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredResult, setFilteredResult] = useState([]);
  const [Networks, setNetworks] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const getNetworkDetails = () => {
    console.log("enquirykey", enquirykey);
    getLinkedNetworkDetails(enquirykey).then((result) => {
      console.log("table rows");
      console.log(result.tableRows);
      setNetworks(result?.tableRows);
      setFilteredResult(result?.tableRows);
    });
  };

  const [error, setError] = useState({
    nd_networkname: {},
    nd_avg_gen_pressure: {},
    nd_unitvalue: {},
    nd_acceptablepressure: {},
    fu_receivercapacity: {},
  });

  const bodyValidate = (fileUploadObj) => {
    let isError = false;
    let a = [];
    if (currentTableData?.length > 0) {
      let tableError = [...currentTableDataError];
      currentTableData.forEach((value, index) => {
        if (value.nd_networkname == "") {
          tableError[index].nd_networknameError = "Enter Network Name";
          isError = true;
        }
        if (value.nd_avg_gen_pressure == "") {
          tableError[index].nd_avg_gen_pressureError =
            "Enter Avg Gen. Pressure";
          isError = true;
        }
        if (value.nd_unit == "") {
          tableError[index].nd_unitError = "Select Unit";
          isError = true;
        }
        if (value.nd_acceptablepressure == "") {
          tableError[index].nd_acceptablepressureError =
            "Enter Acceptable Pressure";
          isError = true;
        }
        if (
          Number(value.nd_acceptablepressure) >
          Number(value.nd_avg_gen_pressure)
        ) {
          isError = true;
          a.push(value.nd_networkname);
        }
      });
      setCurrentTableDataError([...tableError]);
      console.log("temprun444data", tableError);
    }
    if (currentTableDataError?.length > 0) {
      currentTableDataError.forEach((value, index) => {
        if (
          (value.nd_networknameError !== null &&
            value.nd_networknameError !== "") ||
          (value.nd_avg_gen_pressureError !== null &&
            value.nd_avg_gen_pressureError !== "") ||
          (value.nd_unitError !== "" && value.nd_unitError !== null) ||
          (value.nd_acceptablepressureError !== null &&
            value.nd_acceptablepressureError !== "")
        ) {
          isError = true;
        }
      });
    }
    if (fileUploadObj.fu_schematiclayout === "") {
      setValidateMessage("Schematic Layout is required ");
      isError = true;
    }
    if (fileUploadObj.fu_receivercapacity === "") {
      isError = true;
      error.fu_receivercapacity = {
        status: true,
        message: "Enter Receiver Capacity",
      };
    }
    if (error.fu_receivercapacity.status) {
      isError = true;
    }
    if (a.length > 0 && isError) {
      setShowWarning(true);
      setShowWarnMessage(
        "Acceptable Pressure should not be greater than Avg Gen. Pressure in record : " +
          a.join()
      );
    }
    return isError;
  };

  const tempBodyValidate = () => {
    let isError = false;
    let a = [];
    if (currentTableData?.length > 0) {
      let tableError = [...currentTableDataError];
      currentTableData.forEach((value, index) => {
        if (value.nd_networkname == "") {
          tableError[index].nd_networknameError = "";
          isError = false;
        }
        if (value.nd_avg_gen_pressure == "") {
          tableError[index].nd_avg_gen_pressureError = "";
          isError = false;
        }
        if (value.nd_unit == "") {
          tableError[index].nd_unitError = "";
          isError = false;
        }
        if (value.nd_acceptablepressure == "") {
          tableError[index].nd_acceptablepressureError = "";
          isError = false;
        }
        if (
          Number(value.nd_acceptablepressure) >
          Number(value.nd_avg_gen_pressure)
        ) {
          isError = true;
          a.push(value.nd_networkname);
        }
      });
      setCurrentTableDataError([...tableError]);
      console.log("temprun444data", tableError);
    }
    // if (currentTableDataError?.length > 0) {
    //   currentTableDataError.forEach((value, index) => {
    //     if (
    //       (value.nd_networknameError !== null &&
    //         value.nd_networknameError !== "") ||
    //       (value.nd_avg_gen_pressureError !== null &&
    //         value.nd_avg_gen_pressureError !== "") ||
    //       (value.nd_unitError !== "" && value.nd_unitError !== null) ||
    //       (value.nd_acceptablepressureError !== null &&
    //         value.nd_acceptablepressureError !== "")
    //     ) {
    //       isError = true;
    //     }
    //   });
    // }
    if (error.fu_receivercapacity.status) {
      isError = true;
    }
    if (a.length > 0 && isError) {
      setShowWarning(true);
      setShowWarnMessage(
        "Acceptable Pressure should not be greater than Avg Gen. Pressure in record : " +
          a.join()
      );
    }
    return isError;
  };

  const insertNetworkDetails = async () => {
    let result;
    let flag = false;
    if (fileUploadObj.fu_enquiryid === "") {
      fileUploadObj.fu_enquiryid = enquirykey;
    }
    // bodyValidate()
    // if (!validateInput(Networks, fileUploadObj) && !bodyValidate()) {
    if (!bodyValidate(fileUploadObj)) {
      result = await insertNetwork(Networks, fileUploadObj);
      console.log("temprun3333", result);
      if(result.message=='Unable to find request'){
        setShowWarnMessage("Error in adding data or invalid data.");
        setShowWarning(true);
        flag = false;
        return flag;
      }
      if (result.data.message.includes("fails to match the required pattern")) {
        let s = result.data.message;
        s = s.split("_")[1];
        console.log("In Weird Pattern", s);

        setShowWarnMessage("Special Characters not allowed in " + s);
        setShowWarning(true);
      } else if (result.data.message.includes("must be")) {
        let s = result.data.message;
        s = s.split("_")[1];
        console.log("In Weird Pattern", s);

        setShowWarnMessage(s);
        setShowWarning(true);
      } else {
        if (
          result.status &&
          (!result.data.message.includes(
            "fails to match the required pattern"
          ) ||
            !result.data.message.includes("must be"))
        ) {
          setShowMessage(result.data.message);
          setShow(true);
          flag = true;
          // props.setGoToNext(true);
        } else {
          setShowWarnMessage("Error in adding data or invalid data.");
          setShowWarning(true);
          // alert(result.data.message);
          //props.setGoToNext(true);
          flag = false;
        }
      }
      setValidateMessage("");
    }
    return flag;
  };

  const insertNetworkDetailsTemp = async () => {
    let result;
    if (fileUploadObj.fu_enquiryid === "") {
      fileUploadObj.fu_enquiryid = enquirykey;
    }

    // if (
    //   (value.nd_networknameError !== null &&
    //     value.nd_networknameError !== "") ||
    //   (value.nd_avg_gen_pressureError !== null &&
    //     value.nd_avg_gen_pressureError !== "") ||
    //   (value.nd_unitError !== "" && value.nd_unitError !== null) ||
    //   (value.nd_acceptablepressureError !== null &&
    //     value.nd_acceptablepressureError !== "")
    // )
    for (let i = 0; i < Networks.length; i++) {
      if (Networks[i].nd_avg_gen_pressure == null) {
        Networks[i].nd_avg_gen_pressure = 0;
      }
    }

    console.log(Networks);
    if (!tempBodyValidate()) {
      result = await insertNetwork(Networks, fileUploadObj);
      console.log("temprun33334", result);
      if(result.message=='Unable to find request'){
        setShowWarnMessage("Error in adding data or invalid data.");
        setShowWarning(true);
      }
      if (result.data.message.includes("fails to match the required pattern")) {
        let s = result.data.message;
        s = s.split("_")[1];
        console.log("In Weird Pattern", s);

        setShowWarnMessage("Special Characters not allowed in " + s);
        setShowWarning(true);
      } else if (result.data.message.includes("must be")) {
        let s = result.data.message;
        s = s.split("_")[1];
        console.log("In Weird Pattern", s);

        setShowWarnMessage(s);
        setShowWarning(true);
      } else if (result.status) {
        console.log("tresuktttt", result);
        setShowMessage(result.data.message);
        setShow(true);
      } else {
        setShowWarnMessage("Error in adding data or invalid data.");
        setShowWarning(true);
        // alert(result.data.message);
      }
      setValidateMessage("");
    }
  };

  // useEffect(() => {
  //   props.IsDisabledempFunc.current = insertNetworkDetailsTemp;
  // }, [Networks]);

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (filteredResult?.length > 0) {
      let tempData = filteredResult.slice(firstPageIndex, lastPageIndex);
      let errorData = [];
      if (tempData.length > 0) {
        errorData = tempData.map(() => {
          let tempVal = {
            nd_networknameError: "",
            nd_avg_gen_pressureError: "",
            nd_unitError: "",
            nd_acceptablepressureError: "",
          };
          return tempVal;
        });
      }
      setCurrentTableData(tempData);
      setCurrentTableDataError(errorData);
    } else {
      setCurrentTableData([]);
    }
  }, [currentPage, filteredResult, PageSize]);
  const handleChange = (e, index) => {
    let result = [...Networks];
    result[index][e.target.name] = e.target.value;
    if (e.target.name === "nd_unit") {
      result[index]["nd_unitvalue"] = e.target.value;
    }
    setNetworks(result);
  };
  const handleChangeval = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    setfileUploadObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      //[filetypeMessage1, setFiletypeMessage1]
      setFiletypeMessage1("");
      setFiletypeMessage2("");
      setFiletypeMessage3("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [filetypeMessage1, filetypeMessage2, filetypeMessage3]);
  //end

  return (
    <>
      <CommonModal
        message={showMessage}
        show={show}
        handleClose={() => {
          setShow(false);
        }}
      />
      <WarningModal
        message={showWarnMessage}
        show={showWarning}
        handleClose={() => {
          setShowWarning(false);
        }}
      />
      {
        <Form onSubmit={handleSubmit} name="masterForm" id="myForm">
          <Form className="filter" name="filterForm">
            <div>
              <span className="btn-text">Upload Docs</span>
            </div>
            <div className="form-evaluation" id="uploader">
              <Row className="gx-3 mt-2 mb-2">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <div id="uploader">
                    <Form.Group>
                      <Form.Label>
                        Schematic Layout File Upload
                        <span className="req-input">Required</span>
                        <br />
                        <span className="no1req-input">
                          .hproj, .dtf, WORD, EXCEL, PDF, IMAGES, RAR, PPT, ZIP
                          formats
                        </span>
                      </Form.Label>

                      <div className="dropZoneMainDiv">
                        {!fileUploadObj?.fu_schematiclayout && (
                          <Dropzone
                            // maxSize={maxSize}
                            disabled={props.IsDisabled === false ? true : false}
                            onDrop={(acceptedFiles) =>
                              handleChangeStatus1(
                                acceptedFiles,
                                "fu_schematiclayout",
                                "fu_schematiclayoutfilename"
                              )
                            }
                            multiple={false}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   // if (e.target.value) {
                            //   //   setValidateMessage("");
                            //   // }
                            // }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className="dropZoneContainer"
                              >
                                <input {...getInputProps()} />
                                <div className="dropOuterCircle">
                                  <div className="dropInnerCircle">
                                    <img src={uploadIcon} alt="uploadIcon" />
                                  </div>
                                </div>
                                <div className="dropTextContainer">
                                  <span className="dropText">
                                    Drag and drop file here or{" "}
                                  </span>
                                  <span className="dropTextBlue">
                                    Browse file
                                  </span>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                        {docPercent1 > 0 &&
                          fileUploadObj?.fu_schematiclayout && (
                            <div className="dropZoneContainer">
                              <div className="dropTextContainer">
                                <span className="dropText">
                                  <NavLink
                                    className="uploadNavlink"
                                    onClick={() =>
                                      handleattachpic(
                                        fileUploadObj?.fu_schematiclayout
                                      )
                                    }
                                  >
                                    {fileUploadObj?.fu_schematiclayoutfilename.slice(
                                      0,
                                      20
                                    ) +
                                      (fileUploadObj?.fu_schematiclayoutfilename
                                        .length > 20
                                        ? "..."
                                        : "")}
                                    {/* {fileUploadObj?.fu_schematiclayoutfilename} */}
                                  </NavLink>
                                </span>
                              </div>
                              <div className="dropLeftDiv">
                                <div className="dropLeftUploadPercent">
                                  {docPercent1 + "%"}
                                </div>
                                {docPercent1 == "100" && (
                                  <img
                                    className="dropLeftIcons"
                                    src={greenTick}
                                    alt="greenTick"
                                  />
                                )}
                                {docPercent1 == "100" && (
                                  <img
                                    hidden={
                                      props.IsDisabled === false ? true : false
                                    }
                                    className="dropLeftIcons"
                                    onClick={() => {
                                      deletefilesfroms3(
                                        fileUploadObj.fu_schematiclayout
                                      );
                                      setDocPercent1("");
                                      fileUploadObj.fu_schematiclayout = "";
                                      fileUploadObj.fu_schematiclayoutfilename =
                                        "";
                                    }}
                                    src={deleteIcon}
                                    alt="uploadIcon"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        {docPercent1 == 0 &&
                          fileUploadObj?.fu_schematiclayout && (
                            <div className="dropZoneContainer">
                              <div className="dropTextContainer">
                                <Form.Label className="mt-0">
                                  <NavLink
                                    onClick={() =>
                                      handleattachpic(
                                        fileUploadObj.fu_schematiclayout
                                      )
                                    }
                                  >
                                    {fileUploadObj?.fu_schematiclayoutfilename.slice(
                                      0,
                                      20
                                    ) +
                                      (fileUploadObj?.fu_schematiclayoutfilename
                                        .length > 20
                                        ? "..."
                                        : "")}
                                  </NavLink>
                                </Form.Label>
                              </div>
                              <div className="dropLeftDiv">
                                <div className="dropLeftUploadPercent">
                                  {docPercent1 == "0" && (
                                    <img
                                      hidden={
                                        props.IsDisabled === false
                                          ? true
                                          : false
                                      }
                                      className="dropLeftIcons"
                                      onClick={() => {
                                        deletefilesfroms3(
                                          fileUploadObj.fu_schematiclayout
                                        );
                                        setDocPercent1("");
                                        fileUploadObj.fu_schematiclayout = "";
                                        fileUploadObj.fu_schematiclayoutfilename =
                                          "";
                                      }}
                                      src={deleteIcon}
                                      alt="uploadIcon"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </Form.Group>
                    {validateMessage && (
                      <div className="text-danger">{validateMessage}</div>
                    )}
                    {MaxSizeMessageSCL && (
                      <div className="text-danger">{MaxSizeMessageSCL}</div>
                    )}
                    <div>
                      {filetypeMessage1 && (
                        <Alert className="alertBox" variant="warning">
                          {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                          {filetypeMessage1}
                        </Alert>
                      )}
                    </div>
                    <Form.Group>
                      {/* {error.pm_technicaldocattachment.status && (
                      <span className="req-input">
                        {error.pm_technicaldocattachment.message}
                      </span>
                    )} */}
                    </Form.Group>
                  </div>
                  <div id="uploader" className="mt-md-4 mt-3">
                    <div className="pt-md-1">
                      <Form.Group className="pt-md-2">
                        <Form.Label>
                          Hobo Project File Upload
                          <br />
                          <span className="no1req-input">
                            .hproj, .dtf, WORD, EXCEL, PDF, IMAGES, RAR, PPT,
                            ZIP formats
                          </span>
                        </Form.Label>
                        <div className="dropZoneMainDiv">
                          {!fileUploadObj?.fu_hoboproject && (
                            <Dropzone
                              // maxSize={maxSize}
                              disabled={
                                props.IsDisabled === false ? true : false
                              }
                              onDrop={(acceptedFiles) =>
                                handleChangeStatus1(
                                  acceptedFiles,
                                  "fu_hoboproject",
                                  "fu_hobofilename"
                                )
                              }
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps()}
                                  className="dropZoneContainer"
                                >
                                  <input {...getInputProps()} />
                                  <div className="dropOuterCircle">
                                    <div className="dropInnerCircle">
                                      <img src={uploadIcon} alt="uploadIcon" />
                                    </div>
                                  </div>
                                  <div className="dropTextContainer">
                                    <span className="dropText">
                                      Drag and drop file here or{" "}
                                    </span>
                                    <span className="dropTextBlue">
                                      Browse file
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          )}
                          {docPercent4 > 0 && fileUploadObj?.fu_hoboproject && (
                            <div className="dropZoneContainer">
                              <div className="dropTextContainer">
                                <span className="dropText">
                                  <NavLink
                                    className="uploadNavlink"
                                    onClick={() =>
                                      handleattachpic(
                                        fileUploadObj?.fu_hoboproject
                                      )
                                    }
                                  >
                                    {fileUploadObj?.fu_hobofilename.slice(
                                      0,
                                      20
                                    ) +
                                      (fileUploadObj?.fu_hobofilename.length >
                                      20
                                        ? "..."
                                        : "")}
                                    {/* {fileUploadObj?.fu_hobofilename} */}
                                  </NavLink>
                                </span>
                              </div>
                              <div className="dropLeftDiv">
                                <div className="dropLeftUploadPercent">
                                  {docPercent4 + "%"}
                                </div>
                                {docPercent4 == "100" && (
                                  <img
                                    className="dropLeftIcons"
                                    src={greenTick}
                                    alt="greenTick"
                                  />
                                )}
                                {docPercent4 == "100" && (
                                  <img
                                    hidden={
                                      props.IsDisabled === false ? true : false
                                    }
                                    className="dropLeftIcons"
                                    onClick={() => {
                                      deletefilesfroms3(
                                        fileUploadObj.fu_hoboproject
                                      );
                                      setDocPercent4("");
                                      fileUploadObj.fu_hoboproject = "";
                                      fileUploadObj.fu_hobofilename = "";
                                    }}
                                    src={deleteIcon}
                                    alt="uploadIcon"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {docPercent4 == 0 &&
                            fileUploadObj?.fu_hoboproject && (
                              <div className="dropZoneContainer">
                                <div className="dropTextContainer">
                                  <Form.Label>
                                    <NavLink
                                      onClick={() =>
                                        handleattachpic(
                                          fileUploadObj.fu_hoboproject
                                        )
                                      }
                                    >
                                      {fileUploadObj?.fu_hobofilename.slice(
                                        0,
                                        20
                                      ) +
                                        (fileUploadObj?.fu_hobofilename.length >
                                        20
                                          ? "..."
                                          : "")}
                                    </NavLink>
                                  </Form.Label>
                                </div>
                                <div className="dropLeftDiv">
                                  <div className="dropLeftUploadPercent">
                                    {docPercent4 == "0" && (
                                      <img
                                        hidden={
                                          props.IsDisabled === false
                                            ? true
                                            : false
                                        }
                                        className="dropLeftIcons"
                                        onClick={() => {
                                          deletefilesfroms3(
                                            fileUploadObj.fu_hoboproject
                                          );
                                          setDocPercent4("");
                                          fileUploadObj.fu_hoboproject = "";
                                          fileUploadObj.fu_hobofilename = "";
                                        }}
                                        src={deleteIcon}
                                        alt="uploadIcon"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>

                        <div>
                          {filetypeMessage2 && (
                            <Alert className="alertBox" variant="warning">
                              {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                              {filetypeMessage2}
                            </Alert>
                          )}
                        </div>
                      </Form.Group>
                      {MaxSizeMessageHOBO && (
                        <div className="text-danger">{MaxSizeMessageHOBO}</div>
                      )}
                      <Form.Group></Form.Group>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mt-3 mt-md-0">
                  <div id="uploader">
                    <Form.Group>
                      <Form.Label>
                        Additional File Upload
                        <br />
                        <span className="no1req-input">
                          .hproj, .dtf, WORD, EXCEL, PDF, IMAGES, RAR, PPT, ZIP
                          formats
                        </span>
                      </Form.Label>
                      <div className="dropZoneMainDiv">
                        {!fileUploadObj?.fu_additional1 && (
                          <Dropzone
                            // maxSize={maxSize}
                            disabled={props.IsDisabled === false ? true : false}
                            onDrop={(acceptedFiles) =>
                              handleChangeStatus1(
                                acceptedFiles,
                                "fu_additional1",
                                "fu_attch1filename"
                              )
                            }
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className="dropZoneContainer"
                              >
                                <input {...getInputProps()} />
                                <div className="dropOuterCircle">
                                  <div className="dropInnerCircle">
                                    <img src={uploadIcon} alt="uploadIcon" />
                                  </div>
                                </div>
                                <div className="dropTextContainer">
                                  <span className="dropText">
                                    Drag and drop file here or{" "}
                                  </span>
                                  <span className="dropTextBlue">
                                    Browse file
                                  </span>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                        {docPercent2 > 0 && fileUploadObj?.fu_additional1 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <span className="dropText">
                                <NavLink
                                  className="uploadNavlink"
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj?.fu_additional1
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch1filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch1filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                  {/* {fileUploadObj?.fu_attch1filename} */}
                                </NavLink>
                              </span>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent2 + "%"}
                              </div>
                              {docPercent2 == "100" && (
                                <img
                                  className="dropLeftIcons"
                                  src={greenTick}
                                  alt="greenTick"
                                />
                              )}
                              {docPercent2 == "100" && (
                                <img
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  className="dropLeftIcons"
                                  onClick={() => {
                                    deletefilesfroms3(
                                      fileUploadObj.fu_additional1
                                    );
                                    setDocPercent2("");
                                    fileUploadObj.fu_additional1 = "";
                                    fileUploadObj.fu_attch1filename = "";
                                  }}
                                  src={deleteIcon}
                                  alt="uploadIcon"
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {docPercent2 == 0 && fileUploadObj?.fu_additional1 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <Form.Label>
                                <NavLink
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj.fu_additional1
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch1filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch1filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                </NavLink>
                              </Form.Label>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent2 == "0" && (
                                  <img
                                    hidden={
                                      props.IsDisabled === false ? true : false
                                    }
                                    className="dropLeftIcons"
                                    onClick={() => {
                                      deletefilesfroms3(
                                        fileUploadObj.fu_additional1
                                      );
                                      setDocPercent2("");
                                      fileUploadObj.fu_additional1 = "";
                                      fileUploadObj.fu_attch1filename = "";
                                    }}
                                    src={deleteIcon}
                                    alt="uploadIcon"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    {MaxSizeMessageADD1 && (
                      <div className="text-danger">{MaxSizeMessageADD1}</div>
                    )}
                    <Form.Group></Form.Group>
                  </div>
                  <div id="uploader" className="mt-3">
                    <Form.Group>
                      <div className="dropZoneMainDiv">
                        {!fileUploadObj?.fu_additional2 && (
                          <Dropzone
                            // maxSize={maxSize}
                            disabled={props.IsDisabled === false ? true : false}
                            onDrop={(acceptedFiles) =>
                              handleChangeStatus1(
                                acceptedFiles,
                                "fu_additional2",
                                "fu_attch2filename"
                              )
                            }
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className="dropZoneContainer"
                              >
                                <input {...getInputProps()} />
                                <div className="dropOuterCircle">
                                  <div className="dropInnerCircle">
                                    <img src={uploadIcon} alt="uploadIcon" />
                                  </div>
                                </div>
                                <div className="dropTextContainer">
                                  <span className="dropText">
                                    Drag and drop file here or{" "}
                                  </span>
                                  <span className="dropTextBlue">
                                    Browse file
                                  </span>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                        {docPercent3 > 0 && fileUploadObj?.fu_additional2 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <span className="dropText">
                                <NavLink
                                  className="uploadNavlink"
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj?.fu_additional2
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch2filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch2filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                  {/* {fileUploadObj?.fu_attch2filename} */}
                                </NavLink>
                              </span>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent3 + "%"}
                              </div>
                              {docPercent3 == "100" && (
                                <img
                                  className="dropLeftIcons"
                                  src={greenTick}
                                  alt="greenTick"
                                />
                              )}
                              {docPercent3 == "100" && (
                                <img
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  className="dropLeftIcons"
                                  onClick={() => {
                                    deletefilesfroms3(
                                      fileUploadObj.fu_additional2
                                    );
                                    setDocPercent3("");
                                    fileUploadObj.fu_additional2 = "";
                                    fileUploadObj.fu_attch2filename = "";
                                  }}
                                  src={deleteIcon}
                                  alt="uploadIcon"
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {docPercent3 == 0 && fileUploadObj?.fu_additional2 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <Form.Label>
                                <NavLink
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj.fu_additional2
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch2filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch2filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                  {/* {fileUploadObj.fu_attch2filename} */}
                                </NavLink>
                              </Form.Label>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent3 == "0" && (
                                  <img
                                    hidden={
                                      props.IsDisabled === false ? true : false
                                    }
                                    className="dropLeftIcons"
                                    onClick={() => {
                                      deletefilesfroms3(
                                        fileUploadObj.fu_additional2
                                      );
                                      setDocPercent3("");
                                      fileUploadObj.fu_additional2 = "";
                                      fileUploadObj.fu_attch2filename = "";
                                    }}
                                    src={deleteIcon}
                                    alt="uploadIcon"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    {MaxSizeMessageADD2 && (
                      <div className="text-danger">{MaxSizeMessageADD2}</div>
                    )}
                    <Form.Group></Form.Group>
                  </div>
                  <div id="uploader" className="mt-3">
                    <Form.Group>
                      <div className="dropZoneMainDiv">
                        {!fileUploadObj?.fu_additional3 && (
                          <Dropzone
                            // maxSize={maxSize}

                            disabled={props.IsDisabled === false ? true : false}
                            onDrop={(acceptedFiles) =>
                              handleChangeStatus1(
                                acceptedFiles,
                                "fu_additional3",
                                "fu_attch3filename"
                              )
                            }
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps()}
                                className="dropZoneContainer"
                              >
                                <input {...getInputProps()} />
                                <div className="dropOuterCircle">
                                  <div className="dropInnerCircle">
                                    <img src={uploadIcon} alt="uploadIcon" />
                                  </div>
                                </div>
                                <div className="dropTextContainer">
                                  <span className="dropText">
                                    Drag and drop file here or{" "}
                                  </span>
                                  <span className="dropTextBlue">
                                    Browse file
                                  </span>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                        {docPercent5 > 0 && fileUploadObj?.fu_additional3 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <span className="dropText">
                                <NavLink
                                  className="uploadNavlink"
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj?.fu_additional3
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch3filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch3filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                  {/* {fileUploadObj?.fu_attch3filename} */}
                                </NavLink>
                              </span>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent5 + "%"}
                              </div>
                              {docPercent5 == "100" && (
                                <img
                                  className="dropLeftIcons"
                                  src={greenTick}
                                  alt="greenTick"
                                />
                              )}
                              {docPercent5 == "100" && (
                                <img
                                  hidden={
                                    props.IsDisabled === false ? true : false
                                  }
                                  className="dropLeftIcons"
                                  onClick={() => {
                                    deletefilesfroms3(
                                      fileUploadObj.fu_additional3
                                    );
                                    setDocPercent5("");
                                    fileUploadObj.fu_additional3 = "";
                                    fileUploadObj.fu_attch3filename = "";
                                  }}
                                  src={deleteIcon}
                                  alt="uploadIcon"
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {docPercent5 == 0 && fileUploadObj?.fu_additional3 && (
                          <div className="dropZoneContainer">
                            <div className="dropTextContainer">
                              <Form.Label>
                                <NavLink
                                  onClick={() =>
                                    handleattachpic(
                                      fileUploadObj.fu_additional3
                                    )
                                  }
                                >
                                  {fileUploadObj?.fu_attch3filename.slice(
                                    0,
                                    20
                                  ) +
                                    (fileUploadObj?.fu_attch3filename.length >
                                    20
                                      ? "..."
                                      : "")}
                                </NavLink>
                              </Form.Label>
                            </div>
                            <div className="dropLeftDiv">
                              <div className="dropLeftUploadPercent">
                                {docPercent5 == "0" && (
                                  <img
                                    hidden={
                                      props.IsDisabled === false ? true : false
                                    }
                                    className="dropLeftIcons"
                                    onClick={() => {
                                      deletefilesfroms3(
                                        fileUploadObj.fu_additional3
                                      );
                                      setDocPercent5("");
                                      fileUploadObj.fu_additional3 = "";
                                      fileUploadObj.fu_attch3filename = "";
                                    }}
                                    src={deleteIcon}
                                    alt="uploadIcon"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    {MaxSizeMessageADD3 && (
                      <div className="text-danger">{MaxSizeMessageADD3}</div>
                    )}
                    <Form.Group></Form.Group>
                  </div>
                  <div>
                    {filetypeMessage3 && (
                      <Alert className="alertBox" variant="warning">
                        {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
                        {filetypeMessage3}
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      Receiver Capacity (m3)
                      <span className="req-input">Required</span>
                      {/* <br /> */}
                    </Form.Label>
                    <Form.Control
                      disabled={props.IsDisabled === false ? true : false}
                      type="text"
                      placeholder="Receiver Capacity"
                      name="fu_receivercapacity"
                      value={fileUploadObj.fu_receivercapacity}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChangeval(e);
                        let error = isNumberWithDecimal(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          fu_receivercapacity: error,
                        }));
                      }}
                    />
                    {error.fu_receivercapacity.status && (
                      <div className="text-danger">
                        {error.fu_receivercapacity.message}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Form>

          {/* <Modal.Footer className="justify-content-start">
            <Button type="submit" id="bs">
              {"Save"}
            </Button>
            <Button variant="outline-primary" onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer> */}
        </Form>
      }

      <div className="">
        <br />
        <div>
          <span className="btn-text">Network Details</span>
        </div>
      </div>
      <div className="roiTable">
        <div className="d-none d-md-block d-lg-block">
          {currentTableData && (
            <Form autoComplete="off">
              <div>
                <div className="TableB">
                  <Row className="ps-3 pe-2">
                    <Col md={1} className="tableB-header">
                      Network
                    </Col>
                    <Col md={2} className="tableB-header">
                      CH
                    </Col>
                    <Col md={2} className="tableB-header">
                      Applications
                    </Col>
                    <Col md={1} className="tableB-header">
                      Specific Power
                    </Col>
                    <Col md={2} className="tableB-header">
                      Network Name
                    </Col>
                    <Col md={1} className="tableB-header">
                      Avg Gen. Pressure
                    </Col>
                    <Col md={1} className="tableB-header">
                      Unit
                    </Col>
                    <Col md={1} className="tableB-header">
                      Acceptable Pressure
                    </Col>
                    <Col md={1} className="tableB-header">
                      Unit
                    </Col>
                  </Row>
                </div>
                {currentTableData.map((item, index) => {
                  return (
                    <div className="tableB-row">
                      <Row className="ps-3 pe-2">
                        <Col md={1} className="tableB-text">
                          {item.nd_network}
                        </Col>
                        <Col md={2} className="tableB-text">
                          {item.nd_ch_name}
                        </Col>
                        <Col md={2} className="tableB-text">
                          {item.nd_apps_name}
                        </Col>
                        <Col md={1} className="tableB-text">
                          {item.nd_power}
                        </Col>
                        <Col md={2} className="tableB-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            name="nd_networkname"
                            value={Networks[index]?.nd_networkname}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = alphaNumericWithSpace(
                                e.target.value,
                                "Network Name"
                              );
                              tempError[index].nd_networknameError = error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index].nd_networknameError && (
                            <div className="text-danger">
                              {currentTableDataError[index].nd_networknameError}
                            </div>
                          )}
                        </Col>
                        <Col md={1} className="tableB-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            name="nd_avg_gen_pressure"
                            value={Networks[index]?.nd_avg_gen_pressure}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = validTwoDecimals(
                                e.target.value,
                                "Pressure"
                              );
                              tempError[index].nd_avg_gen_pressureError = error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index]
                            .nd_avg_gen_pressureError && (
                            <div className="text-danger">
                              {
                                currentTableDataError[index]
                                  .nd_avg_gen_pressureError
                              }
                            </div>
                          )}
                        </Col>
                        <Col md={1} className="tableB-input">
                          <Form.Select
                            disabled={props.IsDisabled === false ? true : false}
                            name="nd_unit"
                            placeholder="Unit"
                            className={showInput1}
                            value={Networks[index]?.nd_unit}
                            onChange={(e) => {
                              handleChange(e, index);
                              let tempError = [...currentTableDataError];
                              if (e.target.value !== "") {
                                tempError[index].nd_unitError = "";
                              }
                              setCurrentTableDataError([...tempError]);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="barg">barg</option>
                            <option value="psig">psig</option>
                          </Form.Select>
                          {currentTableDataError[index].nd_unitError && (
                            <div className="text-danger">
                              {currentTableDataError[index].nd_unitError}
                            </div>
                          )}
                        </Col>
                        <Col md={1} className="tableB-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            name="nd_acceptablepressure"
                            value={Networks[index]?.nd_acceptablepressure}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = validTwoDecimals(
                                e.target.value,
                                "Pressure"
                              );
                              tempError[index].nd_acceptablepressureError =
                                error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index]
                            .nd_acceptablepressureError && (
                            <div className="text-danger">
                              {
                                currentTableDataError[index]
                                  .nd_acceptablepressureError
                              }
                            </div>
                          )}
                        </Col>
                        <Col md={1} className="tableB-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            readOnly={true}
                            type="text"
                            className={showInput1}
                            name="nd_unitvalue"
                            value={Networks[index]?.nd_unitvalue}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })}

                {/* <div className="table-body">
                {currentTableData.map((item, index) => {
                  console.log(index);
                  return (
                    <Form name="UpdateForm">
                      <div
                        className="table-row2 mt-2"
                        key={`row_${index}`}
                        id={`row_${index}`}
                      >
                        &nbsp;&nbsp;&nbsp;
                        <div className="table-cell ms-0 me-3">
                          <span>{item.nd_network}</span>
                        </div>
                        <div className="table-cell ms-0 me-3">
                          <span>{item.nd_ch_name}</span>
                        </div>
                        <div className="table-cell ms-0 me-3">
                          <span>{item.nd_apps_name}</span>
                        </div>
                        <div className="table-cell ms-0 me-3">
                          <span>{item.nd_power}</span>
                        </div>
                        <div className="filter-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            // className={
                            //   error.nd_networkname.status
                            //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                            //     : "placeHolderInput inputText showInput1"
                            // }
                            name="nd_networkname"
                            value={Networks[index]?.nd_networkname}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = alphaNumericWithSpace(
                                e.target.value,
                                "Network Name"
                              );
                              tempError[index].nd_networknameError = error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index].nd_networknameError && (
                            <div className="text-danger">
                              {currentTableDataError[index].nd_networknameError}
                            </div>
                          )}
                        </div>
                        <div className="filter-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            // className={
                            //   error.nd_avg_gen_pressure.status
                            //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                            //     : "placeHolderInput inputText showInput1"
                            // }
                            name="nd_avg_gen_pressure"
                            value={Networks[index]?.nd_avg_gen_pressure}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = validTwoDecimals(
                                e.target.value,
                                "Pressure"
                              );
                              tempError[index].nd_avg_gen_pressureError = error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index]
                            .nd_avg_gen_pressureError && (
                              <div className="text-danger">
                                {
                                  currentTableDataError[index]
                                    .nd_avg_gen_pressureError
                                }
                              </div>
                            )}
                        </div>
                        <div className="filter-input">
                          <Form.Select
                            disabled={props.IsDisabled === false ? true : false}
                            name="nd_unit"
                            placeholder="Unit"
                            className={showInput1}
                            // className={
                            //   error.nd_unitvalue.status
                            //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                            //     : "placeHolderInput inputText showInput1"
                            // }
                            value={Networks[index]?.nd_unit}
                            onChange={(e) => {
                              handleChange(e, index);
                              let tempError = [...currentTableDataError];
                              if (e.target.value !== "") {
                                tempError[index].nd_unitError = "";
                              }
                              setCurrentTableDataError([...tempError]);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="barg">barg</option>
                            <option value="psig">psig</option>
                          </Form.Select>
                          {currentTableDataError[index].nd_unitError && (
                            <div className="text-danger">
                              {currentTableDataError[index].nd_unitError}
                            </div>
                          )}
                        </div>
                        <div className="filter-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            type="text"
                            className={showInput1}
                            // className={
                            //   error.nd_acceptablepressure.status
                            //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                            //     : "placeHolderInput inputText showInput1"
                            // }
                            name="nd_acceptablepressure"
                            value={Networks[index]?.nd_acceptablepressure}
                            onChange={(e) => handleChange(e, index)}
                            onInput={(e) => {
                              let tempError = [...currentTableDataError];
                              let error = validTwoDecimals(
                                e.target.value,
                                "Pressure"
                              );
                              tempError[index].nd_acceptablepressureError =
                                error;
                              setCurrentTableDataError([...tempError]);
                            }}
                          />
                          {currentTableDataError[index]
                            .nd_acceptablepressureError && (
                              <div className="text-danger">
                                {
                                  currentTableDataError[index]
                                    .nd_acceptablepressureError
                                }
                              </div>
                            )}
                        </div>
                        <div className="filter-input">
                          <Form.Control
                            disabled={props.IsDisabled === false ? true : false}
                            readOnly={true}
                            type="text"
                            className={showInput1}
                            name="nd_unitvalue"
                            value={Networks[index]?.nd_unitvalue}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                })}
              </div> */}
                {/* {error.nd_networkname.status && (
                <div className="text-danger">
                  {error.nd_networkname.message}
                </div>
              )}
              {error.nd_avg_gen_pressure.status && (
                <div className="text-danger">
                  {error.nd_avg_gen_pressure.message}
                </div>
              )}
              {error.nd_unitvalue.status && (
                <div className="text-danger">{error.nd_unitvalue.message}</div>
              )}
              {error.nd_acceptablepressure.status && (
                <div className="text-danger">
                  {error.nd_acceptablepressure.message}
                </div>
              )} */}
              </div>
            </Form>
          )}
        </div>
        <div className="d-md-none d-lg-none table-container">
          <Form name="UpdateForm" autoComplete="off">
            <div className="tableWrapperCompressorModal mobileTableModal">
              <div className="table-body tableBodyCompressorModal">
                {currentTableData.length > 0 &&
                  currentTableData.map((item, index) => {
                    return (
                      <div className="outerTabDiv">
                        <div
                          className={
                            "table-row2 mobileTableRow " +
                            (showmoreless.includes(index) ? "showLess" : "showFull")
                          }
                          key={`row_${index}`}
                          id={`row_${index}`}
                        >
                          <div className="statusHighlightGreen"></div>
                          <div
                            className="status-color-mob"
                            style={{
                              backgroundColor: "#3CB23D",
                            }}
                          ></div>
                          <div className="table-cell">
                            <div className="row w-100 mt-3">
                              <span className="bold-Text">Network</span>

                              <span>{item.nd_network}</span>
                            </div>
                          </div>
                          <div className="table-cell">
                            <div className="row w-100">
                              <span className="bold-Text">CH</span>

                              <span>{item.nd_ch_name}</span>
                            </div>
                          </div>
                          <div className="table-cell">
                            <div className="row w-100">
                              <span className="bold-Text">Applications</span>

                              <span>{item.nd_apps_name}</span>
                            </div>
                          </div>
                          <div className="table-cell">
                            <div className="row w-100">
                              <span className="bold-Text">Specific Power</span>

                              <span>{item.nd_power}</span>
                            </div>
                          </div>
                          <div className="table-cell table-cell-mobile-input">
                            <div className="row">
                              <div className="col-12">
                                <span className="bold-Text">Network Name</span>

                                <Form.Control
                                  disabled={
                                    props.IsDisabled === false ? true : false
                                  }
                                  type="text"
                                  className={showInput1}
                                  // className={
                                  //   error.nd_networkname.status
                                  //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                                  //     : "placeHolderInput inputText showInput1"
                                  // }
                                  name="nd_networkname"
                                  value={Networks[index]?.nd_networkname}
                                  onChange={(e) => handleChange(e, index)}
                                  onInput={(e) => {
                                    let tempError = [...currentTableDataError];
                                    let error = alphaNumeric50(
                                      e.target.value,
                                      "Network Name"
                                    );
                                    tempError[index].nd_networknameError =
                                      error;
                                    setCurrentTableDataError([...tempError]);
                                  }}
                                />
                              </div>
                            </div>
                            {currentTableDataError[index]
                              .nd_networknameError && (
                              <div className="text-danger">
                                {
                                  currentTableDataError[index]
                                    .nd_networknameError
                                }
                              </div>
                            )}
                          </div>
                          <div className="table-cell table-cell-mobile-input">
                            <div className="row">
                              <div className="col-12">
                                <span className="bold-Text w-100">
                                  Avg Gen. Pressure
                                </span>

                                <Form.Control
                                  disabled={
                                    props.IsDisabled === false ? true : false
                                  }
                                  type="text"
                                  className={showInput1}
                                  // className={
                                  //   error.nd_avg_gen_pressure.status
                                  //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                                  //     : "placeHolderInput inputText showInput1"
                                  // }
                                  name="nd_avg_gen_pressure"
                                  value={Networks[index]?.nd_avg_gen_pressure}
                                  onChange={(e) => handleChange(e, index)}
                                  onInput={(e) => {
                                    let tempError = [...currentTableDataError];
                                    let error = validTwoDecimals(
                                      e.target.value,
                                      "Pressure"
                                    );
                                    tempError[index].nd_avg_gen_pressureError =
                                      error;
                                    setCurrentTableDataError([...tempError]);
                                  }}
                                />
                              </div>
                            </div>
                            {currentTableDataError[index]
                              .nd_avg_gen_pressureError && (
                              <div className="text-danger">
                                {
                                  currentTableDataError[index]
                                    .nd_avg_gen_pressureError
                                }
                              </div>
                            )}
                          </div>
                          <div className="table-cell table-cell-mobile-input">
                            <div className="row">
                              <div className="col-12">
                                <span className="bold-Text">Unit</span>

                                <Form.Select
                                  disabled={
                                    props.IsDisabled === false ? true : false
                                  }
                                  name="nd_unit"
                                  placeholder="Unit"
                                  class={showInput1}
                                  // className={
                                  //   error.nd_unitvalue.status
                                  //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                                  //     : "placeHolderInput inputText showInput1"
                                  // }
                                  value={Networks[index]?.nd_unit}
                                  onChange={(e) => {
                                    handleChange(e, index);
                                    let tempError = [...currentTableDataError];
                                    if (e.target.value === "") {
                                      tempError[index].nd_unitError =
                                        "Select Unit";
                                    } else {
                                      tempError[index].nd_unitError = "";
                                    }
                                    setCurrentTableDataError([...tempError]);
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="barg">barg</option>
                                  <option value="psig">psig</option>
                                </Form.Select>
                              </div>
                            </div>
                            {currentTableDataError[index].nd_unitError && (
                              <div className="text-danger">
                                {currentTableDataError[index].nd_unitError}
                              </div>
                            )}
                          </div>
                          <div className="table-cell table-cell-mobile-input">
                            <div className="row">
                              <div className="col-12 mt-2">
                                <span className="bold-Text w-100">
                                  Acceptable Pressure
                                </span>

                                <Form.Control
                                  disabled={
                                    props.IsDisabled === false ? true : false
                                  }
                                  type="text"
                                  className={showInput1}
                                  // className={
                                  //   error.nd_acceptablepressure.status
                                  //     ? "placeHolderInput inputText showInput1 text-field-with-error"
                                  //     : "placeHolderInput inputText showInput1"
                                  // }
                                  name="nd_acceptablepressure"
                                  value={Networks[index]?.nd_acceptablepressure}
                                  onChange={(e) => handleChange(e, index)}
                                  onInput={(e) => {
                                    let tempError = [...currentTableDataError];
                                    let error = validTwoDecimals(
                                      e.target.value,
                                      "Pressure"
                                    );
                                    tempError[
                                      index
                                    ].nd_acceptablepressureError = error;
                                    setCurrentTableDataError([...tempError]);
                                  }}
                                />
                              </div>
                            </div>
                            {currentTableDataError[index]
                              .nd_acceptablepressureError && (
                              <div className="text-danger">
                                {
                                  currentTableDataError[index]
                                    .nd_acceptablepressureError
                                }
                              </div>
                            )}
                          </div>
                          <div className="table-cell table-cell-mobile-input">
                            <div className="row">
                              <div className="col-12">
                                <span className="bold-Text">Unit</span>

                                <Form.Control
                                  disabled={
                                    props.IsDisabled === false ? true : false
                                  }
                                  readOnly={true}
                                  type="text"
                                  className={showInput1}
                                  name="nd_unitvalue"
                                  value={Networks[index]?.nd_unitvalue}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {!item.edit && (
                          <div className="showButton1 showButtonBlueBG">
                            {!showmoreless.includes(index) && (
                              <span
                                onClick={() => {
                                  // let temp = [...currentTableData];
                                  // temp[index].show = true;
                                  // setCurrentTableData([...temp]);
                                  let temp = [...showmoreless];
                                  temp.push(index);
                                  setshowmoreless([...temp])
                                }}
                              >
                                Show More
                              </span>
                            )}
                            {showmoreless.includes(index) && (
                              <span
                                onClick={() => {
                                  // let temp = [...currentTableData];
                                  // temp[index].show = false;
                                  // setCurrentTableData([...temp]);
                                  let temp = [...showmoreless];
                                  const element = temp.indexOf(index);
                                    if (element > -1) { 
                                      temp.splice(element, 1); 
                                    }
                                  setshowmoreless([...temp])
                                }}
                              >
                                Show Less
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </Form>
        </div>
      </div>
      {/* {error.nd_networkname.status && (
          <div className="text-danger">{error.nd_networkname.message}</div>
        )}
        {error.nd_avg_gen_pressure.status && (
          <div className="text-danger">{error.nd_avg_gen_pressure.message}</div>
        )}
        {error.nd_unitvalue.status && (
          <div className="text-danger">{error.nd_unitvalue.message}</div>
        )}
        {error.nd_acceptablepressure.status && (
          <div className="text-danger">
            {error.nd_acceptablepressure.message}
          </div>
        )} */}
      {showForm1 && (
        <Pic_Preview
          show={showForm1}
          data={datapic}
          onHide={() => setShowForm1(false)}
        />
      )}
    </>
  );
  ReactDOM.render(<NewSolution />);
});

export default FileNetworkDetails;
