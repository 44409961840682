import {
  Breadcrumb,
  Button,
  Form,
  Alert,
  Row,
  Col,
  NavLink,
} from "react-bootstrap";
import { BsPlusLg, BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import { getAllUserMaster } from "../../Services/user-service";
import { useEffect, useMemo, useRef, useState } from "react";
import GolbalSearch from "../../Components/utility/GolbalSearch";
import { getAllLocationMaster } from "../../Services/location-service";
import { getActiveRoles } from "../../Services/role-service";
import exportFromJSON from "export-from-json";
import { useHistory } from "react-router-dom";
import { getAllActiveDealers } from "../../Services/dealer-service";
import Scrollbar from "smooth-scrollbar";
import Export from "../../icons/Export.svg";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { getDataMaskStatus } from "../../Services/user-service";

import {
  FilterChange,
  formatDateDD_MM_YYYY,
  resetAllFilter,
  resetFilter,
} from "../../functions/functions";
import AllSolutionHoc from "./AllSolutionHoc";
import NewSolution from "../../Components/NewSolution/NewSolution";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import { deleteByEnquiryNumber } from "../../Services/enquiry-customer-service";
import Customtable from "../../Components/layout/customTable";
import escapeExcelData from "../../utils/customValidation";
import { ExcelDownloadLog } from "../../Services/excel-download-service";
import { store } from "../../redux/store";

const API_URL = process.env.REACT_APP_PRODUCT_API_URL;
const AllSolution = (props) => {
  const userRole = store.getState().global?.userDetails?.um_role;

  //BOC Vikas K on 09/09/2022
  let filteredResult1 = props.tableData;
  let flag = false;
  //EOC Vikas K on 09/09/2022
  const [dataMask, setDataMask] = useState(false);

  //  console.log(props.tableData[0]?.ed_country);
  const gridRef = useRef();
  const [users, setUsers] = useState(null);
  const [reload, setReload] = useState(false);
  const [globalText, setGlobalText] = useState("");
  const [userObj, setUserObj] = useState({
    //BOC Vikas K on 12/09/2022
    ed_enquiryid: "",
    ed_enqstatus: "",
    ed_enquirynumber: "",
    ed_insertedon: "",
    username: "",
    dm_dealercode: "",
    dm_dealercompanyname: "",
    ed_custname: "",
    ed_country: "",
    ed_location_: "",
    ed_telno: "",
    ed_sector: "",
    ed_roi: "",
    //EOC Vikas K on 12/09/2022
  });
  const [filteredResult, setFilteredResult] = useState([]);
  const [rolesCombo, setRolesCombo] = useState("");
  const [dealersCombo, setDealersCombo] = useState("");
  const [locationsCombo, setLocationsCombo] = useState("");
  const [filterDisplay, setFilterDisplay] = useState("filter-input d-none");
  const [filteredCol, setFilteredCol] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [rowData, setRowData] = useState({});
  const [data, setdata] = useState("");
  const [show1, setShow1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();
  let formName = "Enquiry Record";
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const fetchUserMaster = async () => {
    setFilteredResult(filteredResult1);
  };

  useEffect(() => {
    fetchUserMaster();
    fetchDataMaskingSts();
    // props.getData();
    Scrollbar.init(document.querySelector(".app-content"));
  }, [reload]);

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };
  const handleExport = () => {
    let gridData = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      gridData.push(node.data);
    });
    const data = [];
    for (let n = 0; n < gridData.length; n++) {
      data.push({
        Status: gridData[n].ed_enqstatus,
        "Enquiry No.": escapeExcelData(gridData[n].ed_enquirynumber),
        "Enquiry Generation Date": formatDateDD_MM_YYYY(
          gridData[n].ed_insertedon
        ),
        "Enquiry Created By": escapeExcelData(gridData[n].username),
        "Dealer Code": escapeExcelData(gridData[n].dm_dealercode),
        "Dealer Name": escapeExcelData(gridData[n].dm_dealercompanyname),
        "Customer Name": escapeExcelData(gridData[n].ed_custname),
        "Customer Country": escapeExcelData(gridData[n].ed_country),
        Location: escapeExcelData(gridData[n].ed_location_),
        "Customer Telephone No.": escapeExcelData(
          gridData[n].ed_telno,
          dataMask
        ),
        "Customer Business Sector": escapeExcelData(gridData[n].ed_sector),
        ROI: escapeExcelData(gridData[n].ed_roi),
      });
    }
    const fileName = "AllSolution";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  const handleDelete = (row) => {
    let isError = false;
    //console.log(data);
    //   let result = deleteByEnquiryIDCS(row.ed_enquiryid);
    //   result
    //     .then((resp) => {
    //       console.log("RESP ", resp);
    //       if (resp.status) {
    //         alert(resp.data.message);
    //         console.log("result " + row.ed_enquiryid);
    //         //deletefromuserlocation(row.um_id);
    //       } else {
    //         alert(resp.data.message);
    //       }
    //       window.location.reload();
    //     })
    //     .catch((error) => {
    //       console.log("Unable to process request");
    //     });
    // };

    deleteByEnquiryNumber(data.ed_enquirynumber)
      .then((resp) => {
        // console.log(resp);
        if (resp.message == "Status updated as Deleted") {
          setMsg("Great! You’ve deleted Enquiry Record Successfully");
          setReload(!reload);
          setShowSuccess(true);
        } else {
          isError = true;
          // console.log(resp.message);
          setAlertMessage(resp.data.message);
          setReload(!reload);
          setShow1(false);
          //setShowSuccess(true);
          return isError;
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  const handleGlobalSearch = (text) => {
    //BOC Vikas K on 09/09/2022
    flag = true;
    //EOC Vikas K on 09/09/2022
    let searchText = text.toLowerCase();
    // console.log("filterdata");
    setGlobalText(searchText);
    // console.log(searchText);
    if (searchText) {
      let result = props.tableData.filter((item) => {
        // console.log(item, searchText);
        // console.log(item.ed_custname?.toLowerCase().indexOf(searchText));
        return (
          item.ed_enqstatus?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_enquirynumber?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_insertedon?.toLowerCase().indexOf(searchText) >= 0 ||
          item.dm_dealercode?.toLowerCase().indexOf(searchText) >= 0 ||
          item.dm_dealercompanyname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_custname?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_country?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_location_?.toLowerCase().indexOf(searchText) >= 0 ||
          // item.ed_telno?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_sector?.toLowerCase().indexOf(searchText) >= 0 ||
          item.ed_roi?.toLowerCase().indexOf(searchText) >= 0 ||
          item.username?.toLowerCase().indexOf(searchText) >= 0
        );
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(props.tableData);
    }
  };

  const resetForm = () => {
    document.filterForm.reset();
    setUserObj({
      ed_enqstatus: "",
      ed_enquirynumber: "",
      ed_insertedon: "",
      dm_dealercode: "",
      dm_dealercompanyname: "",
      ed_custname: "",
      ed_country: "",
      ed_location_: "",
      ed_telno: "",
      ed_sector: "",
      ed_roi: "",
    });

    setFilteredResult(users);
  };

  const headerName = (field) => {
    var name;
    switch (field) {
      //   case "um_status":
      //     name = "Status";
      //     break;
      //   case "um_id":
      //     name = "User Id";
      //     break;
      //   case "um_name":
      //     name = "User Name";
      //     break;
      //   case "um_type":
      //     name = "User Type";
      //     break;
      //   case "um_role":
      //     name = "User Role";
      //     break;
      //   case "locationname":
      //     name = "Location";
      //     break;
      //   case "um_company":
      //     name = "Company Name";
      //     break;
      //   case "um_approval":
      //     name = "Approval Workflow Applicable";
      //     break;

      case "ed_enqstatus":
        name = "Status";
        break;
      case "ed_enquirynumber":
        name = "Enquiry No.";
        break;
      case "ed_insertedon":
        name = "Enquiry Generation Date";
        break;
      case "username":
        name = "Enquiry Created By";
        break;
      case "dm_dealercode":
        name = "Dealer Code";
        break;
      case "dm_dealercompanyname":
        name = "Dealer Name";
        break;
      case "ed_custname":
        name = "Customer Name";
        break;
      case "ed_country":
        name = "Country";
        break;
      case "ed_location_":
        name = "Location";
        break;
      case "ed_telno":
        name = "Customer Telephone No.";
        break;
      case "ed_sector":
        name = "Customer Business Sector";
        break;

      // case "Quotation Value":
      //   name = "ed_sector";
      //   break;
      case "ed_roi":
        name = "ROI";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      case "Status":
        name = "ed_enqstatus";
        break;
      case "Enquiry No.":
        name = "ed_enquirynumber";
        break;
      case "Enquiry Generation Date":
        name = "ed_insertedon";
        break;
      case "Dealer Code":
        name = "um_company";
        break;
      case "Dealer Name":
        name = "um_name";
        break;
      case "Customer Name":
        name = "ed_custname";
        break;
      case "Country":
        name = "ed_country";
        break;
      case "Location":
        name = "ed_location_";
        break;
      case "Customer Telephone No.":
        name = "ed_telno";
        break;
      case "Customer Business Sector":
        name = "ed_sector";
        break;
      case "ROI":
        name = "ed_roi";
        break;
      case "Enquiry Created By":
        name = "inserted_by";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const actionRenderer = (params) => {
    return (
      <div className="cell-action">
        {/* <BiEdit
          size={32}
          className="p-1"
          color={"blue"}
          onClick={(flag1) =>
            history.push({
              pathname: "/newenquiry",
              data: params.data,
            })
          }
        /> */}
        <img
          src={Edit}
          alt="search"
          className="p-1"
          onClick={(flag1) =>
            history.push({
              pathname: "/newenquiry",
              data: params.data,
            })
          }
        />
        {/* <BiTrash
          size={32}
          className="p-1"
          color={"blue"}
          //onClick={() => handleDelete(params.data)}
          onClick={() => {
            setdata(params.data);
            setShow1(true);
            setAction("Delete");
          }}
        /> */}
        {/* <img
          src={Delete}
          alt="delete"
          className="p-1"
          onClick={() => {
            setdata(params.data);
            setShow1(true);
            setAction("Delete");
          }}
        /> */}
      </div>
    );
  };

  const statusRenderer = (params) => {
    // console.log("Params ", params);
    // let status_color = params.value ? "#D262E6" : params.value ? "#4285F0" : params.value ? "#25C36E" : params.value ? "#FF6565" : "";
    let status_color;
    if (params.value == "Pending with Regional Manager") {
      status_color = "#D262E6";
    } else if (params.value == "Pending to Approve") {
      status_color = "#4285F0";
    } else if (params.value == "Pending for Proposal Status") {
      status_color = "#BE596C";
    } else if (params.value == "Approved by Manager") {
      status_color = "#25C36E";
    } else if (
      params.value == "Rejected by Regional Engineer" ||
      params.value == "Rejected by Manager" ||
      params.value == "Rejected by HO Engineer"
    ) {
      status_color = "#F2BF60";
    }
    // else if (params.value == "Rejected by HO Engineer") {
    //   status_color = "#F2BF60";

    // }
    // else if (params.value == "Rejected by Manager") {
    //   status_color = "#F2BF60";

    // }
    else if (params.value == "Pending with Regional Engineer") {
      status_color = "#D262E6";
    } else if (
      params.value == "Pending with Regional In-charge" ||
      params.value == "Pending with Regional Incharge" ||
      params.value == "Pending with Regional In-Charge"
    ) {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Regional Manager") {
      status_color = "#4285F0";
    } else if (params.value == "Pending with Manager") {
      status_color = "#06A4A5";
    } else if (params.value == "Pending with HO Engineer") {
      status_color = "#FF6565";
    } else if (params.value == "In-Process") {
      status_color = "#0693E3";
    } else if (params.value == "Created") {
      status_color = "#7ABE35";
    } else if (params.value == "Closed") {
      status_color = "#A7A7AB";
    } else {
      status_color = "";
    }
    return (
      <>
        <div
          className="status-color"
          style={{ backgroundColor: status_color }}
        ></div>
        <span
          style={{
            color: status_color,
            fontWeight: "600",
          }}
          className="ms-3"
        >
          {/* {params.value ? "Pending with Regional Manager" : params.value ? "Pending to Approve" : params.value ? "Approved" : params.value ? "Rejected" : "" } */}
          {params.value ? params.value : ""}
        </span>
      </>
    );
  };

  const contains = (target, lookingFor) => {
    return target && target.indexOf(lookingFor) >= 0;
  };

  const [columnDefs] = useState([
    // {
    //   field: "ed_enqstatus",
    //   cellRenderer: statusRenderer,
    //   cellStyle: { paddingLeft: 0, display: "flex", alignItems: "center" },
    //   headerName: "Status",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     filterOptions: ["equals"],
    //     suppressAndOrCondition: true,
    //     textMatcher: ({ value, filterText }) => {
    //       var filterTextLowerCase = filterText ? filterText.toLowerCase() : "";
    //       var valueLowerCase = value.toString().toLowerCase();
    //       var aliases = {
    //         active: "true",
    //         inactive: "false",
    //       };
    //       return contains(valueLowerCase, aliases[filterTextLowerCase]);
    //     },
    //   },
    //   width: 50,
    // },
    {
      field: "ed_enqstatus",
      cellRenderer: statusRenderer,
      cellStyle: { paddingLeft: 0, display: "flex", alignItems: "center" },
      resizable: true,
      headerName: "Status",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enqstatus",
      minWidth: 170,
    },
    {
      field: "ed_enquirynumber",
      resizable: true,
      cellRenderer: (params) => {
        let cellValue = (
          <span>
            <NavLink
              className="uploadNavlink"
              style={{ color: "#264AE4" }}
              onClick={() => {
                history.push({
                  pathname: "/newenquiry",
                  data: params.data,
                });
                let bodyData = {
                  Session: "enquiry",
                  Records: [{ id: params.data.ed_enquiryid }],
                };
                let result = ExcelDownloadLog(bodyData);
              }}
            >
              {params.value}
            </NavLink>
          </span>
        );
        return cellValue;
      },
      headerName: "Enquiry No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_enquirynumber",
      minWidth: 186,
    },
    {
      field: "ed_insertedon",
      resizable: true,
      cellRenderer: (params) => {
        return params.value && (formatDateDD_MM_YYYY(params.value) || "");
      },
      headerName: "Enquiry Generation Date",
      filter: "agDateColumnFilter",
      tooltipField: "ed_insertedon",
      minWidth: 170,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // const dateCell = new Date(cellValue);
          // if (dateCell < filterLocalDateAtMidnight) {
          //   return -1;
          // } else if (dateCell > filterLocalDateAtMidnight) {
          //   return 1;
          // }
          // return 0;

          const dateAsString = formatDateDD_MM_YYYY(cellValue);
          // console.log("dateAsString : ", dateAsString);
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split("-");
          const year = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[0]);
          const dateCell = new Date(year, month, day);
          // console.log("dateCell", dateCell);
          if (dateCell < filterLocalDateAtMidnight) {
            // console.log("dateCell < filterLocalDateAtMidnight");
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            // console.log("dateCell > filterLocalDateAtMidnight");
            return 1;
          }
          // console.log("Returning 0");
          return 0;
        },
      },
    },
    {
      field: "username",
      resizable: true,
      headerName: "Enquiry Created By",
      filter: "agTextColumnFilter",
      tooltipField: "username",
      minWidth: 170,
    },
    {
      field: "dm_dealercode",
      resizable: true,
      headerName: "Dealer Code",
      filter: "agTextColumnFilter",
      tooltipField: "dm_dealercode",
      minWidth: 170,
    },
    {
      field: "dm_dealercompanyname",
      resizable: true,
      headerName: "Dealer Name",
      filter: "agTextColumnFilter",
      tooltipField: "dm_dealercompanyname",
      minWidth: 170,
    },
    {
      field: "ed_custname",
      resizable: true,
      headerName: "Customer Name",
      filter: "agTextColumnFilter",
      tooltipField: "ed_custname",
      minWidth: 170,
    },
    {
      field: "ed_country",
      resizable: true,
      headerName: "Customer Country",
      filter: "agTextColumnFilter",
      tooltipField: "ed_country",
      minWidth: 170,
    },
    {
      field: "ed_location_",
      resizable: true,
      headerName: "Location",
      filter: "agTextColumnFilter",
      tooltipField: "ed_location_",
      minWidth: 170,
    },
    {
      field: "ed_telno",
      resizable: true,
      headerName: "Customer Telephone No.",
      filter: "agTextColumnFilter",
      tooltipField: "ed_telno",
      minWidth: 170,
    },
    {
      field: "ed_sector",
      resizable: true,
      headerName: "Customer Business Sector",
      filter: "agTextColumnFilter",
      tooltipField: "ed_sector",
      minWidth: 170,
    },
    {
      field: "ed_roi",
      resizable: true,
      headerName: "ROI",
      filter: "agTextColumnFilter",
      tooltipField: "ed_roi",
      minWidth: 170,
    },
    // EOC by Vikas K on 12/09/2022
    // {
    //   field: "um_approval",
    //   resizable: true,
    //   headerName: "Customer Code",
    //   filter: "agTextColumnFilter",
    //   tooltipField: "um_approval",
    //   width: 170,
    //   cellRenderer: (params) => {
    //     return params.value ? "Yes" : "No";
    //   },
    // },
    { field: "", cellRenderer: actionRenderer, pinned: "right", width: 90 },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);
  const fetchDataMaskingSts = async () => {
    const result = await getDataMaskStatus(userRole);
    // console.log("getDataMaskStatus", result.result, userRole);
    setDataMask(result.result);
  };
  // BOC Vikas K on 09/09/2022
  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredResult(props.tableData);
    }, 100);
  }, [filteredResult1]);
  // EOC Vikas K on 09/09/2022

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 60000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        //message={`Great! You’ve Deleted Role and Authorization successfully.`}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />

      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <div className="bread-crumb d-none d-md-block d-lg-block">
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/#" active>
            All Records
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="place-center">
        <div className="d-md-none d-lg-none ">
          <Breadcrumb>
            <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/#" active>
              All Records
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <span>
        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
            {alertMessage}
          </Alert>
        )}
      </span>
      <div className="app-content">
        {/* <div className="action-area"> */}
        <Row>
          <Col>
            <div className="global-search-container">
              <GolbalSearch filterFunc={handleGlobalSearch} />
              <img
                src={Export}
                alt="export"
                className="ms-2 p-1"
                onClick={handleExport}
              />
            </div>
          </Col>
        </Row>
        {/* </div> */}
        <div className="d-none mt-3 d-flex" id="filterDisplay">
          {filteredCol &&
            filteredCol.map((item, index) => {
              return (
                <div key={`FilterCol${index}`} className="filter-col pr-0">
                  <span className="ps-2 btn-text">{item}</span>
                  <div onClick={() => resetFilter(gridRef, item, feildName)}>
                    <BsX size={32} />
                  </div>
                </div>
              );
            })}
          <div>
            <Button
              variant="outline-primary"
              className="ms-2 lh-1"
              onClick={() => {
                resetAllFilter(gridRef);
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
        <div id="myGrid" className="d-none d-md-block d-lg-block">
          {props.tableData.length > 0 && (
            <div className="ag-theme-material" style={{ width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredResult}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                headerHeight={56}
                rowHeight={56}
                pagination={true}
                paginationPageSize={10}
                onFilterChanged={(params) => {
                  FilterChange(params, setFilteredCol, headerName);
                }}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
              ></AgGridReact>
            </div>
          )}
        </div>
        <div className="d-md-none d-lg-none table-container1 allSolnStyle">
          <Customtable
            header={[
              {
                id: "ed_enqstatus",
                name: "Status",
                class: "",
              },
              {
                id: "ed_enquirynumber",
                name: "Enquiry No.",
                class: "",
              },
              {
                id: "ed_insertedon",
                name: "Enquiry Generation Date",
                type: "date",
                class: "",
              },
              {
                id: "username",
                name: "Enquiry Created By",
                class: "",
              },
              {
                id: "dm_dealercode",
                name: "Dealer Code",
                class: "",
              },
              {
                id: "dm_dealercompanyname",
                name: "Dealer Name",
                class: "",
              },
              {
                id: "ed_custname",
                name: "Customer Name",
                class: "",
              },
              {
                id: "ed_country",
                name: "Customer Country",
                class: "",
              },
              {
                id: "ed_hoaddress",
                name: "HO Address",
                class: "",
              },
              {
                id: "ed_telno",
                name: "Tel No",
                class: "",
              },
              {
                id: "ed_webaddress",
                name: "Website Address",
                class: "",
              },
              {
                id: "ed_roi",
                name: "ROI",
                class: "",
              },
            ]}
            highlightKey="ed_enqstatus"
            enableEdit={true}
            enableDelete={true}
            editFunction={(data) =>
              history.push({
                pathname: "/newenquiry",
                data: data,
              })
            }
            deleteFunction={(params) => handleDelete(params.data)}
            data={filteredResult}
          />
        </div>
      </div>
      {showForm && (
        <NewSolution
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          formName={formName}
        />
      )}
    </>
  );
};
export default AllSolutionHoc(AllSolution);
