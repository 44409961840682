import { useState } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import SearchIcon from "../../icons/SearchIcon.svg";
import { RenderIf } from "../../functions/functions";

const GolbalSearch = (props) => {
  const [showGlobal, setShowGlobal] = useState(false);
  const [globalText, setGlobalText] = useState("");

  return (
    <div className={`global-search-box ${showGlobal ? "show" : ""}`}>
      {/* <BiSearch
        size={20}
        color="#264ae4"
        style={{ cursor: "pointer" }}
        onClick={() => setShowGlobal(true)}
      /> */}
      <img
        src={SearchIcon}
        alt="search"
        style={{ cursor: "pointer" }}
        onClick={() => setShowGlobal(true)}
      />
      <input
        type="text"
        placeholder="Search Here"
        value={globalText}
        onChange={(e) => {
          setGlobalText(e.target.value);
          props.filterFunc(e.target.value);
        }}
      />
      <RenderIf isTrue={showGlobal}>
        <BiX
          size={20}
          color="black"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setGlobalText("");
            setShowGlobal(false);
            props.filterFunc("");
          }}
        />
      </RenderIf>
    </div>
  );
};

export default GolbalSearch;
