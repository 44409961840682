import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Alert } from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { BiSelectMultiple } from "react-icons/bi";
import SuccessAlertModal from "../Modals/successAlertModal";
import {
  getmaxcustId,
  getAllCustomers,
  getcustName,
} from "../../Services/customer-service";
import WarningModal from "../Modals/warningModal";
import ConfirmWindow from "../Modals/confirmWindow";
import { getActiveSector } from "../../Services/sector-service";
import {
  AddNewEnquiryCustomer,
  AddNewEnquiryTemp,
  getmaxEnqId,
  getEnquiryId,
  getEnquiryIdByName,
} from "../../Services/enquiry-customer-service";
import {
  validDecimals,
  isAlphanumeric,
  isAlphanumericWithSpecialChars,
} from "../../functions/validations";
import { RenderIf, resetFilter } from "../../functions/functions";
import SingleValuePopUp from "../utility/SingleValuePopUp";
import MultiValuePopUp from "../utility/MultiValuePopUp";
import {
  getDistinctCountry,
  getActiveLocations,
  getLocationComboForUser,
  getDistinctLocation,
  getcountrybylocation,
} from "../../Services/location-service";
import {
  isAlphanumericWithAtRate,
  isAlphanumericWithSpace,
  isNumberWithDecimal,
  checkNull,
  isAlphanumericWithSpaceDash,
  isNumber,
  isAlphanumericWeb,
  isNumbernrange,
  isEmail,
} from "../../functions/validations";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import CompressorAppDetails from "./CompressorAppDetails";
import { setUserName } from "../../redux/redux/global/action";
var setcountr;
var IsDisabled;
const CustomerDetails = (props) => {
  console.log("custoemr details", props.enquirydata);
  const [flag, setFlag] = useState(true);
  const [flagdisableOverseas, setflagdisableOverseas] = useState(false);
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [flagdisableIndia, setflagdisableIndia] = useState(false);
  const [isIndian, setIsIndian] = useState(null);
  const [isOverseas, setIsOverseas] = useState(null);
  const [locationCombo, setLocationCombo] = useState([]);
  const [SectorCombo, setSectorCombo] = useState([]);
  const enquirydata = props.enquirydata;
  const setenquirydata = props.setenquirydata;
  const [showMessage, setShowMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [nameReadOnly, setNameReadOnly] = useState(false);


  const [enqCustObj, setEnqCustObj] = useState({});
  const [msg, setmsg] = useState("");

  const [EnqNum, setEnqNum] = useState("");
  const [Name, setName] = useState("");

  const [edcountry, setedcountry] = useState(null);
  console.log("EnqNum", EnqNum);
  useEffect(() => {
    console.log("enquirydata", enquirydata);
    setEnqCustObj((prevState) => ({
      ed_isnew: (enquirydata?.ed_custname || "") !== "" ? true : false,
      ed_custcode: enquirydata?.ed_custcode || "",
      ed_custname: enquirydata?.ed_custname || "",
      ed_custlocation: enquirydata?.ed_custlocation || "",
      ed_country: enquirydata?.ed_country || "",
      ed_hoaddress: enquirydata?.ed_hoaddress || "",
      ed_telno:
        enquirydata?.ed_telno.toString() === "0"
          ? ""
          : enquirydata?.ed_telno.toString() || "",
      ed_webaddress: enquirydata?.ed_webaddress || "",
      ed_productsmanufactured: enquirydata?.ed_productsmanufactured || "",
      ed_location: enquirydata?.ed_location || 0,
      ed_location_: enquirydata?.ed_location_ || "",
      ed_sector: enquirydata?.ed_sector || "",
      ed_techname: enquirydata?.ed_techname || "",
      ed_techdesignation: enquirydata?.ed_techdesignation || "",
      ed_techcontactno:
        enquirydata?.ed_techcontactno.toString() === "0"
          ? ""
          : enquirydata?.ed_techcontactno.toString() || "",
      ed_techemailid: enquirydata?.ed_techemailid || "",
      ed_dmname: enquirydata?.ed_dmname || "",
      ed_dmdesignation: enquirydata?.ed_dmdesignation || "",
      ed_dmcontactno:
        enquirydata?.ed_dmcontactno.toString() === "0"
          ? ""
          : enquirydata?.ed_dmcontactno.toString() || "",
      ed_dmemailid: enquirydata?.ed_dmemailid || "",
      ed_currencyvalue: enquirydata?.ed_currencyvalue || "",
      ed_workingdays:
        enquirydata?.ed_workingdays.toString() === "0"
          ? ""
          : enquirydata?.ed_workingdays.toString() || "",
      ed_electricityunitrate:
        enquirydata?.ed_electricityunitrate.toString() === "0.00"
          ? ""
          : enquirydata?.ed_electricityunitrate.toString() || "",
      ed_ambienttemp_c:
        enquirydata?.ed_ambienttemp_c.toString() === "0"
          ? ""
          : enquirydata?.ed_ambienttemp_c.toString() || "",
      ed_ambienttemp_f:
        enquirydata?.ed_ambienttemp_f.toString() === "0"
          ? ""
          : enquirydata?.ed_ambienttemp_f.toString() || "",
      ed_status: enquirydata?.ed_status || "",
      ed_enquiryid: enquirydata?.ed_enquiryid || "",
      ed_enquirynumber: enquirydata?.ed_enquirynumber || "",
      ed_insertedby: enquirydata?.ed_insertedby || "",
      ed_status: enquirydata?.ed_status || "",
      ed_tempSave: "",
      ed_custPopup: 0,
      ed_custid: enquirydata?.ed_custcode.replace("CUSID", "") || "",
    }));
    setcountr=enquirydata?.ed_country;
    console.log("enquirydata1", enqCustObj);
    setcountr=enquirydata?.ed_country;
   
    console.log("set country")
    setcountry(enquirydata?.ed_location);
    setEnqNum(enquirydata?.ed_enquirynumber);
    console.log("enqCustObj");
    console.log(enqCustObj);
    setEnqNum(enquirydata?.ed_enquirynumber);
    setIsIndian(
      enquirydata?.ed_custlocation === "India" ||
        enquirydata?.ed_custlocation === "India,Overseas"
        ? true
        : false
    );
    setIsOverseas(enquirydata?.ed_custlocation === "Overseas" ? true : false);
    //getlocbyEnquiry(enquirydata);
    if (enquirydata === undefined) getMaxCustID();
    const scroll = Scrollbar.init(document.querySelector("#customer-details"));
    scroll.track.xAxis.element.remove();
    if (enquirydata === undefined);
    setCountryPopUp(
      "Country Master:" +
      (enquirydata?.ed_custlocation === undefined
        ? "India,Overseas"
        : enquirydata?.ed_custlocation)
    );

    // if (
    //   enquirydata.ed_custlocation == "India,Overseas" ||
    //   enquirydata.ed_custlocation == "Overseas,India"
    // ) {
    //   setIsIndian(true);
    // }

    if (enquirydata?.ed_custlocation.indexOf("Overseas") >= 0) {
      enqCustObj.ed_currencyvalue = "USD";
    } else {
      enqCustObj.ed_currencyvalue = "INR";
    }
    setEnqCustObj((prevState) => ({
      ...prevState,
      ed_currencyvalue: enqCustObj.ed_currencyvalue,
    }));

    //getLocationCombo();
    //getlocbyEnquiry();
    //getloc();
    getSectorCombo();
  }, [flag]);

  const getLocationCombo = async () => {
    let result = await getLocationComboForUser("binal");
    //result.push("");
    //setLocationCombo(result);
  };
  const getSectorCombo = async () => {
    let result = await getActiveSector();
    console.log(result);
    setSectorCombo(result);
  };

  const getlocbyEnquiry = async (e) => {
    console.log(e);

    if (e?.ed_custlocation == "India") {
      //setIsIndian(true);

      let result = await getDistinctLocation(e.ed_custlocation);
      console.log(result);

      setLocationCombo(result);
      setcountr = e.ed_country;
    } else if (e.ed_custlocation == "Overseas") {
      //setIsOverseas(true);
      let result = await getDistinctLocation(e.ed_custlocation);
      console.log(result);

      setLocationCombo(result);
      setcountr = e.ed_country;
    } else if (e.ed_custlocation == "India,Overseas") {
      //setIsIndian(true);
      let result = await getDistinctLocation("India");
      console.log(result);

      setLocationCombo(result);
      setcountr = e.ed_country;
    }

    //result.push("");
  };

  const getloc = async (e) => {
    console.log(e);
    // if (e.cm_loctype == "India") {
    //   let result = await getDistinctLocation(e.cm_loctype);
    //   console.log(result);

    //   setLocationCombo(result);
    // } else if (e.cm_loctype == "Overseas") {
    //   let result = await getDistinctLocation(e.cm_loctype);
    //   console.log(result);

    //   setLocationCombo(result);
    // } else if (e.cm_loctype == "India,Overseas") {
    //   let result = await getDistinctLocation("India");
    //   console.log(result);

    //   setLocationCombo(result);
    // }

    // setLocationCombo("");

    let result = await getDistinctLocation(e.target.value);
    console.log("getDistinctLocation", e.target.value, result);
    if (result.length == 0) {
      setcountr = "";
      console.log(locationCombo);
    }

    setLocationCombo(result);

    //result.push("");
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    setNameReadOnly(true);
    let result = await getcustName(Name);
    console.log(result);
    if (result.length >= 1) {
      //
      //let ans = window.confirm("Customer already exists!");
      //if (ans) {
      console.log(IsDisabled);
      //props.sett(true);
      console.log("in poup freeze", enqCustObj.ed_insertedby);
      if (result[0]?.cm_loctype === "India") {
        setIsIndian(true);
        getloc(result[0]);
        setEnqCustObj((prevState) => ({
          ...prevState,
          ed_currencyvalue: "INR",
        }));
      } else if (result[0]?.cm_loctype === "Overseas") {
        setIsOverseas(true);
        getloc(result[0]);
        setEnqCustObj((prevState) => ({
          ...prevState,
          ed_currencyvalue: "USD",
        }));
      } else if (result[0]?.cm_loctype === "India,Overseas") {
        setIsIndian(true);
        getloc(result[0]);
        setEnqCustObj((prevState) => ({
          ...prevState,
          ed_currencyvalue: "USD",
        }));
      } else if (result[0]?.cm_loctype === "Overseas,India") {
        setIsIndian(true);
        getloc(result[0]);
        setEnqCustObj((prevState) => ({
          ...prevState,
          ed_currencyvalue: "USD",
        }));
      }
      setEnqCustObj((prevState) => ({
        ed_isnew: (result[0]?.cm_custname || "") !== "" ? true : false,
        ed_custcode: result[0]?.cm_custcode || "",
        ed_custname: result[0]?.cm_custname || "",
        ed_custlocation: result[0]?.cm_loctype || "",
        //ed_country: result[0]?.cm_country || "",
        ed_hoaddress: result[0]?.cm_hoaddress || "",
        ed_telno: result[0]?.cm_telno || "",
        ed_webaddress: result[0]?.cm_webaddress || "",
        ed_productsmanufactured: result[0]?.cm_productsmanufactured || "",
        ed_location: result[0]?.cm_location || 0,
        ed_sector: result[0]?.cm_sector || "",
        ed_techname: result[0]?.cm_techname || "",
        ed_techdesignation: result[0]?.cm_techdesignation || "",
        ed_techcontactno: result[0]?.cm_techcontactno || "",
        ed_techemailid: result[0]?.cm_techemailid || "",
        ed_dmname: result[0]?.cm_dmname || "",
        ed_dmdesignation: result[0]?.cm_dmdesignation || "",
        ed_dmcontactno: result[0]?.cm_dmcontactno || "",
        ed_dmemailid: result[0]?.cm_dmemailid || "",
        //ed_currencyvalue: result[0]?.cm_currencyvalue || "",
        ed_workingdays: result[0]?.cm_workingdays || "",
        ed_electricityunitrate: result[0]?.cm_electricityunitrate || "",
        ed_ambienttemp_c: result[0]?.cm_ambienttemp_c || "",
        ed_ambienttemp_f: result[0]?.cm_ambienttemp_f || "",
        ed_status: "" || "",

        ed_tempSave: "",
        ed_custPopup: 0,
        ed_custid: result[0]?.cm_custcode || "",
      }));
    }
  };

  const handleCloseConf = () => {
    enqCustObj.ed_custname = "";
    setShowConfirm(false);
  };

  const setcountry = async (e) => {
    console.log("in country");
    console.log(e);
    console.log("location: ", e);
    //console.log(e.cm_loctype);
    console.log(enqCustObj.cm_loctype);
    enqCustObj.ed_location = e;
    let result = await getcountrybylocation(e);
    console.log(result);
    setcountr = result;
    setEnqCustObj((prevState) => ({
      ...prevState,
      [enqCustObj.ed_country]: result,
    }));
    setedcountry(result);
    enqCustObj.ed_country = result;

    //setLocationCombo(result);

    //result.push("");
  };
  console.log(enqCustObj.ed_country);
  const getMaxCustID = async () => {
    let result = await getmaxcustId();

    // setEnqCustObj((prevState) => ({
    //   ...prevState,
    //   ed_custid: Number(result) + 1,
    //   ed_custcode: "CUSID" + (Number(result) + 1),
    // }));
  };
  useEffect(() => {
    props.sett(IsDisabled);
    props.childFunc.current = handleSubmit;
    props.tempFunc.current = handleTempSubmit;
    props.tabFunc.current = validate;
    //props.setLoader(false);
  }, [enqCustObj]);

  const validatename = async (value) => {
    if (!enqCustObj.ed_isnew) {
      let result = await getcustName(value);

      if (result.length >= 1) {
        alert(
          "Customer entered above is matching with existing customer records. Please check and validate new customer data entered. Click OK to select from existing list or enter different name to create new customer record.  Please choose from Customer list to select existing customer data."
        );

        return false;
      } else {
        return false;
      }
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
    valuecusname: "",
  });
  const [popUpLocation, setPopUpLocation] = useState({ name: "", type: "" });


  const [popUp2, setPopUp2] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });
  const [countryPopUp, setCountryPopUp] = useState(
    "Country Master:" + enqCustObj.ed_custlocation
  );
  // const [isIndian, setIsIndian] = useState(
  //   enqCustObj.ed_custlocation.indexOf("India") >= 0 ? true : false
  // );
  // const [isOverseas, setIsOverseas] = useState(
  //   enqCustObj.ed_custlocation.indexOf("Overseas") >= 0 ? true : false
  // );
  const isnamepresent = async (e) => {
    const { name, value } = e.target;
    setName(value);
    if (!enqCustObj.ed_isnew) {
      let result = await getcustName(value);
      console.log(result);
      if (result.length >= 1) {
        setShowConfirm(true);
        // let ans = window.confirm("Customer already exists!");
        // if (ans) {
        //   console.log(IsDisabled);
        //   //props.sett(true);
        //   console.log("in poup freeze", enqCustObj.ed_insertedby);
        //   if (result[0]?.cm_loctype === "India") {
        //     setIsIndian(true);
        //     getloc(result[0]);
        //     setEnqCustObj((prevState) => ({
        //       ...prevState,
        //       ed_currencyvalue:"INR",
        //     }));
        //   } else if (result[0]?.cm_loctype === "Overseas") {
        //     setIsOverseas(true);
        //     getloc(result[0]);
        //     setEnqCustObj((prevState) => ({
        //       ...prevState,
        //       ed_currencyvalue: "USD",
        //     }));
        //   } else if (result[0]?.cm_loctype === "India,Overseas") {
        //     setIsIndian(true);
        //     getloc(result[0]);
        //     setEnqCustObj((prevState) => ({
        //       ...prevState,
        //       ed_currencyvalue: "USD",
        //     }));
        //   } else if (result[0]?.cm_loctype === "Overseas,India") {
        //     setIsIndian(true);
        //     getloc(result[0]);
        //     setEnqCustObj((prevState) => ({
        //       ...prevState,
        //       ed_currencyvalue: "USD",
        //     }));
        //   }
        //   setEnqCustObj((prevState) => ({
        //     ed_isnew: (result[0]?.cm_custname || "") !== "" ? true : false,
        //     ed_custcode: result[0]?.cm_custcode || "",
        //     ed_custname: result[0]?.cm_custname || "",
        //     ed_custlocation: result[0]?.cm_loctype || "",
        //     //ed_country: result[0]?.cm_country || "",
        //     ed_hoaddress: result[0]?.cm_hoaddress || "",
        //     ed_telno: result[0]?.cm_telno || "",
        //     ed_webaddress: result[0]?.cm_webaddress || "",
        //     ed_productsmanufactured: result[0]?.cm_productsmanufactured || "",
        //     ed_location: result[0]?.cm_location || "",
        //     ed_sector: result[0]?.cm_sector || "",
        //     ed_techname: result[0]?.cm_techname || "",
        //     ed_techdesignation: result[0]?.cm_techdesignation || "",
        //     ed_techcontactno: result[0]?.cm_techcontactno || "",
        //     ed_techemailid: result[0]?.cm_techemailid || "",
        //     ed_dmname: result[0]?.cm_dmname || "",
        //     ed_dmdesignation: result[0]?.cm_dmdesignation || "",
        //     ed_dmcontactno: result[0]?.cm_dmcontactno || "",
        //     ed_dmemailid: result[0]?.cm_dmemailid || "",
        //     //ed_currencyvalue: result[0]?.cm_currencyvalue || "",
        //     ed_workingdays: result[0]?.cm_workingdays || "",
        //     ed_electricityunitrate: result[0]?.cm_electricityunitrate || "",
        //     ed_ambienttemp_c: result[0]?.cm_ambienttemp_c || "",
        //     ed_ambienttemp_f: result[0]?.cm_ambienttemp_f || "",
        //     ed_status: result[0]?.cm_status || "",

        //     ed_tempSave: "",
        //     ed_custPopup: 0,
        //     ed_custid: result[0]?.cm_custcode || "",
        //   }));
        // } else {
        //   console.log("hi");
        //   enqCustObj.ed_custname = "";
        //   e.target.value = "";
        // }
        // alert(
        //   "Customer entered above is matching with existing customer records. Please check and validate new customer data entered. Click OK to select from existing list or enter different name to create new customer record.  Please choose from Customer list to select existing customer data."
        // );
      } else {
        return { status: true, message: "New Customer" };
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnqCustObj((prevState) => ({
      ...prevState,
      [name]: value,

    }));
    if (name === "ed_custname") {
      setEnqCustObj((prevState) => ({
        ...prevState,
        ["ed_custcode"]: "",
      }));
    }
  };

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    let arr = [];
    if (document.CustomerDetails.ed_custlocation[0].checked === true) {
      arr.push("India");
      setIsIndian(true);
    } else {
      if (arr.indexOf("India") > -1) arr.splice(arr.indexOf("India"));
      setIsIndian(false);
    }
    if (document.CustomerDetails.ed_custlocation[1].checked === true) {
      arr.push("Overseas");
      setIsOverseas(true);
    } else {
      if (arr.indexOf("Overseas") > -1) arr.splice(arr.indexOf("Overseas"));
      setIsOverseas(false);
    }
    setEnqCustObj((prevState) => ({
      ...prevState,
      ed_custlocation: arr.toString(),
      ed_currencyvalue: arr.indexOf("Overseas") > -1 ? "USD" : "INR",
    }));
    if (arr.length === 0) arr = ["India", "Overseas"];
    setCountryPopUp("Country Master:" + arr);
    setEnqCustObj((prevState) => ({
      ...prevState,
      ed_country: "",
    }));
  };

  // const handleCheckboxes = (e) => {
  //   const { name } = e.target;

  //   let checkArr = [];
  //   if (document.CustomerDetails.ed_custlocation[0].checked) {
  //     checkArr.push(document.CustomerDetails.ed_custlocation[0].value);
  //     setIsIndian(true);
  //     enqCustObj.ed_currencyvalue = "INR";
  //   } else setIsIndian(false);

  //   if (document.CustomerDetails.ed_custlocation[1].checked) {
  //     checkArr.push(document.CustomerDetails.ed_custlocation[1].value);
  //     setIsOverseas(true);
  //     enqCustObj.ed_currencyvalue = "USD";
  //   } else {
  //     setIsOverseas(false);
  //   }
  //   console.log(checkArr);
  //   setCountryPopUp("Country Master:" + checkArr);
  //   let loctype = checkArr.toString();
  //   console.log(loctype);

  //   setEnqCustObj((prevState) => ({
  //     ...prevState,
  //     [name]: loctype,
  //     ed_country: "",
  //   }));
  // };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    isnamepresent(e);
  };

  const handleLocChange = (e) => {
    const { name, value } = e.target;
    if (value === "India") {
      enqCustObj.ed_currencyvalue = "INR";
    } else if (value === "Overseas") {
      enqCustObj.ed_currencyvalue = "USD";
    }
    setEnqCustObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeTemp = (e) => {
    console.log("location", enqCustObj.ed_location);
    const { name, value } = e.target;
    let farvalue = 32 + (9 * value) / 5;
    enqCustObj.ed_ambienttemp_f = farvalue;
    setEnqCustObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  let isError = false;
  function validate() {
    if (
      !enqCustObj.ed_telno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
      !enqCustObj.ed_telno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
    ) {
      isError = true;
      error.ed_telno = {
        status: true,
        message: "Add valid contact number (eg: +91 8888888888)",
      };
    }

    if (
      !enqCustObj.ed_techcontactno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
      !enqCustObj.ed_techcontactno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
    ) {
      isError = true;
      error.ed_techcontactno = {
        status: true,
        message: "Add valid contact number (eg: +91 8888888888)",
      };
    }

    if (
      !enqCustObj.ed_dmcontactno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
      !enqCustObj.ed_dmcontactno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
    ) {
      isError = true;
      error.ed_dmcontactno = {
        status: true,
        message: "Add valid contact number (eg: +91 8888888888)",
      };
    }

    if (enqCustObj.ed_custname === "") {
      isError = true;
      error.ed_custname = {
        status: true,
        message: "Please enter Customer Name",
      };
    }
    if (enqCustObj.ed_custlocation === "") {
      isError = true;
      error.ed_custlocation = {
        status: true,
        message: "Please enter Customer Location",
      };
      // error.setcountr = {
      //   status: true,
      //   message: "Please enter Country",
      // };
    }

    if (enqCustObj.ed_location === 0 || enqCustObj.ed_location === undefined || enqCustObj.ed_location === "") {
      isError = true;
      error.ed_location = {
        status: true,
        message: "Please enter Location",
      };
    }
    console.log(setcountr);
    // if (enqCustObj.setcountr === "") {
    //   // enqCustObj.ed_country = setcountr;
    //   isError = true;
    //   error.ed_country = {
    //     status: true,
    //     message: "Please enter Country",
    //   };
    // }
    if (enqCustObj.ed_hoaddress === "") {
      isError = true;
      error.ed_hoaddress = {
        status: true,
        message: "Please enter Address",
      };
    }
    if (enqCustObj.ed_telno === "") {
      isError = true;
      error.ed_telno = {
        status: true,
        message: "Please enter Telephone Number",
      };
    }
    if (enqCustObj.ed_webaddress === "") {
      isError = true;
      error.ed_webaddress = {
        status: true,
        message: "Please enter Web Address",
      };
    }
    if (enqCustObj.ed_productsmanufactured === "") {
      isError = true;
      error.ed_productsmanufactured = {
        status: true,
        message: "Please enter Products Manufactured",
      };
    }


    if (enqCustObj.ed_sector === "") {
      isError = true;
      error.ed_sector = {
        status: true,
        message: "Please enter Sector",
      };
    }
    if (enqCustObj.ed_techname === "") {
      isError = true;
      error.ed_techname = {
        status: true,
        message: "Please enter Technician Name",
      };
    }
    if (enqCustObj.ed_techdesignation === "") {
      isError = true;
      error.ed_techdesignation = {
        status: true,
        message: "Please enter Technician Designation",
      };
    }
    if (enqCustObj.ed_techcontactno === "") {
      isError = true;
      error.ed_techcontactno = {
        status: true,
        message: "Please enter Technician Contact Number",
      };
    }
    if (enqCustObj.ed_techemailid === "") {
      isError = true;
      error.ed_techemailid = {
        status: true,
        message: "Please enter Technician Email Id",
      };
    }

    if (
      !enqCustObj.ed_techemailid.match(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      )
    ) {
      isError = true;
      error.ed_techemailid = {
        status: true,
        message: "Please enter vaild Email Id",
      };
    }

    if (enqCustObj.ed_dmname === "") {
      isError = true;
      error.ed_dmname = {
        status: true,
        message: "Please enter DM Name",
      };
    }
    if (enqCustObj.ed_dmdesignation === "") {
      isError = true;
      error.ed_dmdesignation = {
        status: true,
        message: "Please enter DM Designation",
      };
    }
    if (enqCustObj.ed_dmcontactno === "") {
      isError = true;
      error.ed_dmcontactno = {
        status: true,
        message: "Please enter DM Contact Number",
      };
    }
    if (enqCustObj.ed_dmemailid === "") {
      isError = true;
      error.ed_dmemailid = {
        status: true,
        message: "Please enter DM Email Id",
      };
    }
    if (
      !enqCustObj.ed_dmemailid.match(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      )
    ) {
      isError = true;
      error.ed_dmemailid = {
        status: true,
        message: "Please enter valid Email Id",
      };
    }
    if (enqCustObj.ed_workingdays === ""
      //|| enqCustObj.ed_workingdays===0 
    ) {
      isError = true;
      error.ed_workingdays = {
        status: true,
        message: "Please enter Working Days",
      };
    }
    if (enqCustObj.ed_electricityunitrate === ""
      //|| enqCustObj.ed_electricityunitrate === 0.00
    ) {
      isError = true;
      error.ed_electricityunitrate = {
        status: true,
        message: "Please enter Electricity Unit Rate",
      };
    }
    if (enqCustObj.ed_ambienttemp_c === ""
      //|| enqCustObj.ed_ambienttemp_c===0
    ) {
      isError = true;
      error.ed_ambienttemp_c = {
        status: true,
        message: "Please enter Ambient Temperature",
      };
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    if (
      error.ed_custname.status ||
      error.ed_custlocation.status ||
      error.ed_country.status ||
      error.ed_hoaddress.status ||
      error.ed_telno.status ||
      error.ed_webaddress.status ||
      error.ed_productsmanufactured.status ||
      error.ed_location.status ||
      error.ed_sector.status ||
      error.ed_techname.status ||
      error.ed_techdesignation.status ||
      error.ed_techcontactno.status ||
      error.ed_techemailid.status ||
      error.ed_dmname.status ||
      error.ed_dmdesignation.status ||
      error.ed_dmcontactno.status ||
      error.ed_dmemailid.status ||
      error.ed_workingdays.status ||
      error.ed_electricityunitrate.status ||
      error.ed_ambienttemp_c.status
    ) {
      isError = true;
    }
    // if (isError == true) {
    //   props.setLoader(false);
    // }
    return isError;

  }

  function tempValidate() {
    //let isError = false;

    if (enqCustObj.ed_telno == 0) {
      enqCustObj.ed_telno = "";
    }
    if (enqCustObj.ed_techcontactno == 0) {
      enqCustObj.ed_techcontactno = "";
    }
    if (enqCustObj.ed_workingdays == 0) {
      enqCustObj.ed_workingdays = "";
    }
    if (enqCustObj.ed_ambienttemp_c == 0) {
      enqCustObj.ed_ambienttemp_c = "";
    }

    if (enqCustObj.ed_electricityunitrate == 0.0) {
      enqCustObj.ed_electricityunitrate = "";
    }
    if (enqCustObj.ed_techcontactno == 0) {
      enqCustObj.ed_techcontactno = "";
    }
    if (enqCustObj.ed_dmcontactno == 0) {
      enqCustObj.ed_dmcontactno = "";
    }

    if (enqCustObj.ed_telno !== "") {
      if (
        !enqCustObj.ed_telno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
        !enqCustObj.ed_telno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
      ) {
        isError = true;
        error.ed_telno = {
          status: true,
          message: "Add valid contact number (eg: +91 8888888888) ",
        };
      }
    }

    if (enqCustObj.ed_techcontactno !== "") {
      if (
        !enqCustObj.ed_techcontactno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
        !enqCustObj.ed_techcontactno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
      ) {
        isError = true;
        error.ed_techcontactno = {
          status: true,
          message: "Add valid contact number (eg: +91 8888888888)",
        };
      }
    }

    if (enqCustObj.ed_dmcontactno !== "") {
      if (
        !enqCustObj.ed_dmcontactno.match(/^(\+)[0-9]{2}( |-)[0-9]{7,16}$/) &
        !enqCustObj.ed_dmcontactno.match(/^(\+)[0-9]{3}( |-)[0-9]{7,16}$/)
      ) {
        isError = true;
        error.ed_dmcontactno = {
          status: true,
          message: "Add valid contact number (eg: +91 8888888888)",
        };
      }
    }

    if (enqCustObj.ed_techemailid !== "") {
      if (
        !enqCustObj.ed_techemailid.match(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        )
      ) {
        isError = true;
        error.ed_techemailid = {
          status: true,
          message: "Please enter vaild Email Id",
        };
      }
    }

    if (enqCustObj.ed_dmemailid !== "") {
      if (
        !enqCustObj.ed_dmemailid.match(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        )
      ) {
        isError = true;
        error.ed_dmemailid = {
          status: true,
          message: "Please enter valid Email Id",
        };
      }
    }

    if (enqCustObj.ed_custname === "") {
      isError = true;
      setShowMessage("Please enter Customer name");
      setShow(true);
      // error.ed_custname = {
      //   status: true,
      //   message: "Please enter Customer name",
      // };
    }

    // if (enqCustObj.ed_custlocation === "") {
    //   isError = true;
    //   error.ed_custlocation = {
    //     status: true,
    //     message: "Please enter Customer Location",
    //   };
    //   // error.setcountr = {
    //   //   status: true,
    //   //   message: "Please enter Country",
    //   // };
    // }
    // if (enqCustObj.ed_location === 0 || enqCustObj.ed_location === undefined || enqCustObj.ed_location === "") {
    //   isError = true;
    //   error.ed_location = {
    //     status: true,
    //     message: "Please enter Location",
    //   };
    // }
    //  if (enqCustObj.ed_custname === "" && enqCustObj.ed_custlocation === "" && enqCustObj.ed_country === "" && enqCustObj.ed_hoaddress === "" && enqCustObj.ed_telno === "" && enqCustObj.ed_webaddress === "" && enqCustObj.ed_productsmanufactured === "" && enqCustObj.ed_location === "" && enqCustObj.ed_sector === "" && enqCustObj.ed_workingdays === "" && enqCustObj.ed_electricityunitrate === "" && enqCustObj.ed_ambienttemp_c === "") {
    //   isError = true;
    //   setShowMessage("Please enter Customer details")
    //   setShow(true);
    // }

    // if (enqCustObj.ed_custname === "" && enqCustObj.ed_custlocation === "") {
    //   error.ed_custname = {
    //     status: false,
    //     message: "",
    //   };
    // }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    if (
      error.ed_custname.status ||
      error.ed_country.status ||
      error.ed_hoaddress.status ||
      error.ed_telno.status ||
      error.ed_webaddress.status ||
      error.ed_productsmanufactured.status ||
      error.ed_location.status ||
      error.ed_sector.status ||
      error.ed_techname.status ||
      error.ed_techdesignation.status ||
      error.ed_techcontactno.status ||
      error.ed_techemailid.status ||
      error.ed_dmname.status ||
      error.ed_dmdesignation.status ||
      error.ed_dmcontactno.status ||
      error.ed_dmemailid.status ||
      error.ed_workingdays.status ||
      error.ed_electricityunitrate.status ||
      error.ed_ambienttemp_c.status
    ) {
      isError = true;
    }
    return isError;
  }

  const handleTempSubmit = async (e) => {

    // let result1 = await getEnquiryIdByName(enqCustObj.ed_custname);
    // console.log(result1);
    // if (result1.length > 0) {
    //   enqCustObj.ed_enquiryid = result1[0].ed_enquiryid;
    // }
    isError = false;

    error.ed_custname.status = false;
    error.ed_country.status = false;
    error.ed_hoaddress.status = false;
    error.ed_telno.status = false;
    error.ed_webaddress.status = false;
    error.ed_productsmanufactured.status = false;
    error.ed_location.status = false;
    error.ed_sector.status = false;
    error.ed_techname.status = false;
    error.ed_techdesignation.status = false;
    error.ed_techcontactno.status = false;
    error.ed_techemailid.status = false;
    error.ed_dmname.status = false;
    error.ed_dmdesignation.status = false;
    error.ed_dmcontactno.status = false;
    error.ed_dmemailid.status = false;
    error.ed_workingdays.status = false;
    error.ed_electricityunitrate.status = false;
    error.ed_ambienttemp_c.status = false;
    error.ed_custlocation = false;

    console.log(Error);
    enqCustObj.ed_tempSave = 1;

    enqCustObj.ed_country = setcountr;

    let result;
    if (!tempValidate()) {
      if (enqCustObj.ed_custPopup == 1) {
        // alert(enqCustObj);
        result = AddNewEnquiryTemp(enqCustObj);
      } else {
        //  enqCustObj.ed_custcode = ;
        console.log(enqCustObj);
        result = AddNewEnquiryTemp(enqCustObj);
      }

      // result = AddNewEnquiryTemp(enqCustObj);

      result
        .then((resp) => {
          console.log("weirdmsg", resp.message);
          //alert(resp.status)
          if (resp.message.includes("must be")) {
            let s = resp.message;
            s = s.split('"')[1];
            console.log("In Weird Pattern", s);
            setEnqCustObj((prevState) => ({
              ...prevState,
              [s]: "",
            }));
            setShowMessage("Special Characters not allowed!");
            setShow(true);
          } else {
            if (resp.satus) {
              //alert(resp.message);

              setmsg(resp.message);

              enqCustObj.ed_enquiryid = resp.data[1];
              enqCustObj.ed_enquirynumber = resp.data[0];
              localStorage.setItem("enqkey", resp.data[1]);
              setShowSuccess(true);
              props.setEnquirynum(enqCustObj.ed_enquirynumber);
            } else {
              setmsg(resp.message);
              if (resp?.data[1]) {
                enqCustObj.ed_enquiryid = resp.data[1];
                enqCustObj.ed_enquirynumber = resp.data[0];
              }
              setShowSuccess(true);
              props.setEnquirynum(enqCustObj.ed_enquirynumber);
              localStorage.setItem("enqkey", resp.data[1]);
              // window.location = "/allsolution";
            }
          }
         // props.setLoader(false);
        })
        .catch((error) => {
        console.log("Unable to process request");
        // props.setLoader(false);
        isError = true;
        setShowMessage("Error in adding data or invalid data.");
        setShow(true);
        });
    }
  };
  const handleCheckboxes = (e) => {
    const { name, value } = e.target;

    let checkArr = [];
    if (document.CustomerDetails.cm_loctype[0].checked) {
      checkArr.push(document.CustomerDetails.cm_loctype[0].value);
      setIsIndian(true);
    } else setIsIndian(false);

    if (document.CustomerDetails.cm_loctype[1].checked) {
      checkArr.push(document.CustomerDetails.cm_loctype[1].value);
      setIsOverseas(true);
    } else {
      setIsOverseas(false);
    }
    setCountryPopUp("Country Master:" + checkArr);

    enqCustObj((prevState) => ({
      ...prevState,
      [name]: checkArr.toString(),
      ed_country: "",
    }));
  };
  const handleSubmit = (e) => {
    enqCustObj.ed_tempSave = 0;
    enqCustObj.ed_country = setcountr;
    if (enqCustObj.ed_custlocation === "India") {
      enqCustObj.ed_currencyvalue = "INR";
    } else {
      enqCustObj.ed_currencyvalue = "USD";
    }
    if (!validate()) {
      AddNewEnquiryCustomer(enqCustObj)
        .then((resp) => {
          console.log("weirdmsg", resp.data.message);
          //alert(resp.status)
          if (resp.data.message.includes("must be")) {
            let s = resp.data.message;
            s = s.split('"')[1];
            console.log("In Weird Pattern", s);
            setEnqCustObj((prevState) => ({
              ...prevState,
              [s]: "",
            }));
            setShowMessage("Special Characters not allowed!");
            setShow(true);
           // props.setLoader(false);
          } else {
            if (resp.status) {
              //   enqCustObj.ed_enquirynumber = resp.data.data[0];
              //   enqCustObj.ed_enquiryid = resp.data.data[1];
              //   localStorage.setItem("enqkey", resp.data.data[1]);
              //   setmsg(resp.data.message);
              //   props.setEnquirynum(enqCustObj.ed_enquirynumber);
              //   setShowSuccess(true);
              //   props.setGoToNext(true);
              // } else {
              //alert(resp.message);
              if (
                enqCustObj.ed_enquiryid == "" ||
                enqCustObj.ed_enquiryid == "undefined"
              ) {
                localStorage.setItem("enqkey", resp.data.data[1]);
                console.log("new custo data", resp.data, resp.data.data[2]);
                enqCustObj.ed_enquiryid = resp.data.data[1];
                enqCustObj.ed_enquirynumber = resp.data.data[0];
                enqCustObj.ed_custcode = resp.data.data[2];

                setEnqNum(resp.data.data);
                props.setEnquirynum(enqCustObj.ed_enquirynumber);
              }
              setmsg(resp.data.message);
              props.setEnquirynum(enqCustObj.ed_enquirynumber);

              setShowSuccess(true);
              props.setGoToNext(true);
             // props.setLoader(false);
              //props.sett(IsDisabled);
            }
          }
         // props.setLoader(false);
        })
        .catch((error) => {
        console.log("Unable to process request:" + error);
         // props.setLoader(false);
        isError = true;
        setShowMessage("Error in adding data or invalid data.");
        setShow(true);    
            
        });
    }
  };

  const showPopUpLocation = (name, type) => {
    console.log(name, type);
    setPopUpLocation({ name, type });
    setShowForm(true);
  };
  const showPopUp = (name, type, key, value, valuecusname) => {
    console.log("enqCustObj.ed_custlocation", enqCustObj.ed_custlocation);
    console.log("showpopup", name, type, key, value, valuecusname, enqCustObj.ed_custlocation)

    let arr = name.split(":");


    if (arr[0] === "Location") {
      if (!enqCustObj?.ed_custlocation) {
        isError = true;
        error.ed_custlocation = {
          status: true,
          message: "Please select Customer Location type",
        };
      }
      else {
        isError = false;
        setPopUp({ name, type, key, value, valuecusname });
        setShowForm(true);
      }
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      return isError;
    }
    if (name === "Customer") {
      setPopUp({ name, type, key, value, valuecusname });
      setShowForm(true);
    }
  };
  const handleClose = async () => {
    if (msg) {
      setShowSuccess(false);
    }
    let result = await getEnquiryId(EnqNum);
    console.log("ENQID IS:", result);
    if (result.length > 0) {
      setEnqCustObj((prevState) => ({
        ed_isnew: (result[0]?.ed_custname || "") !== "" ? true : false,
        ed_custcode: result[0].ed_custcode,
        ed_custname: result[0].ed_custname,
        ed_custlocation: result[0].ed_custlocation,
        setcountr: result[0].ed_country,
        ed_hoaddress: result[0].ed_hoaddress,
        ed_telno: result[0].ed_telno,
        ed_webaddress: result[0].ed_webaddress,
        ed_productsmanufactured: result[0].ed_productsmanufactured,
        ed_location: result[0].ed_location,
        //ed_location_:result[0].ed_location_,
        ed_sector: result[0].ed_sector,
        ed_techname: result[0].ed_techname,
        ed_techdesignation: result[0].ed_techdesignation,
        ed_techcontactno: result[0].ed_techcontactno,
        ed_techemailid: result[0].ed_techemailid,
        ed_dmname: result[0].ed_dmname,
        ed_dmdesignation: result[0].ed_dmdesignation,
        ed_dmcontactno: result[0].ed_dmcontactno,
        ed_dmemailid: result[0].ed_dmemailid,
        ed_currencyvalue: result[0].ed_currencyvalue,
        ed_workingdays: result[0].ed_workingdays.toString(),
        ed_electricityunitrate: result[0].ed_electricityunitrate.toString() === "0.00" ? "" : result[0].ed_electricityunitrate.toString(),
        ed_ambienttemp_c: result[0].ed_ambienttemp_c.toString() === "0" ? "" : result[0].ed_ambienttemp_c.toString(),
        ed_ambienttemp_f: result[0].ed_ambienttemp_f.toString(),
        ed_status: result[0].ed_status,
        ed_enquiryid: result[0].ed_enquiryid,
        ed_enquirynumber: result[0].ed_enquirynumber,
        ed_tempSave: "",
        ed_custPopup: 0,
        //ed_custid: result[0].ed_custcode,
      }));
    }
    setShowSuccess(false);
  };

  const setPopUpData = (data) => {
    console.log("setPopUpData", data);
    if (data.name === "Customer") {
      //console.log(data.result.length);
      //enqCustObj.ed_custcode = data.result.cm_custcode;
      getloc(data.result);

      setEnqCustObj((prevState) => ({
        ...prevState,
        ed_isnew: true,
        ed_custcode: data.result.cm_custcode,
        ed_custname: data.result.cm_custname,
        ed_custlocation: data.result.cm_loctype,
        //ed_country: data.result.cm_country,
        ed_hoaddress: data.result.cm_hoaddress,
        ed_telno: data.result.cm_telno,
        ed_webaddress: data.result.cm_webaddress,
        ed_productsmanufactured: data.result.cm_productsmanufactured,
        //ed_location: data.result.cm_location,
        ed_sector: data.result.cm_sector,
        ed_techname: data.result.cm_techname,
        ed_techdesignation: data.result.cm_techdesignation,
        ed_techcontactno: data.result.cm_techcontactno,
        ed_techemailid: data.result.cm_techemailid,
        ed_dmname: data.result.cm_dmname,
        ed_dmdesignation: data.result.cm_dmdesignation,
        ed_dmcontactno: data.result.cm_dmcontactno,
        ed_dmemailid: data.result.cm_dmemailid,
      }));
      setCountryPopUp("Country Master:" + data.result.cm_loctype);
      setNameReadOnly(true);
      setIsIndian(
        data.result.cm_loctype.indexOf("India") >= 0 ||
          (data.result.cm_loctype.indexOf("India") >= 0 &&
            data.result.cm_loctype.indexOf("Overseas") >= 0)
          ? true
          : false
      );

      // setIsOverseas(
      //   data.result.cm_loctype.indexOf("Overseas") >= 0 ? true : false
      // );
      setIsOverseas(
        (data.result.cm_loctype.indexOf("India") >= 0 &&
          data.result.cm_loctype.indexOf("Overseas") >= 0) ||
          data.result.cm_loctype.indexOf("India") >= 0
          ? false
          : true
      );

      setflagdisableOverseas(
        data.result.cm_loctype.indexOf("India") >= 0 &&
          data.result.cm_loctype.indexOf("Overseas") < 0
          ? true
          : false
      );
      setflagdisableIndia(
        data.result.cm_loctype.indexOf("India") < 0 &&
          data.result.cm_loctype.indexOf("Overseas") >= 0
          ? true
          : false
      );

      if (data.result.cm_loctype.indexOf("Overseas") >= 0) {
        enqCustObj.ed_currencyvalue = "USD";
      } else {
        enqCustObj.ed_currencyvalue = "INR";
      }
      setEnqCustObj((prevState) => ({
        ...prevState,
        ed_currencyvalue: enqCustObj.ed_currencyvalue,
      }));

      let error = checkNull(enqCustObj.ed_custname);
      if (error.status) {
        setError({
          ed_custname: {},
          ed_custlocation: {},
          ed_country: {},
          ed_hoaddress: {},
          ed_telno: {},
          ed_webaddress: {},
          ed_productsmanufactured: {},
          ed_location: {},
          ed_sector: {},
          ed_techname: {},
          ed_techdesignation: {},
          ed_techcontactno: {},
          ed_techemailid: {},
          ed_dmname: {},
          ed_dmdesignation: {},
          ed_dmcontactno: {},
          ed_dmemailid: {},
          ed_workingdays: {},
          ed_electricityunitrate: {},
          ed_ambienttemp_c: {},
        });
      }
    }
    else if (data.name.includes("Location")) {
      setEnqCustObj((prevState) => ({
        ...prevState,
        ed_location: data.result.lm_loccode,
        ed_location_: data.result.lm_loc,
        ed_country: data.result.lm_country
      }));
      setcountr=data.result.lm_country;
      let error = checkNull(data.result.lm_loccode);
      setError((prevState) => ({
        ...prevState,
        ed_location: error,
      }));
    }
    let countryArr = [];
    if (data.name === countryPopUp) {
      for (let i = 0; i < data.result.length; i++) {
        countryArr.push(data.result[i].lm_country);
      }
      setEnqCustObj((prevState) => ({
        ...prevState,
        ed_country: countryArr.toString(),
      }));
      let error = checkNull(countryArr.toString());
      setError((prevState) => ({
        ...prevState,
        ed_country: error,
      }));
    }

    enqCustObj.ed_custPopup = 1;
  };

  const [error, setError] = useState({
    ed_custname: {},
    ed_custlocation: {},
    ed_country: {},
    ed_hoaddress: {},
    ed_telno: {},
    ed_webaddress: {},
    ed_productsmanufactured: {},
    ed_location: {},
    ed_sector: {},
    ed_techname: {},
    ed_techdesignation: {},
    ed_techcontactno: {},
    ed_techemailid: {},
    ed_dmname: {},
    ed_dmdesignation: {},
    ed_dmcontactno: {},
    ed_dmemailid: {},
    ed_workingdays: {},
    ed_electricityunitrate: {},
    ed_ambienttemp_c: {},
  });
  console.log(enqCustObj.ed_insertedby, "bhjkliuytresdfghtg", props.userId);
  if (
    enqCustObj.ed_status === "In-Process" ||
    enqCustObj.ed_status === "Closed"
  ) {
    IsDisabled = false;
  } else {
    if (
      enqCustObj.ed_insertedby === props.userId ||
      enqCustObj.ed_insertedby == "" ||
      enqCustObj.ed_insertedby == " " ||
      enqCustObj.ed_insertedby == undefined
    ) {
      IsDisabled = true;
    } else {
      IsDisabled = false;
    }
  }

  localStorage.setItem("enqkey", enqCustObj.ed_enquiryid);

  return (
    <>
      <fieldset
        id="CustomerDetails"
        disabled={IsDisabled === false ? true : false}
      >
        <WarningModal
          message={showMessage}
          show={show}
          handleClose={() => {
            setShow(false);
          }}
        />
        <ConfirmWindow
          handleYes={handleConfirm}
          //formName={formName}
          show={showConfirm}
          handleClose={handleCloseConf}
        />
        <SuccessAlertModal
          //clickFunction={modalOnClick}
          msg={msg}
          show={ShowSuccess}
          handleClose={handleClose}
        />
        <Form
          id="customer-details"
          name="CustomerDetails"
          className="newEnquiry"
          autoComplete="off"
        >
          {/* <label style={{ fontWeight: "600", marginLeft: "4px" }}>
          Customer Details
        </label> */}
          <div>
            <span className="req-input">All fields are mandatory</span>
          </div>

          <div className="px-2">
            <Row className="gx-3">
              <Col md={3} sm={12} className="mt-3">
                <Form.Label>Customer Code</Form.Label>

                <Form.Control
                  type="text"
                  name="ed_custcode"
                  //value={cm_maxCustId}
                  // //value={!enqCustObj.ed_isnew ? "" : enqCustObj.ed_custcode}
                  value={enqCustObj.ed_custcode}
                  onChange={handleChange}
                  readOnly
                  style={{ backgroundColor: "#ffffff" }}
                  className="readonly-fields"
                />
              </Col>
              <Col md={9} sm={12} className="mt-3">
                <Form.Label>Customer Name</Form.Label>
                {/* <Form.Control
                type="text"
                name="ed_custname"
                placeholder="Enter Customer Name"
                value={enqCustObj.ed_custname}
                onChange={handleChange}
                maxLength="100"
                onInput={(e) => {
                  let error = isAlphanumericWithSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    ed_custname: error,
                  }));
                }}
              /> */}
                <div className="d-flex align-items-center">
                  <Form.Control
                    required
                    //readOnly={enqCustObj.ed_isnew?true:false}
                    name="ed_custname"
                    type="text"
                    placeholder="Enter or select Customer Name"
                    value={enqCustObj.ed_custname}
                    disabled={!nameReadOnly ? false : true}
                    onChange={handleChange}
                    onBlur={handleNameChange}
                    onInput={(e) => {
                      //let error = isAlphanumericWithSpace(e.target.value);
                      let error = isAlphanumericWithSpecialChars(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        ed_custname: error,
                      }));
                    }}
                    className={
                      error.ed_custname.status
                        ? "placeHolderInput inputText text-field-with-error"
                        : "placeHolderInput inputText"
                    }
                  />
                  <BiSelectMultiple
                    size={20}
                    onClick={() =>
                      IsDisabled == true
                        ? showPopUp(
                          "Customer",
                          "Single",
                          "cm_custcode",
                          enqCustObj.ed_custcode
                        )
                        : showPopUp()
                    }
                    color={"gray"}
                    style={{ marginLeft: "-2rem" }}
                  />
                </div>
                {error.ed_custname.status && (
                  <span className="req-input">{error.ed_custname.message}</span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={12} sm={12} className="mt-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  disabled={IsDisabled === false ? true : false}
                  type="text"
                  name="ed_hoaddress"
                  placeholder="Enter Address"
                  value={enqCustObj.ed_hoaddress}
                  onChange={handleChange}
                  maxLength="200"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpecialChars(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_hoaddress: error,
                    }));
                  }}
                  className={
                    error.ed_hoaddress.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_hoaddress.status && (
                  <span className="req-input">
                    {error.ed_hoaddress.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Official Contact No (Add Country Code)</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_telno"
                  placeholder="+91 8888888888"
                  value={enqCustObj.ed_telno}
                  onChange={handleChange}
                  maxLength="20"
                  onInput={(e) => {
                    let error = isNumber(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_telno: error,
                    }));
                  }}
                  className={
                    error.ed_telno.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_telno.status && (
                  <span className="req-input">{error.ed_telno.message}</span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Website Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Website Address"
                  name="ed_webaddress"
                  value={enqCustObj.ed_webaddress}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWeb(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      cm_webaddress: error,
                    }));

                    let error2 = isAlphanumericWeb(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_webaddress: error,
                    }));
                  }}
                  // onInput={(e) => {
                  //   let error = isAlphanumericWithAtRate(e.target.value);
                  //   setError((prevState) => ({
                  //     ...prevState,
                  //     ed_webaddress: error,
                  //   }));
                  // }}
                  className={
                    error.ed_webaddress.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_webaddress.status && (
                  <span className="req-input">
                    {error.ed_webaddress.message}
                  </span>
                )}
              </Col>

              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Sector</Form.Label>
                <Form.Select
                  name="ed_sector"
                  value={enqCustObj.ed_sector}
                  onChange={(e) => {
                    handleChange(e);

                    let error = checkNull(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_sector: error,
                    }));
                  }}
                  className={
                    error.ed_sector.status
                      ? "placeHolderInput inputText text-field-with-error form-select-ba"
                      : "placeHolderInput inputText form-select-ba"
                  }
                >
                  <option value="">Select Sector</option>

                  {SectorCombo?.map((SectorCombo) => (
                    <option key={SectorCombo} value={SectorCombo}>
                      {SectorCombo}
                    </option>
                  ))}
                </Form.Select>
                {error.ed_sector.status && (
                  <span className="req-input">{error.ed_sector.message}</span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={3} sm={12} className="mt-3">
                <div className="d-flex flex-column h-100">
                  <Form.Label>Location Type</Form.Label>

                  <div className="container radioContainer">
                    <div className="row">
                      <div className="col content-align">
                        {/* <Form.Check
                  type="radio"
                  label="India"
                  value="India"
                  name="ed_custlocation"
                  onChange={handleChangeLocation}
                  checked = {enqCustObj.ed_custlocation==="India"?true:false}
                />
                <Form.Check
                  type="radio"
                  label="Overseas"
                  value="Overseas"
                  name="ed_custlocation"
                  onChange={handleChangeLocation}
                  checked = {enqCustObj.ed_custlocation==="Overseas"?true:false}
                /> */}
                        <input
                          type="radio"
                          class="radio"
                          id="checked1"
                          value="India"
                          //disabled={flagdisableIndia === true ? true : false}
                          onClick={(e) => {
                            //document.getElementById("drpid").value = "";
                            enqCustObj.ed_location = 0;
                            enqCustObj.ed_location_ = "";

                            let rsloc = getloc(e);
                            console.log(rsloc);
                          }}
                          onChange={(e) => {
                            handleChangeLocation(e);
                            let error = checkNull(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              ed_custlocation: error,
                            }));
                          }}
                          name="ed_custlocation"
                          checked={isIndian == true ? true : false}
                        />
                        <label
                          for="India"
                          className="label"
                          style={{
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          India
                        </label>
                      </div>
                      <div className="col">
                        <input
                          type="radio"
                          class="radio"
                          id="checked2"
                          value="Overseas"
                          //disabled={flagdisableOverseas === true ? true : false}
                          onClick={(e) => {
                            //document.getElementById("drpid").value = "";
                            enqCustObj.ed_location = 0;
                            enqCustObj.ed_location_ = "";

                            let rsloc = getloc(e);

                            //console.log(rsloc);
                          }}
                          onChange={(e) => {
                            handleChangeLocation(e);
                            let error = checkNull(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              ed_custlocation: error,
                            }));
                          }}
                          name="ed_custlocation"
                          checked={isOverseas == true ? true : false}
                        />

                        <label
                          for="Overseas"
                          className="label"
                          style={{
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          Overseas
                        </label>
                      </div>
                      {error.ed_custlocation.status && (
                        <span className="req-input">
                          {error.ed_custlocation.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              {/* <Col md={3} sm={12} className="mt-3">
                <Form.Label>Location</Form.Label>
                <Form.Select
                  required
                  name="ed_location"
                  id="drpid"
                  value={enqCustObj.ed_location}
                  onChange={(e) => {
                    handleChange(e);
                    setcountry(e.target.value);
                    let error = checkNull(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_location: error,
                    }));
                  }}
                  className={
                    error.ed_location.status
                      ? "placeHolderInput inputText text-field-with-error form-select-ba"
                      : "placeHolderInput inputText form-select-ba"
                  }
                >
                  <option value="" selected>
                    Select Location
                  </option>

                  {locationCombo?.map((locationCombo) => (
                    <option
                      key={locationCombo.lm_loccode}
                      value={locationCombo.lm_loccode}
                    >
                      {locationCombo.lm_loc}
                    </option>
                  ))}
                </Form.Select>
                {error.ed_location.status && (
                  <span className="req-input">{error.ed_location.message}</span>
                )}
              </Col> */}

              <Col md={3} sm={12} className="mt-3">
                <Form.Group>
                  <Form.Label>
                    Location <span className="req-input">Required</span>
                  </Form.Label>

                  <div className="d-flex align-items-center">
                    <Form.Control
                      
                      //className="pe-5 d-block text-truncate"
                      className={
                        error.ed_location.status
                          ? "placeHolderInput inputText text-field-with-error"
                          : "placeHolderInput inputText"
                      }
                      id="Location"
                      name="Location"
                      type="text"
                      placeholder="Select Location"
                      value={enqCustObj.ed_location_}
                      onChange={(e) => {
                        alert(e.target.value);
                        let error = checkNull(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          ed_location: error,
                        }));
                      }}
                      // onChange={(e) => {
                      //   handleChange(e);
                      //   setcountry(e.target.value);
                      // }}
                    />
                    <BiSelectMultiple
                      size={20}
                      onClick={() => {

                        showPopUp(
                          `Location:${enqCustObj.ed_custlocation.toString()}`,
                          "Single",
                          "lm_loccode",
                          enqCustObj.ed_location.toString()
                        )
                        // showPopUpLocation(
                        //  "Location:No",
                        //   "Multiple"
                        // );
                      }}
                      color={"gray"}
                      style={{ marginLeft: "-2rem" }}
                    />
                  </div>
                  {error.ed_location.status && (
                      <Form.Text className="text-danger">
                        {error.ed_location.message}
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>

              <Col md={3} sm={12} className="mt-3">
                <Form.Label>Country</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    readOnly
                    name="ed_country"
                    type="text"
                    style={{ backgroundColor: "#ffffff" }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={enqCustObj.ed_country}
                    // value={setcountr}
                    className={
                      error.ed_country.status
                        ? "placeHolderInput inputText text-field-with-error"
                        : "placeHolderInput inputText"
                    }
                  />
                </div>
                {error.ed_country.status && (
                  <span className="req-input">{error.ed_country.message}</span>
                )}
              </Col>
              <Col md={3} sm={12} className="mt-3">
                <Form.Label>Products Manufactured</Form.Label>
                <Form.Control
                  name="ed_productsmanufactured"
                  placeholder="Enter Products Manufactured"
                  value={enqCustObj.ed_productsmanufactured}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_productsmanufactured: error,
                    }));
                  }}
                  className={
                    error.ed_productsmanufactured.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_productsmanufactured.status && (
                  <span className="req-input">
                    {error.ed_productsmanufactured.message}
                  </span>
                )}
              </Col>
            </Row>
            {/* <Row className="gx-3">
            <Col md={3} sm={12}>
              <Form.Label>Products Manufactured</Form.Label>
              <Form.Control
                name="ed_productsmanufactured"
                placeholder="Enter Products Manufactured"
                value={enqCustObj.ed_productsmanufactured}
                onChange={handleChange}
                maxLength="100"
                onInput={(e) => {
                  let error = isAlphanumericWithSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    ed_productsmanufactured: error,
                  }));
                }}
                className={
                  error.ed_productsmanufactured.status
                    ? "placeHolderInput inputText text-field-with-error"
                    : "placeHolderInput inputText"
                }
              />
              {error.ed_productsmanufactured.status && (
                <span className="req-input">
                  {error.ed_productsmanufactured.message}
                </span>
              )}
            </Col>
          </Row> */}
            <label className="mt-5">Technical Person's Information</label>
            <Row className="gx-3">
              <Col md={12} sm={12} className="mt-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_techname"
                  placeholder="Enter Name"
                  value={enqCustObj.ed_techname}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_techname: error,
                    }));
                  }}
                  className={
                    error.ed_techname.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_techname.status && (
                  <span className="req-input">{error.ed_techname.message}</span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_techdesignation"
                  placeholder="Enter Designation"
                  value={enqCustObj.ed_techdesignation}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_techdesignation: error,
                    }));
                  }}
                  className={
                    error.ed_techdesignation.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_techdesignation.status && (
                  <span className="req-input">
                    {error.ed_techdesignation.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Official Contact No (Add Country Code)</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_techcontactno"
                  placeholder="+91 8888888888"
                  value={enqCustObj.ed_techcontactno}
                  onChange={handleChange}
                  maxLength="20"
                  onInput={(e) => {
                    let error = isNumber(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_techcontactno: error,
                    }));
                  }}
                  className={
                    error.ed_techcontactno.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_techcontactno.status && (
                  <span className="req-input">
                    {error.ed_techcontactno.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_techemailid"
                  placeholder="Enter Email ID"
                  value={enqCustObj.ed_techemailid}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isEmail(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_techemailid: error,
                    }));
                  }}
                  className={
                    error.ed_techemailid.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_techemailid.status && (
                  <span className="req-input">
                    {error.ed_techemailid.message}
                  </span>
                )}
              </Col>
            </Row>
            <label className="mt-5">Decision Making Person's Information</label>
            <Row className="gx-3">
              <Col md={12} sm={12} className="mt-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_dmname"
                  placeholder="Enter Name"
                  value={enqCustObj.ed_dmname}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_dmname: error,
                    }));
                  }}
                  className={
                    error.ed_dmname.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_dmname.status && (
                  <span className="req-input">{error.ed_dmname.message}</span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Designation</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_dmdesignation"
                  placeholder="Enter Designation"
                  value={enqCustObj.ed_dmdesignation}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isAlphanumericWithSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_dmdesignation: error,
                    }));
                  }}
                  className={
                    error.ed_dmdesignation.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_dmdesignation.status && (
                  <span className="req-input">
                    {error.ed_dmdesignation.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Official Contact No (Add Country Code)</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_dmcontactno"
                  placeholder="+91 8888888888"
                  value={enqCustObj.ed_dmcontactno}
                  onChange={handleChange}
                  maxLength="20"
                  onInput={(e) => {
                    let error = isNumber(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_dmcontactno: error,
                    }));
                  }}
                  className={
                    error.ed_dmcontactno.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_dmcontactno.status && (
                  <span className="req-input">
                    {error.ed_dmcontactno.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_dmemailid"
                  placeholder="Enter Email ID"
                  value={enqCustObj.ed_dmemailid}
                  onChange={handleChange}
                  maxLength="100"
                  onInput={(e) => {
                    let error = isEmail(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_dmemailid: error,
                    }));
                  }}
                  className={
                    error.ed_dmemailid.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_dmemailid.status && (
                  <span className="req-input">
                    {error.ed_dmemailid.message}
                  </span>
                )}
              </Col>
            </Row>
            <label className="mt-5">Operational Information</label>
            <Row className="gx-3">
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_currencyvalue"
                  placeholder="Enter Currency"
                  value={enqCustObj.ed_currencyvalue}
                  readOnly
                  style={{ backgroundColor: "#ffffff" }}
                  className="readonly-fields"
                />
              </Col>

              <Col md={4} sm={12} className="mt-3">
                <Form.Label>No. Of Working Days in a Year</Form.Label>
                <Form.Control
                  type="text"
                  min="1"
                  max="366"
                  name="ed_workingdays"
                  placeholder="Enter No. Of Working Days in a Year"
                  value={enqCustObj.ed_workingdays}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error = isNumbernrange(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_workingdays: error,
                    }));

                    let error1 = isNumberWithDecimal(e.target.value);
                    if (error1.status) {
                      setError((prevState) => ({
                        ...prevState,
                        pm_capacity: error1,
                      }));
                    }
                  }}
                  className={
                    error.ed_workingdays.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_workingdays.status && (
                  <span className="req-input">
                    {error.ed_workingdays.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Electricity unit rate</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_electricityunitrate"
                  placeholder="Enter Electricity unit rate"
                  value={enqCustObj.ed_electricityunitrate}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error = isNumberWithDecimal(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_electricityunitrate: error,
                    }));
                  }}
                  className={
                    error.ed_electricityunitrate.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_electricityunitrate.status && (
                  <span className="req-input">
                    {error.ed_electricityunitrate.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="gx-3">
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Ambient Temperature(°C)</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_ambienttemp_c"
                  placeholder="Enter Ambient Temperature(°C)"
                  value={enqCustObj.ed_ambienttemp_c}
                  onChange={handleChangeTemp}
                  onInput={(e) => {
                    let error = isNumberWithDecimal(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      ed_ambienttemp_c: error,
                    }));
                  }}
                  className={
                    error.ed_ambienttemp_c.status
                      ? "placeHolderInput inputText text-field-with-error"
                      : "placeHolderInput inputText"
                  }
                />
                {error.ed_ambienttemp_c.status && (
                  <span className="req-input">
                    {error.ed_ambienttemp_c.message}
                  </span>
                )}
              </Col>
              <Col md={4} sm={12} className="mt-3">
                <Form.Label>Ambient Temperature(°F)</Form.Label>
                <Form.Control
                  type="text"
                  name="ed_ambienttemp_f"
                  value={enqCustObj.ed_ambienttemp_f}
                  readOnly
                  style={{ backgroundColor: "#ffffff" }}
                  className="readonly-fields"
                />
              </Col>
              <Col md={4} sm={12} className="mt-3 mb-3">
                <Form.Group>
                  <Form.Label>Enquiry Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="ed_enquiryid"
                    value={enqCustObj.ed_enquirynumber}
                    onChange={handleChange}
                    maxLength="20"
                    readOnly
                    style={{ backgroundColor: "#ffffff" }}
                    className="readonly-fields"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Form>
        <RenderIf isTrue={popUp.type === "Single" && showForm}>
          <SingleValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            paramvaluecusname={enqCustObj.ed_custname}
          />
        </RenderIf>
      </fieldset>
    </>
  );
};

export default compose(withRouter)(CustomerDetails);
