import { createStore, applyMiddleware, compose } from "redux";
// import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootReducer from "./root-reducer";

export const initialState = {};

// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;

const enhancer =
  process.env.NODE_ENV === "development"
    ? compose(
        // console.tron.createEnhancer(),
        applyMiddleware(),
        // logger
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__()
          : f=>f
      )
    : applyMiddleware();

export const store = createStore(rootReducer, initialState, enhancer);
export const persistor = persistStore(store);
const ex = { store, persistStore };
export default ex;
